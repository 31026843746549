import React, { useState } from 'react';
import '../assets/scss/styles.scss'

function TextAreaWithCharacterCount(params) {
  const [text, setText] = useState('');
  
  // Update the character count whenever the textarea value changes
  const handleTextChange = (event) => {
    //slice the text to 160 characters
    if (event.target.value.length > 160) {
        event.target.value = event.target.value.slice(0, 160);
    }
    const newText = event.target.value;
    setText(newText);
  };
  
  const characterCount = text.length;

  return (
    <div>
      <textarea
        className="form-control"
        id={params.id}
        rows={3}
        value={text}
        onChange={handleTextChange}
      ></textarea>
      <p style={{color: (characterCount === 160)?'red':'blue'}}>{characterCount} / 160</p>
    </div>
  );
}

export default TextAreaWithCharacterCount;
