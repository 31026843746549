import React from "react";

export default function FeatureImgTwo() {
  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h2>
                  Use Your iOS or Android Device <br />
                  to Manage Everything
                </h2>

                <div className="single-feature mb-4 mt-5">
                  <div className="icon-box-wrap d-flex align-items-center mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-1.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      Proactively generate real estate leads with our user-friendly iOS or Android app. Simplify the process of finding potential buyers and sellers with our cutting-edge technology.
                    </p>
                  </div>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-2.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      Our platform allows you to aggregate and organize leads from various sources, ensuring you never miss out on a potential deal. Connect with your favorite CRM and start managing your leads today.
                    </p>
                  </div>
                  <p></p>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-3.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      Customize your landing pages to match your brand and capture leads. Our platform allows you to create and manage your own landing pages.
                    </p>
                  </div>
                  <p></p>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-4.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      Follow up with your leads with easy to set up tasks and reminders. Do not let any lead slip through the cracks.
                    </p>
                  </div>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src="assets/img/image-14-2.png"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
