export default function Price({ hasBg }) {
  return (
    <>
      <div className="overflow-hidden">
        <section
          id="pricing"
          className={`package-section ${
            hasBg ? "gray-light-bg" : "background-shape-right"
          } ptb-100`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>
                    Flexible Payment Options <br />
                    You Decide When to Pay
                  </h2>
                  <p className="lead">
                    We offer two payment options to fit your business needs
                    and budget. Choose the option that works best for you.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md">
                <div className="card text-center popular-price single-pricing-pack p-5">
                  <h5 className="mb-2">Top Producer Plan</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/standard.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>You control the flow of leads</li>
                      <li>No initial fee</li>
                      <li>Limited to 5 Agents per Zip</li>
                      <li>Month-to-month</li>
                      <li>Cancel Anytime</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                      Starting at $<span className="price font-weight-bolder">399</span>/month
                      </div>
                    </div>
                    <a href="/#home" className="btn solid-btn">
                      Sign Up Today
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div className="card text-center single-pricing-pack p-5">
                  <h5 className="mb-2">Pay-at-closing</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/unlimited.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>Intermittent flow of leads</li>
                      <li>No Upfront Cost</li>
                      <li>Higher Cost per Lead</li>
                      <li>Lead gets shared if ignored</li>
                      <li>Results-Driven Service</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $288 one-time<br/>
                        <span className="price font-weight-bolder">15%</span> at closing
                      </div>
                    </div>
                    <a href="/#home" className="btn outline-btn">
                      Sign Up Today
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="mt-5 text-center">
              <p className="mb-2">
                If you need custom services or Need more?
                <a href="#/" className="color-secondary">
                  {" "}
                  Contact us{" "}
                </a>
              </p>
            </div> */}
          </div>
        </section>
      </div>
    </>
  );
}
