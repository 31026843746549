import React, { useEffect, useRef, useState, useMemo, useCallback, memo } from 'react'
import { Avatar, Divider, FormControl, Heading, HStack, Input, VStack, TextArea, ScrollView, Spinner, Stack, Text, Skeleton, Center, Box, Icon, Image, StatusBar, Badge, View, NativeBaseProvider, Pressable, IconButton, Toast, Button, Select, Link, Flex, Switch } from 'native-base';
import { addUserSupportChat, auth, db, getSupportSettings, updateSupportStatus, updateUserTextSupport, getUserNavigationHistory, getUserCollections, getFullChatData, sendPushNotification } from '../config/firebase';
import { LoginScreen } from './LoginScreen';
import { collection, collectionGroup, deleteDoc, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { displayTimestamp } from '../functions/formatDate';
import * as Fa from 'react-icons/fa';
import debounce from 'lodash.debounce';
import ReactPlayer from 'react-player';

interface message {
    text?: string,
    uid: string,
    created_at : {
        seconds: number,
        miliseconds: number
    },
    userAvatar?: string,
    userName?: string,
    aspectRatio?: number,
    width?: number,
    height?: number
}

interface UserData {
    uid?: string,
    public?: any,
    chats?: any[]
}

const ChatSupport = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
  
    // Add event listener to update window dimensions on resize
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    //check if user is logged in with firebase
    const currentUser = auth.currentUser;

    // Set an initializing state whilst Firebase connects
    const [initializing, setInitializing] = useState(true);
    const [user, setUser] = useState(currentUser);
    const [docTotal, setDocTotal] = useState(100);
    const [days, setDays] = useState(2);
    

    // Handle user state changes
    function onAuthStateChanged(user : any) {
        setUser(user);
        if (initializing) setInitializing(false);
    }

    useEffect(() => {
        const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
        return subscriber; // unsubscribe on unmount
    }, []);

    if (!user) {
        return (
            <NativeBaseProvider>
                <LoginScreen />
            </NativeBaseProvider>
        )
    }
        

    const ChatComponent = () => {
        const [selectedUserId, setSelectedUserId] = useState<any>(null);
        const [isFinishedLoading, setIsFinishedLoading] = useState(false);
        const [printMode, setPrintMode] = useState(false);
        const [spyMode, setSpyMode] = useState(false);
        const [allUsersMode, setAllUsersMode] = useState(false);
        const [supportSettings, setSupportSettings] = useState<any>(null);
        const [selectedAgent, setSelectedAgent] = useState<any>({
            name: "Thomas",
            avatar: "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
        });

        const [userText, setUserText] = useState('');
        const [loadingImg, setLoadingImg] = useState(false)
        const [height, setHeight] = useState(100);
        const [noteMode, setNoteMode] = useState(false);
        const [sendingMessage, setSendingMessage] = useState(false);

        const handleTextSubmit = async (text: string) => {
            setSendingMessage(true)
            if(text.length > 0){
                console.log(text)
                await addUserSupportChat(selectedUserId, {
                    text: text,
                    uid: user?.uid,
                    created_at: serverTimestamp(),
                    read: false,
                    isNote: noteMode,
                    agent: selectedAgent
                })
            }
            setUserText('')
            allowPush && sendPushNotification({
                pushTokens: pushTokens,
                message: `Support: ${text}`,
            })
            setSendingMessage(false)
        }

        const [usersData, setUsersData] = useState<any>([]);
        const [originalUsersData, setOriginalUsersData] = useState<any>([]);
        const [searchedUsersData, setSearchedUsersData] = useState<any>([]);
        const [chatData, setChatData] = useState<any>([]);
        const [usersOrdered, setUsersOrdered] = useState<any>([]);
        const [textUpdateData, setTextUpdateData] = useState<any>({
            text: "",
            uid: "",
            chatId: ""
        });
        const [supportStatus, setSupportStatus] = useState<any>(null)
        const [chatTotal, setChatTotal] = useState(0);
        const [selectedMessageId, setSelectedMessageId] = useState<any>(null);
        const [allowPush, setAllowPush] = useState(false);
        const [pushTokens, setPushTokens] = useState<any>([]);
        const [fullChatData, setFullChatData] = useState<any>(null);
        const [chatStats, setChatStats] = useState<any>(null);

        useEffect(() => {
            console.log('isFinishedLoading', isFinishedLoading)
        }, [isFinishedLoading])
        
        
        useEffect(() => {
            if (chatTotal > 0){
                console.log('new chat', chatTotal)
                window.document.title = `NEW CHAT 🆘`;
                handleSound()
            }
        }, [chatTotal])

        const scrollViewRef = useRef<any>();

        const scrollToBottom = () => {
            scrollViewRef?.current.scrollToEnd({ animated: true });
        };

        const audioFile = require('../assets/ding.mp3');
        const [isTabFocused, setIsTabFocused] = useState(true);


        const handleSound = () => {
            const audio = new Audio(audioFile);
            audio.play()
        }

        // useEffect hook to add event listeners and handle focus/blur events
        useEffect(() => {
            const handleVisibilityChange = () => {
                setIsTabFocused(!document.hidden);
                if (!document.hidden) {
                    window.document.title = `Support Chat`;
                }
            };

            document.addEventListener('visibilitychange', handleVisibilityChange);

            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }, []);

        //Subscribe to collectionGroup /users/uid/supportChat
        const subscribeToUsersChat = () => {

            let chatUnsubscribe: any = null;
            let usersUnsubscribe: any = null;

            auth.onAuthStateChanged(user => {
                if (user) {
                    //const userRef = query(collection(db, "users"), limit(99999999));
                    const userRef = collection(db, "users");
                    usersUnsubscribe = onSnapshot(userRef, (querySnapshot) => {
                        let usersData = [];
                        querySnapshot.forEach((doc) => {
                            usersData.push({
                                id: doc.id,
                                data: doc.data()
                            });
                        });
                        console.log('usersData', usersData)
                        setOriginalUsersData(usersData);
                        setUsersData(usersData);
                    });

                    const today = new Date(); // Get the current date
                    const daysAgo = new Date(today.getTime() - days * 24 * 60 * 60 * 1000);

                    let chatRef = query(collectionGroup(db, "supportChat"), where("created_at", ">=", daysAgo), orderBy("created_at", "desc"), limit(docTotal));

                    if (days > 365 && docTotal > 1000) {
                        chatRef = query(collectionGroup(db, "supportChat"), orderBy("created_at", "desc"));
                    }

                    chatUnsubscribe = onSnapshot(chatRef, (querySnapshot) => {
                        let chatData = [];
                        querySnapshot.forEach((doc) => {
                            chatData.push({
                                id: doc.id,
                                uid: doc.ref.parent.parent?.id,
                                data: doc.data()
                            });
                        });
                        //chatTotal if user.id is not equal to logged in user id
                        let total = 0;
                        chatData.forEach((chat : any) => {
                            if (chat.data.uid !== currentUser.uid) {
                                total++;
                            }
                        })


                        console.log(chatData)
                        setChatData(chatData);
                        setChatTotal(total);
                        setIsFinishedLoading(true);
                    });
                } else {
                    chatUnsubscribe && chatUnsubscribe();
                    usersUnsubscribe && usersUnsubscribe();
                }
            });
        };

        const updateStatus = async (status : string) => {
            setSupportStatus(status)
            await updateSupportStatus(status)
        }

        useEffect(() => {
            //Subscribe to chat messages
            subscribeToUsersChat();
            getSupportSettings().then((settings : any) => {
                setSupportStatus(settings.status)
                setSupportSettings(settings)
            })    
        }, []);

        useEffect(() => {
            orderUsers();
        }, [usersData, chatData, allUsersMode])

        const orderUsers = () => {
            //order users by matching with uid in last message sent in chatData
            let usersOrdered = [];
            usersData.forEach((user : any, index: number) => {
                if (allUsersMode === false){
                    chatData.forEach((chat : any) => {
                        if (user.id === chat.data.uid) {
                            //check if user is already in usersOrdered
                            let userAlreadyIn = false;
                            usersOrdered.forEach((userOrdered : any) => {
                                if (userOrdered.id === user.id) {
                                    userAlreadyIn = true;
                                }
                            })
                            if (!userAlreadyIn) {
                                usersOrdered.push(user);
                            }
                        }
                    })
                } else {
                    if (user?.data?.public?.email){
                        usersOrdered.push(user);
                    }
                }
            })
            //add last message sent to usersOrdered
            usersOrdered.forEach((userOrdered : any) => {
                chatData.forEach((chat : any) => {
                    if (userOrdered.id === chat.data.uid) {
                        userOrdered.lastMessage = chat.data
                    }
                })
            })

            //sort usersOrdered by last message sent
            usersOrdered.sort((a : any, b : any) => {
                let aLastMessage = 0;
                let bLastMessage = 0;
                chatData.forEach((chat : any) => {
                    if (a.id === chat.data.uid) {
                        if (chat.data.created_at) {
                            aLastMessage = chat.data.created_at.seconds;
                        }
                    }
                    if (b.id === chat.data.uid) {
                        if (chat.data.created_at) {
                            bLastMessage = chat.data.created_at.seconds;
                        }
                    }
                })
                return bLastMessage - aLastMessage;
            })

            //add unread messages to usersOrdered
            usersOrdered.forEach((userOrdered : any) => {
                let unreadMessages = 0;
                chatData.forEach((chat : any) => {
                    if (userOrdered.id === chat.data.uid) {
                        if (chat.data.read === false) {
                            unreadMessages++;
                        }
                    }
                })
                userOrdered.unreadMessages = unreadMessages;
            })

            //add last message sent to usersOrdered
            usersOrdered.forEach((userOrdered : any) => {
                chatData.forEach((chat : any) => {
                    if (chat.id === "typing" && userOrdered.id === chat.uid) {
                        userOrdered.typingMessage = chat.data
                    }
                })
            })

            //add all the chats to usersOrdered
            usersOrdered.forEach((userOrdered : any) => {
                let chats = [];
                chatData.forEach((chat : any) => {
                    if (userOrdered.id === chat.data.uid) {
                        chats.push(chat);
                    }
                })
                userOrdered.chats = chats;
            })
            
            setUsersOrdered(usersOrdered);
        }

        const isImage = (text: string) => {
            // const regex = /(http(s)?:\/\/[^\s]+)/gi;
            //check if starts with https://storage.googleapis.com/
            const regex = /(https:\/\/storage.googleapis.com\/[^\s]+)|(https:\/\/firebasestorage.googleapis.com\/[^\s]+)/gi;
            return regex.test(text);
        }

        const hasHttp = (text: string) => {
            const regex = /(http(s)?:\/\/[^\s]+)/gi;
            return regex.test(text);
        }


        const copyToClipboard = (text: string) => {
            navigator.clipboard.writeText(text)
            .then(() => {
                Toast.show({
                    title: "Copied to clipboard",
                    description: text,
                    placement: "top",
                    duration: 2000,
                })
            })
            .catch((error) => {
                console.error('Error copying text:', error);
                // Handle the error case here
            });
        }

        const [collections, setCollections] = useState<any>(null);

        const getCollections = async () => {
            setCollections(null);
            const collectionData: any = await getUserCollections({
                uid: selectedUserId
            });
            console.log("collectionData", collectionData)
            let tempTokens: string[] = [];

            await collectionData.data.map((collection : any) => {
                if (collection?.settings){
                    collection?.settings?.map((setting : any) => {
                        if (setting?.pushTokens){
                            Object.keys(setting?.pushTokens).map((token)=>{
                                console.log("token", token)
                                tempTokens.push(token);
                            })

                        }
                    })
                }
            })
            setCollections(collectionData.data);
            setPushTokens(tempTokens);
        }

        const getFullChat = async () => {
            const fullChatData: any = await getFullChatData(selectedUserId);
            console.log("fullChatData", fullChatData)
            setFullChatData(fullChatData);
        }

        useEffect(() => {
            if (selectedUserId) {
                getCollections();
                getFullChat();
            }
        }, [selectedUserId])

        const [activityAmount, setActivityAmount] = useState<number>(5);
        const [historyAmount, setHistoryAmount] = useState<number>(5);
        const [leadAmount, setLeadAmount] = useState<number>(5);
             

        const SearchUser = () => {
            const [searchText, setSearchText] = useState<string>("");

            const searchUser = useCallback(
                debounce( async (text: string) => {
                    if (text.length > 0) {
                        //filter from originalUsersData
                        let filteredUserdata = originalUsersData.filter((user : any) => {
                            if (user?.data?.public?.name) {
                                return user.data.public.name.toLowerCase().includes(text.toLowerCase());
                            }
                            if (user?.data?.public?.email) {
                                return user.data.public.email.toLowerCase().includes(text.toLowerCase());
                            }
                        })



                        setSearchedUsersData(filteredUserdata);
    
                    }
                    console.log("searching for user", text)
                }, 2000),
                []
            );


            return (
                <HStack justifyContent={'space-between'} key={'searchUser'}>
                    <Input
                        placeholder="Search user by name or email"
                        onChangeText={(text) => {
                            setSearchText(text);
                            searchUser(text)
                        }}
                        value={searchText}
                        w={'full'}
                        InputRightElement={
                            <IconButton 
                                icon={<Fa.FaSyncAlt size={20} color='black'  />}
                                onPress={() => {
                                    setSearchText("");
                                    setUsersData(originalUsersData)
                                    setSearchedUsersData(null);
                                }}
                            />
                        }
                    />
                    
                </HStack>
            )
        }

        return (
        <HStack w={(!printMode)?windowWidth:'full'} h={(!printMode)?windowHeight:'full'}>
            {/* Side Panel */}
            {!printMode && <ScrollView w={'1/4'}>
                <VStack p={3}>
                    <HStack justifyContent={'space-between'}>
                        <VStack>
                            {/* Select an agent from settings.members to interact with */}
                            <HStack space={1} alignItems={'center'} p={1} pl={2} pr={2}>
                                {supportSettings?.members?.map((member : any, index: number) => {
                                    return (
                                        <Pressable
                                            key={`agent_${index}`}
                                            onPress={() => {
                                                setSelectedAgent(member);
                                                Toast.show({
                                                    title: "Agent selected",
                                                    description: member.name,
                                                    duration: 2000,
                                                })
                                            }}
                                        >
                                            <Avatar
                                                key={`agent_${index}`}
                                                size={(member?.name === selectedAgent?.name)?'md':'sm'}
                                                source={{
                                                    uri: member.avatar,
                                                }}
                                                borderWidth={(member?.name === selectedAgent?.name)?5:0}
                                                borderColor="green.500"
                                            />
                                        </Pressable>
                                    )
                                }
                                )}
                            </HStack>
                        </VStack>
                        <VStack>
                            <HStack space={1} alignItems={'center'} p={1} pl={2} pr={2}>
                                <Heading size="sm">Status:</Heading>
                                <Select
                                    selectedValue={supportStatus}
                                    w={100}
                                    accessibilityLabel="Select support status"
                                    placeholder="Select support status"
                                    onValueChange={(itemValue) => updateStatus(itemValue)}
                                >
                                    <Select.Item label="Online" value="online" />
                                    <Select.Item label="Offline" value="offline" />
                                </Select>
                            </HStack>
                            <HStack space={1} alignItems={'center'} p={1} pl={2} pr={2}>
                                <Heading size="sm">Limit:</Heading>
                                <Select
                                    selectedValue={`${docTotal}`}
                                    w={100}
                                    accessibilityLabel="Select limit"
                                    placeholder="Select limit"
                                    onValueChange={(itemValue) => {
                                        setDocTotal(parseInt(itemValue));
                                    }}
                                >
                                    <Select.Item label="50" value="50" />
                                    <Select.Item label="100" value="100" />
                                    <Select.Item label="200" value="200" />
                                    <Select.Item label="500" value="500" />
                                    <Select.Item label="1000" value="1000" />
                                    <Select.Item label="All" value="999999" />
                                </Select>
                            </HStack>
                            <HStack space={1} alignItems={'center'} p={1} pl={2} pr={2}>
                                <Heading size="sm">Days:</Heading>
                                <Select
                                    selectedValue={`${days}`}
                                    w={100}
                                    accessibilityLabel="Select days"
                                    placeholder="Select days"
                                    onValueChange={(itemValue) => {
                                        setDays(parseInt(itemValue));
                                    }}
                                >
                                    <Select.Item label="1" value="1" />
                                    <Select.Item label="2" value="2" />
                                    <Select.Item label="3" value="3" />
                                    <Select.Item label="7" value="7" />
                                    <Select.Item label="14" value="14" />
                                    <Select.Item label="30" value="30" />
                                    <Select.Item label="60" value="60" />
                                    <Select.Item label="90" value="90" />
                                    <Select.Item label="180" value="180" />
                                    <Select.Item label="365" value="365" />
                                    <Select.Item label="All" value="9999" />
                                </Select>
                            </HStack>
                            <HStack space={1} alignItems={'center'} p={1} pl={2} pr={2}>
                                <Heading size="sm">Spy Mode:</Heading>
                                <Switch
                                    value={spyMode}
                                    onValueChange={() => {
                                        setSpyMode(!spyMode);
                                        Toast.show({
                                            title: "Spy Mode",
                                            description: "Spy Mode is now " + ((!spyMode)?"enabled":"disabled"),
                                            duration: 3000,
                                        })
                                    }}
                                />
                            </HStack>
                            <HStack space={1} alignItems={'center'} p={1} pl={2} pr={2}>
                                <Heading size="sm">All Users:</Heading>
                                <Switch
                                    value={allUsersMode}
                                    onValueChange={() => {
                                        setAllUsersMode(!allUsersMode);
                                    }}
                                />
                            </HStack>
                        </VStack>
                    </HStack>
                </VStack>
                <Divider />
                <VStack>
                    <SearchUser />
                    { searchedUsersData && searchedUsersData.map((user : any) => {
                        if (user.id !== currentUser.uid){
                            return (
                                <Stack key={`user_${user.id}`} bgColor={(user.id === selectedUserId)?'amber.300': 'coolGray.200'} >
                                    <Pressable onPress={() => {
                                        setSelectedUserId(user.id);
                                    }}
                                    _hover={{bgColor: (user.id === selectedUserId)?'amber.300':'amber.100'}}
                                    >
                                        <HStack space={1} alignItems={'center'} p={1} pl={2} pr={2}>
                                                <VStack>
                                                    <Avatar size="md" source={{uri: user?.data?.public?.avatar,}}>
                                                        New
                                                    </Avatar>
                                                </VStack>
                                                <VStack>
                                                    <Text fontSize={'lg'}>{user?.data?.public?.name}</Text>
                                                    <Text fontSize={'sm'}>{user?.data?.public?.email}</Text>
                                                </VStack>
                                        </HStack>
                                    </Pressable>
                                    <IconButton position={'absolute'} right={0} icon={<Fa.FaCopy size={20} />} onPress={() => {
                                        copyToClipboard(user.id);
                                    }} />
                                </Stack>
                            )
                        }
                    })}
                    {usersOrdered && usersOrdered.map((user : any) => {
                        if (user.id !== currentUser.uid){
                            return (
                                <Stack key={`user_${user.id}`} bgColor={(user.id === selectedUserId)?'amber.300': 'white'} >
                                    <Pressable onPress={() => {
                                        setSelectedUserId(user.id);
                                    }}
                                    _hover={{bgColor: (user.id === selectedUserId)?'amber.300':'amber.100'}}
                                    >
                                        <HStack space={1} alignItems={'center'} p={1} pl={2} pr={2}>
                                                <VStack>
                                                    <Avatar size="md" source={{uri: user?.data?.public?.avatar,}}>
                                                        New
                                                    </Avatar>
                                                    {user.unreadMessages > 0 && (
                                                    <Center bg='danger.600' rounded="full" size={4} position={'absolute'} right={0}>
                                                        <Text color="white" fontSize="xs">{user.unreadMessages}</Text>
                                                    </Center>)}
                                                </VStack>
                                                <VStack>
                                                    <Text fontSize={'lg'}>{user?.data?.public?.name}</Text>
                                                    <Text fontSize={'sm'}>{user?.data?.public?.email}</Text>
                                                    {user?.lastMessage?.text && (
                                                    <Text fontSize={'xs'} color={'coolGray.800'} numberOfLines={1} ellipsizeMode="tail">{user?.lastMessage?.text}</Text>
                                                    )}
                                                    {(user?.typingMessage?.text && user?.typingMessage?.text != '') && <Text fontSize={'xs'} color={'danger.600'}>Typing: {user.typingMessage.text}</Text>}
                                                </VStack>
                                        </HStack>
                                    </Pressable>
                                    <IconButton position={'absolute'} right={0} icon={<Fa.FaCopy size={20} />} onPress={() => {
                                        copyToClipboard(user.id);
                                    }} />
                                </Stack>
                            )
                        }
                    })}
                </VStack>    
            </ScrollView>
            }
            {/* Chat Window */}
            <VStack w={'2/4'} bgColor={'blue.200'} h={(!printMode)?windowHeight:'100%'}>
                <ScrollView
                    ref={scrollViewRef}
                    onContentSizeChange={scrollToBottom}
                    onLayout={scrollToBottom}
                    bgColor={'blue.200'}
                    h={'100%'}
                >
                    <VStack reversed>
                        {fullChatData && fullChatData.map((chat : any, index: number) => {
                            if (chat.uid === selectedUserId) {
                                //mark chat as read
                                if (chat.data.read === false && isTabFocused && currentUser.uid !== chat.data.uid && !spyMode) {
                                    updateDoc(doc(db, 'users', selectedUserId, 'supportChat', chat.id), {
                                        read: true,
                                        readAt: serverTimestamp()
                                    })
                                }


                                return (
                                    <>
                                    <VStack key={`msg_${index}_${chat?.id}`} p={1} w={'2/3'} alignSelf={(chat?.data?.uid != user?.uid)?'flex-start':'flex-end'}>
                                        {(!isImage(chat?.data?.text) && chat.id != 'typing') && (
                                        <Stack ml={3} mr={3} backgroundColor={(chat?.data?.uid == user?.uid)?(chat?.data?.isNote)?'amber.500':'blue.400':'coolGray.400'} p={2} borderRadius={20} >
                                            <HStack justifyContent={'space-between'} >
                                                {/* <Text mr={3} ml={3} color={'white'}>{chat?.data?.text}</Text> */}
                                                    <Box width="80%">
                                                        {!hasHttp(chat?.data?.text) && (chat?.data?.uid != user?.uid) && (
                                                            <Text mr={3} ml={3} color={'white'}>{chat?.data?.text}</Text>
                                                        )}
                                                        {hasHttp(chat?.data?.text) && (chat?.data?.uid != user?.uid) && (
                                                            <Link href={chat?.data?.text} mr={3} ml={3} color={'white'} isExternal={true}>
                                                                <Text>{chat?.data?.text}</Text>
                                                            </Link>
                                                        )}

                                                        {chat?.data?.isNote && <Text mr={3} ml={3} color={'white'}>Support Note:</Text>}

                                                        {(chat?.data?.uid === user.uid && !printMode && selectedMessageId === chat.id) && (
                                                        <TextArea key={`chat_${chat.id}`} fontSize={'sm'} color={'white'} variant={'unstyled'} autoCompleteType={'off'}
                                                            defaultValue={(textUpdateData.chatId === chat.id)?textUpdateData.text:chat?.data?.text}
                                                            onChangeText={(text) => {
                                                                setTextUpdateData({
                                                                    uid: selectedUserId,
                                                                    chatId: chat.id,
                                                                    text: text
                                                                })
                                                            }}
                                                            
                                                        />
                                                        )}
                                                        {/* Only for print mode */}
                                                        {(chat?.data?.uid === user.uid && selectedMessageId !== chat.id) && (
                                                        <Pressable onPress={() => {
                                                                setSelectedMessageId(chat.id);
                                                            }}
                                                            _hover={{opacity: 0.8}}
                                                        >
                                                            <Text mr={3} ml={3} color={'white'}>{chat?.data?.text}</Text>
                                                        </Pressable>
                                                        )}
                                                    </Box>
                                                {(textUpdateData.chatId === chat.id && !printMode) && (
                                                <IconButton icon={<Fa.FaEdit size={20}/>} size={7} ml="2" color="white"
                                                    onPress={() => {
                                                        updateDoc(doc(db, 'users', selectedUserId, 'supportChat', chat.id), {
                                                            text: textUpdateData.text
                                                        })
                                                        setTextUpdateData({
                                                            uid: '',
                                                            chatId: '',
                                                            text: ''
                                                        })
                                                        setSelectedMessageId(null)
                                                    }}
                                                />
                                                )}
                                                {(chat?.data?.uid === currentUser.uid && textUpdateData.chatId !== chat.id && !printMode) && (
                                                    <IconButton icon={<Fa.FaTrash size={20}/>} size={7} ml="2" color="white"
                                                    onPress={() => {
                                                        deleteDoc(doc(db, 'users', selectedUserId, 'supportChat', chat.id))
                                                    }} />
                                                )}
                                            </HStack>
                                            <VStack mr={3} ml={3} alignSelf={'flex-end'} space={2}>
                                                <Text fontSize={10} color={'white'} >
                                                    Delivered {(chat?.data?.created_at?.seconds)?displayTimestamp(chat?.data?.created_at?.seconds):'sending'}
                                                </Text>
                                                {(chat?.data?.uid === user.uid) && (
                                                <Text fontSize={10} color={'white'} >
                                                    {(chat?.data?.seen)?'Seen at: ':'Not seen yet'}
                                                    {(chat?.data?.seen && chat?.data?.seenAt?.seconds)?displayTimestamp(chat?.data?.seenAt?.seconds):''}
                                                </Text>)}

                                                {(chat?.data?.uid !== user.uid) && (
                                                <Text fontSize={10} color={'white'} >
                                                    {(chat?.data?.read)?'Read ':'Not read yet'}
                                                    {(chat?.data?.readAt?.seconds)?displayTimestamp(chat?.data?.readAt?.seconds):''}
                                                </Text>)}
                                            </VStack>
                                            <HStack space={1} position={'absolute'} mr={(chat?.data?.uid !== user.uid)?0:-5} ml={(chat?.data?.uid !== user.uid)?-5:0} bottom={-2} alignSelf={(chat?.data?.uid !== user.uid)?'flex-start':'flex-end'} alignItems="center" direction={(chat?.data?.uid !== user.uid)?'row':'row-reverse'}>
                                                <Avatar size="sm" source={{uri: (chat.uid != currentUser.uid) ? (chat?.data?.agent?.avatar)?chat?.data?.agent?.avatar:null : null}} backgroundColor={'success.400'}>
                                                    {(chat?.data?.agent?.name)?chat?.data?.agent.name.charAt(0):'S'}
                                                </Avatar>
                                            </HStack>
                                        </Stack>
                                        )}
                                        {isImage(chat?.data?.text) && (
                                            //display image from message.text, make it auto resize according to src size
                                                <Image source={{uri: chat?.data?.text}} alt='image'
                                                    resizeMode={'contain'}
                                                    style={{aspectRatio: (chat?.data?.aspectRatio)? chat?.data?.aspectRatio : 1, flex: 1}}
                                                    maxW={'2/3'}
                                                    mb={1}
                                                    borderRadius={20}
                                                    ml={3} mr={3}
                                                />
                                        )}
                                    </VStack>
                                    {/* typing chat */}
                                    {(!isImage(chat?.data?.text) && chat.id == 'typing' && chat?.data?.text != '') && (
                                            <VStack key={`msg_${chat?.id}_${index}`} p={1} w={'2/3'} alignSelf={(chat?.data?.uid != user?.uid)?'flex-start':'flex-end'}>
                                            <Stack ml={3} mr={3} backgroundColor={'warning.600'} p={2} borderRadius={20} >
                                                {(chat?.data?.uid != user?.uid) && (
                                                    <Text mr={3} ml={3} color={'white'}>{chat?.data?.text}</Text>
                                                )}
                                            </Stack>
                                        </VStack>
                                    )}
                                    </>
                                )
                            }
                        })
                        }
                        {/* Print */}
                        <IconButton alignSelf={'end'} m={5} icon={<Fa.FaPrint size={30}/>} size={7} ml="2" color="red.400"
                            onPress={()=>{
                                setPrintMode(!printMode)
                            }}
                        />
                    </VStack>
                </ScrollView>
                {!printMode && (
                <Box key={'input_field'} alignItems={'center'} bgColor={'white'} w={'full'} h={height + 100}>
                    <HStack space={2} alignItems={'center'} justifyContent={'center'} w={'90%'}>
                        {loadingImg && (
                        <HStack space={2} mt={2} justifyContent="center" alignSelf={'center'}>
                            <Spinner accessibilityLabel="Loading image" />
                            <Heading color="success.500" fontSize="md">
                                Uploading
                            </Heading>
                        </HStack>)}
                        {sendingMessage && (
                            <Text position={'absolute'} left={0}>Sending message...</Text>
                        )}
                        {!sendingMessage && (
                            <HStack space={1} alignItems={'center'} position={'absolute'} left={0}>
                                <Text fontSize={12} color={'muted.400'}>You are writing as</Text>
                                <Text fontSize={12} color={'blue.400'}>{selectedAgent?.name}</Text>
                                <Avatar size={'xs'} source={{uri: selectedAgent?.avatar}} />
                            </HStack>
                        )}
                        <HStack justifyContent={'center'} space={5} alignItems={'center'}>
                            <HStack space={2}>
                                <IconButton icon={<Fa.FaStickyNote size={20}/>} size={7} ml="2" color={noteMode?'blue.400':"muted.400"}
                                    onPress={()=>{
                                        setNoteMode(!noteMode)
                                    }}
                                />
                                <IconButton icon={<Fa.FaGripLines size={30}/>} size={7} ml="2" color={(height === 500)?'blue.400':"muted.400"}
                                    onPress={()=>{
                                        if (height === 500){
                                            setHeight(100)
                                        } else {
                                            setHeight(500)
                                        }
                                    }}
                                />
                                <IconButton icon={<Fa.FaCrosshairs size={30}/>} size={7} ml="2" color="muted.400"
                                    onPress={()=>{
                                        //generate response with Ai
                                        console.log('generate response with Ai')
                                    }}
                                />
                                {/* Switch to allow / disable push notification */}
                                <HStack space={2} alignItems={'center'} ml={2}>
                                    <Switch
                                        onToggle={() => {
                                            setAllowPush(!allowPush)
                                        }}
                                        isChecked={allowPush}
                                        size="lg"
                                        colorScheme="info"
                                        accessibilityLabel="Allow push notification"
                                    />
                                    <Text fontSize={12} color={'muted.400'}>{(allowPush)?'Push Enabled': 'Push Disabled'}</Text>
                                </HStack>
                            </HStack>
                        </HStack>
                    </HStack>
                    <TextArea key={'supportInput'} backgroundColor={noteMode?'amber.200':'white'} variant="outline" w={'90%'} pb={2} mt={2}
                        h={height} 
                        placeholder="Add new message" 
                        value={userText}
                        autoCompleteType={'off'}
                        fontSize={12}
                        multiline
                        InputLeftElement={
                            <IconButton icon={<Fa.FaImage size={30}/>} size={7} ml="2" color="muted.400"  
                            />
                        }
                        InputRightElement={
                            <IconButton icon={<Fa.FaArrowCircleUp size={30}/>} mr="2" color={(userText != '')?"blue.400":"coolGray.400"}
                                onPress={() => {
                                    handleTextSubmit(userText)
                                }}
                            />
                        }
                        onChangeText={(text) => {
                            setUserText(text)
                        }}

                        onKeyPress={async (event: any) => {
                            //check if shift key is pressed down when enter is pressed
                            if (event.nativeEvent.key === 'Enter' && event.nativeEvent.shiftKey !== true) {
                                const tempText = userText;
                                setUserText('');
                                await handleTextSubmit(tempText);
                            }
                        }}
                        
                    />
                </Box>
                )}
            </VStack>
            {/* Right Side Panel */}
            <ScrollView w={'1/4'} h={(!printMode)?windowHeight:'100%'} bgColor={'coolGray.200'} p={1} pl={2} pr={2}>
                {(!collections && !selectedUserId) && (
                    <HStack space={2} mt={100} justifyContent="center">
                        <Heading color="primary.500" fontSize="md">
                            Select an User to see details
                        </Heading>
                    </HStack>
                )}

                {(!collections && selectedUserId) && (
                    <HStack space={2} mt={100} justifyContent="center">
                        <Spinner accessibilityLabel="Loading posts" />
                        <Heading color="primary.500" fontSize="md">
                            Loading
                        </Heading>
                    </HStack>
                )}

                {(collections && selectedUserId) && (
                <VStack>  
                    <VStack w={'100%'}>
                        {usersOrdered.map((user : any, index: number) => {
                            if (user.id === selectedUserId){
                                const userData = user.data
                                return (
                                    <VStack>
                                        <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.homeValueCardBackgroundColor || 'white'} shadow={'5'}>
                                            <VStack alignItems={'center'}>
                                                <Box h={40} mb={12} w={'100%'} backgroundColor={'black'} borderRadius={5} alignItems={'center'}>
                                                    <Avatar size={'2xl'} source={{uri: userData?.public?.avatar}} borderColor={'white'} borderWidth={5}/>
                                                </Box>
                                                <Box alignItems={'center'}>
                                                    {userData?.public?.name && <Heading color={userData?.customize?.homeValueHeadingColor || 'coolGray.700'}>{userData?.public?.name}</Heading>}
                                                    {userData?.public?.broker  && (
                                                    <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{userData?.public?.broker}</Text>
                                                    )}
                                                    {userData?.public?.license  && (
                                                    <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{userData?.public?.license}</Text>
                                                    )}
                                                    <HStack m={5} space={2} alignItems={'center'}>
                                                    {userData?.public?.phone && (
                                                    <Link isExternal  href={`tel:${userData?.public?.phone}`}>
                                                        <Button size={'lg'} colorScheme={'success'}  leftIcon={<Fa.FaPhone size={15} color={'white'}/>}>
                                                        Call
                                                        </Button>
                                                    </Link>
                                                    )}
                                                    {userData?.public?.email && (
                                                    <Link isExternal  href={`mailto:${userData?.public?.email}`}>
                                                        <Button size={'lg'} colorScheme={'info'}  leftIcon={<Fa.FaEnvelope size={15} color={'white'}/>}>
                                                        Email
                                                        </Button>
                                                    </Link> 
                                                    )}
                                                    </HStack>
                                                    {userData?.public?.bio && <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{userData?.public?.bio}</Text>}
                                                    <HStack m={5} space={2} alignItems={'center'}>
                                                    {userData?.public?.website && (
                                                        <Link isExternal  href={userData?.public?.website} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                                                        <Fa.FaGlobe size={30}/>
                                                        </Link>
                                                    )}
                                                    {userData?.public?.facebook && (
                                                        <Link isExternal  href={userData?.public?.facebook} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                                                        <Fa.FaFacebook size={30}/>
                                                        </Link>
                                                    )}
                                                    {userData?.public?.instagram && (
                                                        <Link isExternal  href={userData?.public?.instagram} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                                                        <Fa.FaInstagram size={30}/>
                                                        </Link>
                                                    )}
                                                    {userData?.public?.twitter && (
                                                        <Link isExternal  href={userData?.public?.twitter} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                                                        <Fa.FaTwitter size={30}/>
                                                        </Link>
                                                    )}
                                                    {userData?.public?.linkedin && (
                                                        <Link isExternal  href={userData?.public?.linkedin} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                                                        <Fa.FaLinkedin size={30}/>
                                                        </Link> 
                                                    )}
                                                    {userData?.public?.youtube && (
                                                        <Link isExternal  href={userData?.public?.youtube} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                                                        <Fa.FaYoutube size={30}/>
                                                        </Link>
                                                    )}
                                                    </HStack>
                                                    {userData?.public?.featured_video && <ReactPlayer url={userData?.public?.featured_video} width={'100%'} height={200} controls={true}  />}
                                                </Box>
                                            </VStack>
                                        </Box>
                                        <HStack w={'100%'} justifyContent={'space-around'} p={2} borderRadius={5}>
                                            <Link isExternal  href={`https://brokerspot.com/agent/?uid=${selectedUserId}`}>
                                                <Badge colorScheme="info" variant="outline" fontSize={'xl'} fontWeight={'bold'}>Public Profile</Badge>
                                            </Link>
                                            <Link isExternal  href={`https://brokerspot.com/homevalue/?uid=${selectedUserId}`}>
                                                <Badge colorScheme="info" variant="outline" fontSize={'xl'} fontWeight={'bold'}>Home Valuation</Badge>
                                            </Link>
                                            <Link isExternal  href={`https://brokerspot.com/buy/?uid=${selectedUserId}`}>
                                                <Badge colorScheme="info" variant="outline" fontSize={'xl'} fontWeight={'bold'}>Buyer Form</Badge>
                                            </Link>
                                        </HStack>
                                    </VStack>
                                )
                            }
                        })}
                    </VStack>

                    <HStack w={'100%'} justifyContent={'space-between'} p={2} bgColor={'coolGray.300'} borderRadius={5}>
                        <Text fontSize={'xl'} fontWeight={'bold'}>User Details</Text>
                        <IconButton icon={<Fa.FaSync size={20}/>} size={7} ml="2" color="black"
                            onPress={() => {
                                getCollections()
                            }}
                        />
                    </HStack>
                    
                    {/* iterate over the collections to process the data */}
                    {collections.map((collection : any, index: number) => {
                        return (
                            <Box key={`collection_${index}`} w={'100%'} p={2}>

                                {collection?.activity && (
                                    <VStack>
                                        <HStack w={'100%'} justifyContent={'space-between'} p={2} alignItems={'center'}>
                                            <Text fontSize={'lg'} fontWeight={'bold'}>Zip Activity</Text>
                                            <Select
                                                selectedValue={`${activityAmount}`}
                                                w={20}
                                                accessibilityLabel="Select amount of activity"
                                                placeholder="Select amount of activity"
                                                onValueChange={(itemValue) => {
                                                    setActivityAmount(parseInt(itemValue))
                                                }}
                                            >
                                                <Select.Item label="5" value={'5'} />
                                                <Select.Item label="10" value={'10'} />
                                                <Select.Item label="20" value={'20'} />
                                                <Select.Item label="30" value={'30'} />
                                                <Select.Item label="All" value={'999999'} />
                                            </Select>
                                        </HStack>
                                        {collection?.activity?.map((activity : any, index: number) => {
                                            if (index <= activityAmount) {
                                            return (
                                                <HStack key={`activity_${index}`} pl={1} pr={1} bgColor={((index % 2) == 0 )?'coolGray.400':'coolGray.300'} >
                                                    <Text fontSize={'sm'} w={'1/3'}>{displayTimestamp(activity?.createdAt?._seconds)}</Text>
                                                    <VStack w={'2/3'}>
                                                        <Text fontSize={'sm'}>
                                                            {activity?.zipCode} - {activity?.leadType}
                                                        </Text>
                                                        <VStack space={0.5}>
                                                            {activity?.estimates?.map((estimate : any, index: number) => {
                                                                return (
                                                                    <Text key={`estimate_${index}`} fontSize={'xs'}>{`$${estimate?.budget}`} {`->`} {estimate?.leads}</Text>
                                                                )
                                                            })}
                                                        </VStack>
                                                    </VStack>
                                                </HStack>
                                            )
                                            }
                                        })}
                                    </VStack>
                                )}

                                {collection?.history && (
                                    <VStack>
                                        <HStack w={'100%'} justifyContent={'space-between'} p={2} alignItems={'center'}>
                                            <Text fontSize={'lg'} fontWeight={'bold'}>Navigation History</Text>
                                            <Select
                                                selectedValue={`${historyAmount}`}
                                                w={20}
                                                accessibilityLabel="Select amount of history"
                                                placeholder="Select amount of history"
                                                onValueChange={(itemValue) => {
                                                    setHistoryAmount(parseInt(itemValue))
                                                }}
                                            >
                                                <Select.Item label="5" value={'5'} />
                                                <Select.Item label="10" value={'10'} />
                                                <Select.Item label="20" value={'20'} />
                                                <Select.Item label="30" value={'30'} />
                                                <Select.Item label="All" value={'999999'} />
                                            </Select>
                                        </HStack>
                                        {collection?.history?.map((history : any, index: number) => {
                                            const parentRoute = history?.state?.routes?.[history?.state?.index]
                                            const childrenRoute = parentRoute.state?.routes?.[parentRoute.state?.index]

                                            if (index <= historyAmount){
                                            return (
                                                <VStack key={`history_${index}`} pl={1} pr={1} bgColor={(index % 2 == 0)?'coolGray.400':'coolGray.300'}>
                                                    {(parentRoute?.name && childrenRoute?.name) && (
                                                    <HStack w={'100%'} >
                                                        <Text fontSize={'sm'} w={'1/3'}>{displayTimestamp(history?.createdAt?._seconds)}</Text>
                                                        <Text w={'2/3'}>{parentRoute?.name}{` -> `}{childrenRoute?.name}</Text>
                                                    </HStack>
                                                    )}
                                                </VStack>
                                            )
                                            }
                                        })}
                                    </VStack>
                                )}

                                {collection?.subscriptions && (
                                    <VStack>
                                        <Text fontSize={'xl'} fontWeight={'bold'}>Subscriptions</Text>
                                        {collection?.subscriptions?.map((subscription : any, index: number) => {
                                            const totalAmount = (subscription?.items?.data[0].plan.amount + subscription?.items?.data[1].plan.amount)/100;
                                            let campaignUrlParameter = subscription?.campaignData?.campaignResourceName;
                                            campaignUrlParameter = campaignUrlParameter?.split('/')[3];

                                            return (
                                                <HStack key={`subscription_${index}`} space={1} pl={1} pr={1} alignItems={'center'} bgColor={(index % 2 == 0)?'coolGray.400':'coolGray.300'}>
                                                    {subscription?.campaignData?.campaignResourceName && (
                                                    <Link isExternal href={`https://ads.google.com/aw/overview?ocid=1228475160&euid=892474927&uscid=1228475160&authuser=support@brokerspot.com&campaignId=${campaignUrlParameter}`}>
                                                        <Icon as={<Fa.FaGoogle/>} size={5} />
                                                    </Link>)}
                                                    {subscription?.campaignData?.campaignId && (
                                                    <Link isExternal href={`https://adsmanager.facebook.com/adsmanager/manage/ads?act=1105520686752206&business_id=922434598702006&global_scope_id=922434598702006&nav_entry_point=am_local_scope_selector&selected_campaign_ids=${subscription?.campaignData?.campaignId}&selected_adset_ids=${subscription?.campaignData?.adSetId}`}>
                                                        <Icon as={<Fa.FaFacebook/>} size={5} />
                                                    </Link>)}
                                                    <Text>${totalAmount}</Text>
                                                    <Text>{subscription?.metadata?.zipCode}</Text>
                                                    <Text>{subscription?.metadata?.leadType}</Text>
                                                    <Text>{subscription?.status}</Text>
                                                    <Text fontSize={'sm'}>{displayTimestamp(subscription?.start_date)}</Text>
                                                    <Text fontSize={'sm'}>{(subscription?.cancel_at_period_end)?'Pending Cancel':''}</Text>
                                                </HStack>
                                            )
                                        })}
                                    </VStack>
                                )}

                                {collection?.leads && (
                                    <VStack>
                                        <HStack w={'100%'} justifyContent={'space-between'} p={2} alignItems={'center'}>
                                            <Text fontSize={'lg'} fontWeight={'bold'}>Leads {`(${collection?.leads?.length})`}</Text>
                                            <Select
                                                selectedValue={`${leadAmount}`}
                                                w={20}
                                                accessibilityLabel="Select amount of leads"
                                                placeholder="Select amount of leads"
                                                onValueChange={(itemValue) => {
                                                    setLeadAmount(parseInt(itemValue))
                                                }}
                                            >
                                                <Select.Item label="5" value={'5'} />
                                                <Select.Item label="10" value={'10'} />
                                                <Select.Item label="20" value={'20'} />
                                                <Select.Item label="30" value={'30'} />
                                                <Select.Item label="All" value={'999999'} />
                                            </Select>
                                        </HStack>
                                        {collection?.leads?.map((lead : any, index: number) => {
                                            if (index <= leadAmount){
                                                return (
                                                    <HStack key={`lead_${index}`} space={1} pl={1} pr={1} alignItems={'center'} bgColor={(index % 2 == 0)?'coolGray.400':'coolGray.300'}>
                                                        <VStack w={'1/3'}>
                                                            <Text>{lead?.name}</Text>
                                                            <Text>{lead?.email}</Text>
                                                            <Text>{lead?.phone}</Text>
                                                        </VStack>
                                                        <VStack w={'1/3'}>
                                                            <Text>{lead?.type}</Text>
                                                            <Text>{lead?.status}</Text>
                                                        </VStack>
                                                        <VStack w={'1/3'}>
                                                            <Text fontSize={'sm'}>{displayTimestamp(lead?.created_at?._seconds)}</Text>
                                                        </VStack>
                                                    </HStack>
                                                )
                                            }
                                        })}
                                    </VStack>
                                )}

                                {collection?.partialLeads && (
                                    <VStack>
                                        <Text fontSize={'lg'} fontWeight={'bold'}>Partial Leads {`(${collection?.partialLeads?.length})`}</Text>
                                    </VStack>
                                )}

                                {collection?.settings && (
                                    <VStack>
                                        <Text fontSize={'lg'} fontWeight={'bold'}>Settings</Text>
                                        {collection?.settings?.map((setting : any, index: number) => {
                                            return (
                                                <VStack key={`setting_${index}`}>
                                                    {setting?.zapierKey && (
                                                        <HStack space={1} pl={1} pr={1} alignItems={'center'}>
                                                            <Text>Zapier Key: </Text>
                                                            <Text>{setting?.zapierKey}</Text>
                                                        </HStack>
                                                    )}

                                                    {setting?.buyerZipCodes && (
                                                        <HStack space={1} pl={1} pr={1} alignItems={'center'}>
                                                            <Text>Buyer Zips: </Text>
                                                            <Flex flexDirection={'row'} flexWrap={'wrap'}>
                                                                {setting?.buyerZipCodes?.map((zip : any, index: number) => {
                                                                    return (
                                                                        <Text mr={1} key={`buyerZip_${index}`}>{zip}</Text>
                                                                    )
                                                                })}
                                                            </Flex>
                                                        </HStack>
                                                    )}

                                                    {setting?.sellerZipCodes && (
                                                        <HStack space={1} pl={1} pr={1} alignItems={'center'}>
                                                            <Text>Seller Zips: </Text>
                                                            <Flex flexDirection={'row'} flexWrap={'wrap'}>
                                                                {setting?.sellerZipCodes?.map((zip : any, index: number) => {
                                                                    return (
                                                                        <Text mr={1} key={`sellerZip_${index}`}>{zip}</Text>
                                                                    )
                                                                })}
                                                            </Flex>
                                                        </HStack>
                                                    )}

                                                    {setting?.leadAlertPhoneStatus && (
                                                        <HStack space={1} pl={1} pr={1} alignItems={'center'}>
                                                            <Text>Lead Phone Status: </Text>
                                                            <Text>{(setting?.leadAlertPhoneStatus)?'Enabled':'Disabled'}</Text>
                                                        </HStack>
                                                    )}

                                                    {setting?.leadAlertPhones && (
                                                        <HStack space={1} pl={1} pr={1} alignItems={'center'}>
                                                            <Text>Lead Phones: </Text>
                                                            <VStack>
                                                                {setting?.leadAlertPhones?.map((phone : any, index: number) => {
                                                                    return (
                                                                        <Text mr={1} key={`leadPhones_${index}`}>{phone}</Text>
                                                                    )
                                                                })}
                                                            </VStack>
                                                        </HStack>
                                                    )}

                                                    {setting?.leadAlertEmailStatus && (
                                                        <HStack space={1} pl={1} pr={1} alignItems={'center'}>
                                                            <Text>Lead Email Status: </Text>
                                                            <Text>{(setting?.leadAlertEmailStatus)?'Enabled':'Disabled'}</Text>
                                                        </HStack>
                                                    )}

                                                    {setting?.leadAlertEmails && (
                                                        <HStack space={1} pl={1} pr={1} alignItems={'center'}>
                                                            <Text>Lead Emails: </Text>
                                                            <VStack>
                                                                {setting?.leadAlertEmails?.map((email : any, index: number) => {
                                                                    return (
                                                                        <Text mr={1} key={`leadEmails_${index}`}>{email}</Text>
                                                                    )
                                                                })}
                                                            </VStack>
                                                        </HStack>
                                                    )}

                                                    {setting?.pushTokens && (
                                                        <HStack space={1} pl={1} pr={1} alignItems={'center'}>
                                                            <Text>Push Tokens: </Text>
                                                            <VStack>
                                                                {pushTokens.map((token)=>{
                                                                    return (
                                                                        <Text mr={1} key={`leadPush_${index}`}>{token}</Text>
                                                                    )
                                                                })}
                                                            </VStack>
                                                        </HStack>
                                                    )}
                                                    


                                                </VStack>
                                            )
                                        })}
                                    </VStack>
                                )}

                            </Box>
                        )
                    })}
                </VStack>)}
                <View h={200} />
            </ScrollView>
        </HStack>
        )
    }
        
    return (
        <NativeBaseProvider>
            <ChatComponent key={'chat_component'}/>
        </NativeBaseProvider>
    )
}

export default ChatSupport