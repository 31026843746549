import { addDoc, collection, doc, serverTimestamp, setDoc } from "@firebase/firestore";
import React, {useState} from "react";
import { db } from "../../config/firebase";

export default function Subsribe() {
  const [email, setEmail] = useState("");
  const [buttonText, setButtonText] = useState('Subscribe');

  const handleSubmit = async (e) => {
    e.preventDefault();
    //check if id exists
    if (!email) {
      return;
    }
    //add to db
    await setDoc(doc(db, "subscribers", email), {
      email: email,
      created_at: serverTimestamp(),
      source: "website"
    }, {merge: true})
    .then(()=>{
      setButtonText('Subscribed');
    })
  };

  return (
    <>
      <div className="shape-img subscribe-wrap">
        <img
          src="assets/img/footer-top-shape.png"
          alt="footer shape"
          className="img-fluid"
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <form
                action="#"
                method="post"
                className={"subscribe-form subscribe-form-footer d-none d-md-block d-lg-block"}
              >
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    className={"form-control input"}
                    id="email-footer"
                    name="email"
                    placeholder="info@yourdomain.com"
                    onChange={(e) => {
                      //check if email is valid using regex
                      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      if (emailRegex.test(e.target.value)) {
                        setEmail(e.target.value);
                      }
                    }}
                    disabled={buttonText === "Subscribed"}
                  />
                  <input
                    type="submit"
                    className={(buttonText === "Subscribe") ? "button btn solid-btn" : "button btn solid-btn disabled"}
                    id="submit-footer"
                    value={buttonText}
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
