import React from "react";

export default function CounterTwo() {
  return (
    <>
      <section className="pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
              <div className="single-fact p-3 text-center">
                <h3 className="title display-3 font-weight-bolder">12.1K</h3>
                <p className="mb-0">
                  Agents signed up already!
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
              <div className="single-fact p-3 text-center">
                <h3 className="title display-3 font-weight-bolder">930K</h3>
                <p className="mb-0">
                  Seller and Buyer Leads Delievered so Far.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
              <div className="single-fact p-3 text-center">
                <h3 className="title display-3 font-weight-bolder">4.9</h3>
                <p className="mb-0">
                  Average customer ratings in the app stores.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
