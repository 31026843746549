import React, { useEffect, useRef, useState, useMemo, useCallback, memo } from 'react'
import { Avatar, Divider, FormControl, Heading, HStack, Input, VStack, TextArea, ScrollView, Spinner, Stack, Text, Skeleton, Center, Box, Icon, Image, StatusBar, Badge, View, NativeBaseProvider, Pressable, IconButton, Toast, Button, Select, Link, Flex, Switch, Checkbox } from 'native-base';
import { addUserSupportChat, auth, db, getSupportSettings, updateSupportStatus, updateUserTextSupport, getUserNavigationHistory, getUserCollections, getFullChatData, sendPushNotification, getAllTokens, getAllUsers, sendBulkPushNotification, getAllSubscriptions, getAllActivity, getAllActiveSubscriptions, getAllUids, getAllPayAtClosings } from '../config/firebase';
import { LoginScreen } from './LoginScreen';
import * as Fa from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import '../assets/scss/styles.scss'
import { displayTimestamp } from '../functions/formatDate';
import { useInView } from 'react-intersection-observer';
import { Firestore, addDoc, arrayUnion, collection, collectionGroup, deleteDoc, doc, documentId, getDoc, getDocs, getFirestore, limit, orderBy, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';
import CSVFileReader from '../components/CSVFileReader';

const notify = ( text: string ) => toast(text);

const SkatePark = () => {

    //check if user is logged in with firebase
    const currentUser = auth.currentUser;
    // Set an initializing state whilst Firebase connects
    const [initializing, setInitializing] = useState(true);
    const [user, setUser] = useState(currentUser);

    // Handle user state changes
    function onAuthStateChanged(user) {
        try {
            setUser(user);
            if (initializing) setInitializing(false);
        } catch (error) {
            console.error("Error in onAuthStateChanged:", error);
        }
    }

    useEffect(() => {
        const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
        return subscriber; // unsubscribe on unmount
    }, []);
    

    if (!user) {
        return (
            <NativeBaseProvider>
                <LoginScreen />
            </NativeBaseProvider>
        )
    }
        
    const SkateParkTable = () => {

        

        return (
            <div style={{padding: 30}}>
                <ToastContainer />
                <h1>Skate Park</h1>
                <CSVFileReader  />
            </div>
        )
    }



        
    return (
        <div>
            <SkateParkTable />
        </div>
    )
}

export default SkatePark