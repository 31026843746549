import React, { useEffect, useRef, useState, useMemo, useCallback, memo } from 'react'
import { NativeBaseProvider} from 'native-base';
import {auth, db, getAllUids} from '../config/firebase';
import { LoginScreen } from './LoginScreen';
import * as Fa from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import '../assets/scss/styles.scss'
import { displayTimestamp } from '../functions/formatDate';
import { useInView } from 'react-intersection-observer';
import { Firestore, addDoc, arrayUnion, collection, collectionGroup, deleteDoc, doc, documentId, getCountFromServer, getDoc, getDocs, getFirestore, limit, orderBy, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';
import { getUserLeads } from '../../../brokerspot/config/firebase';

const notify = ( text: string ) => toast(text);

const LeadDropping = () => {

    //check if user is logged in with firebase
    const currentUser = auth.currentUser;
    // Set an initializing state whilst Firebase connects
    const [initializing, setInitializing] = useState(true);
    const [user, setUser] = useState(currentUser);

    // Handle user state changes
    function onAuthStateChanged(user) {
        try {
            setUser(user);
            if (initializing) setInitializing(false);
        } catch (error) {
            console.error("Error in onAuthStateChanged:", error);
        }
    }

    useEffect(() => {
        const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
        return subscriber; // unsubscribe on unmount
    }, []);
    

    if (!user) {
        return (
            <NativeBaseProvider>
                <LoginScreen />
            </NativeBaseProvider>
        )
    }
        
    const LeadsDroppingTable = () => {

        const incrementRecords = 20;

        const initialLeadDropData = {
            name: '',
            phone: '',
            email: '',
            address: '',
            timeframe: '',
            condition: '',
            leadType: 'buyer_lead',
            renovations: '',
            notes: '',
            sourced: true,
        }

        const [users, setUsers] = useState(null);
        const [tableData, setTableData] = useState(null);
        const [defaultTableData, setDefaultTableData] = useState(null);
        const [showOnlyPaid, setShowOnlyPaid] = useState(false);
        const [numRecords, setNumRecords] = useState(incrementRecords);
        const [isLoading, setIsLoading] = useState(false);
        const [searchText, setSearchText] = useState('');
        const [selectedUser, setSelectedUser] = useState(null);
        const [selectedUserUid, setSelectedUserUid] = useState(null);
        const [leadDropData, setLeadDropData] = useState(initialLeadDropData);
        const [showPayAtClosing, setShowPayAtClosing] = useState(false);
        const [showPendingCancel, setShowPendingCancel] = useState(false);

        const { ref, inView } = useInView();

        //getting all UIDS from firebase auth to later match with profiles
        const getAllUidsAsync = async () => {
            const data: any = await getAllUids();

            //add uid to each user id key
            data.data.forEach((user) => {
                user.id = user.uid;
            });
            console.log("users", data);
            setUsers(data.data);
        }

        useEffect(() => {
            getAllUidsAsync();
        }, []);

        const getTableData = async () => {

            //working with subscriptions
            const subscriptionsData = await getDocs(collectionGroup(db, 'subscriptions'));
            const subscriptions = subscriptionsData.docs.map((doc) => ({id: doc.id, ref: doc.ref, ...doc.data()}));
            //calculate total amount for each subscription and add to object
            subscriptions.forEach((subscription: any) => {
                const totalAmount = (subscription?.items?.data[0].plan.amount + subscription?.items?.data[1].plan.amount)/100;
                subscription.totalAmount = totalAmount;
            })
            subscriptions.sort((a: any, b: any) => b.totalAmount - a.totalAmount);

            //assign subscriptions to users based on uid
            const usersWithSubscriptions = users?.map((user) => {
                const userSubscriptions = subscriptions.filter((subscription: any) => subscription?.metadata?.uid === user.uid);
                user.subscriptions = userSubscriptions;
                user.totalAmount = 0;
                if (userSubscriptions.length > 0) {
                    userSubscriptions.forEach((subscription: any) => {
                        if (subscription?.status === 'active') {
                            user.totalAmount += subscription.totalAmount;
                        }
                    })
                    
                }
                return user;
            });

            //order users by total amount
            usersWithSubscriptions?.sort((a: any, b: any) => b?.totalAmount - a?.totalAmount);

            //get the first batch of users profiles
            const usersProfiles = await getUserProfiles();
            //assign profiles to users based on uid
            usersWithSubscriptions?.forEach((user) => {
                const userProfile = usersProfiles.find((profile) => profile.id === user.uid);
                user.profile = userProfile;
            });

            //get profiles paying at closing
            const payAtClosingData = await (await getDocs(query(collectionGroup(db, 'settings'), where("payAtClosingActivated", "==", true)))).docs.map((doc) => ({uid: doc.ref.parent.parent.id, id: doc.id, ref: doc.ref, ...doc.data()}));
            console.log("payAtClosingData", payAtClosingData)
            //assign pay at closing to users based on uid
            usersWithSubscriptions?.forEach((user) => {
                const userPayAtClosing = payAtClosingData.find((payAtClosing) => payAtClosing.uid === user.uid);
                user.payAtClosing = userPayAtClosing;
            });

            console.log("usersWithSubscriptions", usersWithSubscriptions);

            setTableData(usersWithSubscriptions);
            setDefaultTableData(usersWithSubscriptions);
            
        } 

        const getUserProfiles = async () => {
            //query "users" collection where document in array of uids from first 20 users
            const usersProfilesData = await getDocs(collection(db, 'users'));
            const usersProfiles = usersProfilesData.docs.map((doc) => ({id: doc.id, ref: doc.ref, ...doc.data()}));
            console.log("usersProfiles", usersProfiles);
            return usersProfiles;
        }

        useEffect(() => {
            getTableData();
        }, [users]);

        const handleSearch = (text: string) => {
            setSearchText(text);
            const filteredData = defaultTableData?.filter((user) => {
                return user?.email?.toLowerCase().includes(text.toLowerCase()) || user?.displayName?.toLowerCase().includes(text.toLowerCase()) || user?.uid?.toLowerCase().includes(text.toLowerCase()) || user?.profile?.public?.name?.toLowerCase().includes(text.toLowerCase()) || user?.profile?.public?.phone?.toLowerCase().includes(text.toLowerCase()) || user?.profile?.public?.email?.toLowerCase().includes(text.toLowerCase());
            });
            setTableData(filteredData);
        }

        useEffect(() => {
            handleSearch(searchText);
        }, [searchText]);

        useEffect(() => {
            if (inView) {
                setNumRecords(numRecords + incrementRecords);
            }
        }, [inView]);


        const handleSorting = (sortType: string) => {
            switch (sortType) {
                case '7d':
                    const sortedData7d = [...tableData].sort((a, b) => a?.profile?.leadsLast7Days - b?.profile?.leadsLast7Days);
                    setTableData(sortedData7d);
                    break;
                case '14d':
                    const sortedData14d = [...tableData].sort((a, b) => a?.profile?.leadsLast14Days - b?.profile?.leadsLast14Days);
                    setTableData(sortedData14d);
                    break;
                case '30d':
                    const sortedData30d = [...tableData].sort((a, b) => a?.profile?.leadsLast30Days - b?.profile?.leadsLast30Days);
                    setTableData(sortedData30d);
                    break;
                case 'all':
                    const sortedDataAll = [...tableData].sort((a, b) => a?.profile?.leadsAllTime - b?.profile?.leadsAllTime);
                    setTableData(sortedDataAll);
                    break;
                default:
                    break;
            }
        }

        const getUserLeads = async (uid: string) => {
            //get leads from firestore, order by created_at desc
            const leadsData = await getDocs(query(collection(db, 'users', uid, 'leads'), orderBy('created_at', 'desc')));
            const leads = leadsData.docs.map((doc) => ({id: doc.id, ref: doc.ref, ...doc.data()}));
            
            const userToUpdate = tableData.find((user) => user.uid === uid);
            userToUpdate.leads = leads;

            setSelectedUser(userToUpdate);
        }

        const getSkateParkLeads = async (uid: string) => {
            const user = tableData.find((user) => user.id === uid);
            //get zip codes from user
            const sellerZipCodes = user?.payAtClosing?.sellerZipCodes;
            const buyerZipCodes = user?.payAtClosing?.buyerZipCodes;

            const zipCodesFromSubscriptions = user?.subscriptions?.map((subscription) => subscription?.metadata?.zipCode)
            
            const zipCodeArray = (sellerZipCodes) ? sellerZipCodes.concat(buyerZipCodes) : buyerZipCodes || [];
            zipCodeArray.push(...zipCodesFromSubscriptions);
            //remove duplicates
            let uniqueZipCodes = [...new Set(zipCodeArray)];
            //add same zip codes but as numbers
            uniqueZipCodes.forEach((zipCode: string) => {
                uniqueZipCodes.push(parseInt(zipCode));
            });

            //if uniqueZipCodes.length is less than 10, then get nearby zip codes from firestore /salesTaskForce/skateParkLeads/zipCodes/{zipCode}
            // if (uniqueZipCodes.length < 20) {
            //     uniqueZipCodes.forEach(async (zipCode) => {
            //         const nearbyZipCodesData = await getDoc(doc(db, 'salesTaskForce', 'skateParkLeads', 'zipCodes', zipCode.toString()));
            //         const nearbyZipCodes = nearbyZipCodesData.data();
            //         if (nearbyZipCodes) {
            //             uniqueZipCodes.push(...nearbyZipCodes.nearby_zipcodes.map((nearbyZipCode) => nearbyZipCode.zip));
            //         }
            //     });
            // }

            if (uniqueZipCodes.length >= 30) {
                //reduce array to 30
                uniqueZipCodes.splice(30, uniqueZipCodes.length - 30);
            }

            //remove duplicates again
            const uniqueZipCodesSet = new Set(uniqueZipCodes);
            uniqueZipCodes = [...uniqueZipCodesSet];

            console.log("uniqueZipCodes", uniqueZipCodes);



            //get leads from firestore, order by created_at desc
            const skateLeads = await getDocs(query(collection(db, 'salesTaskForce', 'skateParkLeads', 'skateLeads'), where('listingZipCode', 'in', uniqueZipCodes), orderBy('createdDate', 'desc')))
                .then((querySnapshot) => {
                    const leads = querySnapshot.docs.map((doc) => ({id: doc.id, ref: doc.ref, ...doc.data()}));
                    return leads;
                })
                .catch((error) => {
                    console.error("Error getting documents: ", error);
                    return [];
                });

            //get scheduled leads
            const scheduledLeads = await getDocs(query(collection(db, 'salesTaskForce', 'skateParkLeads', 'scheduledLeads'), where('deliveryUid', '==', user.id)))
                .then((querySnapshot) => {
                    const scheduledLeads = querySnapshot.docs.map((doc) => ({id: doc.id, ref: doc.ref, ...doc.data()}));
                    return scheduledLeads;
                })
                .catch((error) => {
                    console.error("Error getting documents: ", error);
                    return [];
                });

            //add leads to selected user
            const userToUpdate = {...user};
            userToUpdate.uniqueZipCodes = uniqueZipCodes;
            userToUpdate.skateLeads = skateLeads;
            userToUpdate.scheduledLeads = scheduledLeads;

            console.log("userToUpdate: ", userToUpdate)

            //update local state of selected user
            setSelectedUser(userToUpdate);

        }

        const handleUserChange = async () => {
            if (selectedUserUid){
                await getUserLeads(selectedUserUid);
                await getSkateParkLeads(selectedUserUid);
            }
        }

        const getUserScheduledLeadsCount = async (uid: string) => {
            const scheduledLeadsData = await getCountFromServer(query(collection(db, 'salesTaskForce', 'skateParkLeads', 'scheduledLeads'), where('deliveryUid', '==', uid)));
            const scheduledLeadsCount = scheduledLeadsData.data().count
            return scheduledLeadsCount;
        }

        useEffect(() => {
            console.log("selectedUserUid: ", selectedUserUid);
            handleUserChange();
        }, [selectedUserUid]);

        return (
            <div style={{padding: 30}}>
                <ToastContainer />
                <div className='col-auto' style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', columnGap: 10}}>
                    <h2>User Manager</h2>
                    <input type="text" className="form-control" placeholder="Search" aria-label="Search" style={{width: 300, height: 'auto'}}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <p>{`(⌘ + F to search if not find)`}</p>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="showOnlyPaid" defaultChecked={showOnlyPaid}
                            onChange={(e) => {
                                setShowOnlyPaid(e.target.checked);
                                if (e.target.checked){
                                    setNumRecords(99999);
                                } else {
                                    setNumRecords(incrementRecords);
                                }
                            }}
                        />
                        <label className="form-check-label" htmlFor="showOnlyPaid">Active Subs Only</label>
                    </div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="showPayAtClosing" defaultChecked={showPayAtClosing}
                            onChange={(e) => {
                                setShowPayAtClosing(e.target.checked);
                                if (e.target.checked){
                                    setNumRecords(99999);
                                } else {
                                    setNumRecords(incrementRecords);
                                }
                            }}
                        />
                        <label className="form-check-label" htmlFor="showPayAtClosing">Pay At Closing Only</label>
                    </div>
                    {/* Pending Cancel only */}
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="showPendingCancel" defaultChecked={showPendingCancel}
                            onChange={(e) => {
                                setShowPendingCancel(e.target.checked);
                                if (e.target.checked){
                                    setNumRecords(99999);
                                } else {
                                    setNumRecords(incrementRecords);
                                }
                            }}
                        />
                        <label className="form-check-label" htmlFor="showPendingCancel">Pending Cancel Only</label>
                    </div>
                    <div className='col-auto d-flex'>
                        <div className="form-check form-check-inline d-flex">
                            <input className="form-check-input" type="radio" name="sortType" id="sortType7d" value="7d"
                                onChange={(e) => {
                                    handleSorting(e.target.value);
                                }}
                            />
                            <label className="form-check-label" htmlFor="sortType7d">7d</label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                            <input className="form-check-input" type="radio" name="sortType" id="sortType14d" value="14d"
                                onChange={(e) => {
                                    handleSorting(e.target.value);
                                }}
                            />
                            <label className="form-check-label" htmlFor="sortType14d">14d</label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                            <input className="form-check-input" type="radio" name="sortType" id="sortType30d" value="30d"
                                onChange={(e) => {
                                    handleSorting(e.target.value);
                                }}
                            />
                            <label className="form-check-label" htmlFor="sortType30d">30d</label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                            <input className="form-check-input" type="radio" name="sortType" id="sortTypeAll" value="all"
                                onChange={(e) => {
                                    handleSorting(e.target.value);
                                }}
                            />
                            <label className="form-check-label" htmlFor="sortTypeAll">All</label>
                        </div>
                    </div>
                </div>
                {/* Lead Dropping Modal */}
                <div>
                    <div className="modal fade" id="leadDroppingModal" tabIndex={-1} aria-labelledby="leadDroppingModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="leadDroppingModalLabel">Drop a Lead</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {selectedUser && (
                                <div>
                                    <h5>You are about to drop a lead for {selectedUser?.email}.</h5><br/>
                                    {/* Name, phone, email, address, timeframe, condition, renovations, notes (textarea)  */}
                                    <div className="mb-3">
                                        <label htmlFor="floatingInputName">Name</label>
                                        <input type="email" className="form-control" id="floatingInputName" placeholder="John Doe" 
                                            onChange={(e) => {
                                                const updatedLeadDropData = {...leadDropData};
                                                updatedLeadDropData.name = e.target.value;
                                                setLeadDropData(updatedLeadDropData);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="floatingInputPhone">Phone</label>
                                        <input type="email" className="form-control" id="floatingInputPhone" placeholder="555-555-5555" 
                                            onChange={(e) => {
                                                const updatedLeadDropData = {...leadDropData};
                                                updatedLeadDropData.phone = e.target.value;
                                                setLeadDropData(updatedLeadDropData);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="floatingInputEmail">Email</label>
                                        <input type="email" className="form-control" id="floatingInputEmail" placeholder="johndoe@gmail.com" 
                                            onChange={(e) => {
                                                const updatedLeadDropData = {...leadDropData};
                                                updatedLeadDropData.email = e.target.value;
                                                setLeadDropData(updatedLeadDropData);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="radiusLeadType">Lead Type</label>
                                        <select className="form-select" id="radiusLeadType" value={leadDropData.leadType}
                                            onChange={(e) => {
                                                const updatedLeadDropData = {...leadDropData};
                                                updatedLeadDropData.leadType = e.target.value;
                                                setLeadDropData(updatedLeadDropData);
                                            }}
                                        >
                                            <option value="seller_lead">Seller Lead</option>
                                            <option value="buyer_lead">Buyer Lead</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="floatingInputAddress">Address</label>
                                        <input type="email" className="form-control" id="floatingInputAddress" placeholder="123 Main St" 
                                            onChange={(e) => {
                                                const updatedLeadDropData = {...leadDropData};
                                                updatedLeadDropData.address = e.target.value;
                                                setLeadDropData(updatedLeadDropData);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="floatingInputTimeframe">Timeframe</label>
                                        <input type="email" className="form-control" id="floatingInputTimeframe" placeholder="ASAP" 
                                            onChange={(e) => {
                                                const updatedLeadDropData = {...leadDropData};
                                                updatedLeadDropData.timeframe = e.target.value;
                                                setLeadDropData(updatedLeadDropData);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="floatingInputCondition">Condition</label>
                                        <input type="email" className="form-control" id="floatingInputCondition" placeholder="Good" 
                                            onChange={(e) => {
                                                const updatedLeadDropData = {...leadDropData};
                                                updatedLeadDropData.condition = e.target.value;
                                                setLeadDropData(updatedLeadDropData);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="floatingInputRenovations">Renovations</label>
                                        <input type="email" className="form-control" id="floatingInputRenovations" placeholder="None" 
                                            onChange={(e) => {
                                                const updatedLeadDropData = {...leadDropData};
                                                updatedLeadDropData.renovations = e.target.value;
                                                setLeadDropData(updatedLeadDropData);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="floatingInputNotes">Notes</label>
                                        <textarea className="form-control" id="floatingInputNotes" placeholder="Notes" rows={5}
                                            onChange={(e) => {
                                                const updatedLeadDropData = {...leadDropData};
                                                updatedLeadDropData.notes = e.target.value;
                                                setLeadDropData(updatedLeadDropData);
                                            }}
                                        />
                                    </div>
                                </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal"
                                    onClick={() => {
                                        //add new lead document to firestore collection users/uid/leads
                                        const newLead = {
                                            name: leadDropData.name,
                                            phone: leadDropData.phone,
                                            email: leadDropData.email,
                                            address: leadDropData.address,
                                            timeframe: leadDropData.timeframe,
                                            condition: leadDropData.condition,
                                            renovations: leadDropData.renovations,
                                            notes: leadDropData.notes,
                                            type: leadDropData.leadType,
                                            created_at: serverTimestamp(),
                                            push_status: 'pending',
                                            sourced: leadDropData.sourced,
                                        }
                                        addDoc(collection(db, 'users', selectedUser?.uid, 'leads'), newLead);
                                        setDoc(doc(db, 'users', selectedUser?.uid), {
                                            'profile.leadsAllTime': selectedUser?.profile?.leadsAllTime + 1,
                                            'profile.leadsLast30Days': selectedUser?.profile?.leadsLast30Days + 1,
                                            'profile.leadsLast14Days': selectedUser?.profile?.leadsLast14Days + 1,
                                            'profile.leadsLast7Days': selectedUser?.profile?.leadsLast7Days + 1,
                                        }, {merge: true});
                                        notify(`Lead dropped for ${selectedUser?.email}.`);
                                        setLeadDropData(initialLeadDropData);
                                        //update local state
                                        const updatedUser = {...selectedUser};
                                        updatedUser.profile.leadsAllTime += 1;
                                        updatedUser.profile.leadsLast30Days += 1;
                                        updatedUser.profile.leadsLast14Days += 1;
                                        updatedUser.profile.leadsLast7Days += 1;
                                        setTableData(tableData.map((tableUser) => {
                                            if (tableUser.uid === selectedUser.uid) {
                                                return updatedUser;
                                            }
                                            return tableUser;
                                        }));


                                    }}
                                >Drop Lead</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!tableData && <div className="spinner-grow" role="status"/>}
                {tableData && <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">UID</th>
                            <th scope="col">
                                Login Data
                            </th>
                            <th scope="col">Public Profile</th>
                            <th scope="col">Subscriptions</th>
                            <th scope="col">
                                Leads
                            </th>
                            <th scope="col">Pay At Closing</th>
                            <th scope="col">Private Notes</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData && tableData?.map((user, index) => {
                            if (index > numRecords) return null;
                            if (showOnlyPaid && user?.totalAmount === 0) return null;
                            if (showPayAtClosing && !user?.payAtClosing?.payAtClosingActivated) return null;
                            if (showPendingCancel && !user?.subscriptions?.some((subscription) => (subscription?.status == 'active' && subscription?.cancel_at_period_end))) return null;
                            return (
                                <>
                                <tr key={`user_${index}`} style={{backgroundColor: (user?.payAtClosing?.payAtClosingActivated)?'#dff8df':'white'}}
                                    onMouseEnter={async () => {
                                        console.log("user", user);
                                        //get scheduled leads
                                        const scheduledLeadsCount = await getUserScheduledLeadsCount(user?.uid);
                                        const userToUpdate = {...user};
                                        userToUpdate.scheduledLeadsCount = scheduledLeadsCount;

                                        //update local state of table
                                        setTableData(tableData.map((tableUser) => {
                                            if (tableUser.uid === user.uid) {
                                                return userToUpdate;
                                            }
                                            return tableUser;
                                        }));
                                    }}
                                >
                                    <th scope="row">
                                        <button className="btn btn-primary btn-sm" 
                                            onClick={() => {
                                                navigator.clipboard.writeText(user?.uid);
                                                notify(`Copied UID to clipboard (${user?.uid})`);                                  
                                            }}>
                                            <Fa.FaCopy />
                                        </button>
                                    </th>
                                    <td>
                                        {user?.displayName && (<span>{user.displayName}<br/></span>)}
                                        <label style={{fontWeight: 'bold'}}>{user.email}</label><br/>
                                        {user?.metadata?.creationTime && (<span>Created: {user.metadata.creationTime}<br/></span>)}
                                        {user?.metadata?.lastSignInTime && (<span>Last Login: {user.metadata.lastSignInTime}<br/></span>)}
                                    </td>
                                    <td>
                                        {user?.profile?.public?.name && (<span>{user.profile.public.name}<br/></span>)}
                                        {user?.profile?.public?.phone && (<span>{user.profile.public.phone}<br/></span>)}
                                        {user?.profile?.public?.email && (<span>{user.profile.public.email}<br/></span>)}
                                    </td>
                                    <td>
                                        <label style={{fontWeight: 'bold'}}>${user.totalAmount}</label><br/>
                                        {user?.subscriptions?.map((subscription, index) => {
                                            let campaignUrlParameter = subscription?.campaignData?.campaignResourceName;
                                            campaignUrlParameter = campaignUrlParameter?.split('/')[3];

                                            return (
                                                <div key={`${subscription.id}`}>
                                                    <label >{subscription?.status} - ${subscription?.totalAmount} - 📍{subscription?.metadata?.zipCode} - {subscription?.metadata?.leadType} - {subscription?.interval}</label>
                                                    <br/>   
                                                    {(subscription?.status == 'active' && subscription?.cancel_at_period_end) && (<span>{`Pending Cancel - ${displayTimestamp(subscription?.cancel_at)}`}<br/></span>)}
                                                    {subscription?.campaignData?.campaignResourceName && (
                                                    <a target='_blank' href={`https://ads.google.com/aw/overview?ocid=1228475160&euid=892474927&uscid=1228475160&authuser=stripe@houseventure.com&campaignId=${campaignUrlParameter}`}>
                                                        <Fa.FaGoogle size={15}/>
                                                    </a>)}
                                                    {subscription?.campaignData?.campaignId && (
                                                    <a target='_blank' href={`https://adsmanager.facebook.com/adsmanager/manage/ads?act=1105520686752206&business_id=922434598702006&global_scope_id=922434598702006&nav_entry_point=am_local_scope_selector&selected_campaign_ids=${subscription?.campaignData?.campaignId}&selected_adset_ids=${subscription?.campaignData?.adSetId}`}>
                                                        <Fa.FaFacebook size={15}/>
                                                    </a>)}

                                                    {index+1 != user?.subscriptions.length && <div>--------------</div> }
                                                </div>
                                            )
                                        })}
                                    </td>
                                    <td>
                                        {(user?.profile?.leadsLast7Days != null) && (<span>{`7d:${user.profile.leadsLast7Days}`}<br/></span>)}
                                        {(user?.profile?.leadsLast14Days != null) && (<span>{`14d:${user.profile.leadsLast14Days}`}<br/></span>)}
                                        {(user?.profile?.leadsLast30Days != null) && (<span>{`30d:${user.profile.leadsLast30Days}`}<br/></span>)}
                                        {(user?.profile?.leadsAllTime != null) && (<span>{`All:${user.profile.leadsAllTime}`}<br/></span>)}
                                    </td>
                                    <td>     
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id={`payAtClosingAllowed_${user?.uid}`} defaultChecked={user?.profile?.isPayAtClosingAllowed}
                                                onChange={(e) => {
                                                    //update database
                                                    setDoc(doc(db, 'users', user?.uid), {
                                                        'isPayAtClosingAllowed': e.target.checked
                                                    }, {merge: true});
                                                    //update local state
                                                    const updatedUser = {...user};
                                                    updatedUser.profile.isPayAtClosingAllowed = e.target.checked;
                                                    setTableData(tableData.map((tableUser) => {
                                                        if (tableUser.uid === user.uid) {
                                                            return updatedUser;
                                                        }
                                                        return tableUser;
                                                    }));

                                                    const message = `Pay At Closing status has been changed to ${e.target.checked ? 'ALLOWED' : 'NOT ALLOWED'}.`;
                                                    toast(message);
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor={`payAtClosingAllowed_${user?.uid}`}>Can Buy</label>
                                        </div>
                                        
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id={`payAtClosingActivated_${user?.uid}`} defaultChecked={user?.payAtClosing?.payAtClosingActivated}
                                                onChange={(e) => {
                                                    //update database
                                                    setDoc(doc(db, 'users', user.uid, 'settings', 'payAtClosing'), {
                                                        'payAtClosingActivated': e.target.checked
                                                    }, {merge: true});
                                                    //update local state
                                                    const updatedUser = {...user};
                                                    updatedUser.payAtClosing.payAtClosingActivated = e.target.checked;
                                                    setTableData(tableData.map((tableUser) => {
                                                        if (tableUser.uid === user.uid) {
                                                            return updatedUser;
                                                        }
                                                        return tableUser;
                                                    }));

                                                    const message = `Pay At Closing status has been changed to ${e.target.checked ? 'ACTIVATED' : 'DEACTIVATED'}.`;
                                                    toast(message);
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor={`payAtClosingActivated_${user?.uid}`}>Activated ⚠️</label>
                                        </div>
                                        

                                        {user?.payAtClosing?.sellerZipCodes && (
                                            <div>
                                                <label style={{fontWeight: 'bold'}}>Seller Zip Codes</label><br/>
                                                {user?.payAtClosing?.sellerZipCodes.join(', ')}
                                            </div>
                                        )}
                                        {user?.payAtClosing?.buyerZipCodes && (
                                            <div>
                                                <label style={{fontWeight: 'bold'}}>Buyer Zip Codes</label><br/>
                                                {user?.payAtClosing?.buyerZipCodes.join(', ')}
                                            </div>
                                        )}
                                        <br/>
                                    </td>
                                    <td>
                                        {/* create a textarea to save notes for each user */}
                                        <div className="mb-3">
                                            <textarea key={`textarea_notes_${user.uid}`} className="form-control" placeholder="Notes" rows={5}
                                                defaultValue={user?.profile?.supportNotes}
                                                onChange={(e) => {
                                                    //update database
                                                    setDoc(doc(db, 'users', user.uid), {
                                                        'supportNotes': e.target.value
                                                    }, {merge: true});
                                                    //update local state
                                                    const updatedUser = {...user};
                                                    updatedUser.profile.supportNotes = e.target.value;
                                                    setTableData(tableData.map((tableUser) => {
                                                        if (tableUser.uid === user.uid) {
                                                            return updatedUser;
                                                        }
                                                        return tableUser;
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-auto" style={{gap: 5, display: 'flex', flexDirection: 'column', maxWidth: 'max-content'}}>
                                            {/* <button type="button" className="btn btn-primary btn-sm mb-3"
                                                onClick={() => {
                                                    //get user tokens
                                                    //send push notification
                                                    sendPushNotification({
                                                        uid: user?.uid,
                                                        message: `You have a new lead! 🎉 Open the app and claim it before it gets reassigned.`,
                                                    });
                                                    notify(`Push notification sent.`);
                                                }}
                                            >Fake Lead</button>
                                            <br/> */}
                                            {/* View in Stripe */}
                                            {user?.subscriptions?.[0]?.customer && <a href={`https://dashboard.stripe.com/customers/${user?.subscriptions?.[0]?.customer}`} target="_blank" className="btn btn-primary btn-sm mb-3">View in Stripe</a>}
                                            {/* Pull Leads */}
                                            <button type="button" className="btn btn-primary btn-sm mb-3" data-bs-toggle="modal" data-bs-target="#leadsModal"
                                                onClick={() => {
                                                    setSelectedUserUid(user?.id);
                                                }}
                                            >Show User Leads</button>
                                            <button type="button" className="btn btn-warning btn-sm mb-3" data-bs-toggle="modal" data-bs-target="#leadDroppingModal"
                                                onClick={() => {
                                                    setSelectedUser(user);
                                                }}
                                            >
                                                Drop 1 Lead
                                            </button>
                                            {/* Pull Skate Park Leads */}
                                            <button type="button" className="btn btn-danger btn-sm mb-3" data-bs-toggle="modal" data-bs-target="#skateLeadsModal"
                                                onClick={() => {
                                                    setSelectedUserUid(user?.id);
                                                }}
                                            >Skate Park</button>
                                            {user?.scheduledLeadsCount > 0 && (
                                                <pre>Scheduled Leads: {user?.scheduledLeadsCount}</pre>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                </>
                            )
                        })}
                    </tbody>
                </table>}
                <div className="modal fade" id="leadsModal" tabIndex={-1} aria-labelledby="leadsModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="leadsModalLabel">User Leads for {selectedUser?.email}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {(selectedUser?.leads && selectedUser?.leads.length > 0) && (
                                <div className="table-responsive">
                                    <table className="table mb-0 table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">Created At</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedUser?.leads?.map((lead, index) => {
                                                return (
                                                    <tr key={`lead_${index}`}>
                                                        <td>{lead.name}</td>
                                                        <td>{lead.phone}</td>
                                                        <td>{lead.email}</td>
                                                        <td>{lead.type}</td>
                                                        <td>{lead.address}</td>
                                                        <td>{displayTimestamp(lead.created_at.seconds)}</td>
                                                        <td>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="skateLeadsModal" tabIndex={-1} aria-labelledby="skateLeadsModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="skateLeadsModalLabel">User Skate Leads for {selectedUser?.email}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {(selectedUser) && (
                                <div className="table-responsive">
                                    <h5>Based on Pay at Closing Zip Codes</h5>
                                    <span>Unique Zip Codes: {selectedUser?.uniqueZipCodes?.join(', ')}</span>
                                    <table className="table mb-0 table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">ZipCode</th>
                                                <th scope="col">Intent Score</th>
                                                <th scope="col">Created At</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedUser?.skateLeads?.map((lead, index) => {
                                                let wasDropped = false;
                                                let isScheduled = false;
                                                let deliveryDate = '';
                                                //match skate lead with user leads by email, if found, mark as dropped
                                                const userLead = selectedUser?.leads?.find((userLead) => userLead.email === lead.email);
                                                if (userLead) {
                                                    wasDropped = true;
                                                }

                                                //check if lead is scheduled
                                                const scheduledLead = selectedUser?.scheduledLeads?.find((scheduledLead) => scheduledLead.email === lead.email);
                                                if (scheduledLead) {
                                                    isScheduled = true;
                                                    deliveryDate = displayTimestamp(scheduledLead.deliveryDate.seconds);
                                                }
                                                return (
                                                    <tr key={`skateLead_${index}`}>
                                                        <td>{lead?.firstName} {lead?.lastName}</td>
                                                        <td>{lead?.phone}</td>
                                                        <td>{lead?.email}</td>
                                                        <td>{lead?.listingZipCode}</td>
                                                        <td>{lead?.intentScore}</td>
                                                        <td>{displayTimestamp(lead?.createdDate?.seconds)}</td>
                                                        <td>
                                                            <div className="btn-group">
                                                                {!isScheduled && <button type="button" className="btn btn-warning btn-sm"
                                                                    onClick={async () => {
                                                                        //add new lead document to firestore collection users/uid/leads
                                                                        const newLead = {
                                                                            name: lead.fullName,
                                                                            phone: lead.phone,
                                                                            email: lead.email,
                                                                            address: lead?.listingZipCode,
                                                                            type: 'buyer_lead',
                                                                            created_at: serverTimestamp(),
                                                                            push_status: 'pending',
                                                                            sourced: true,
                                                                        }
                                                                        addDoc(collection(db, 'users', selectedUser?.uid, 'leads'), newLead);
                                                                        setDoc(doc(db, 'users', selectedUser?.uid), {
                                                                            'profile.leadsAllTime': selectedUser?.profile?.leadsAllTime + 1,
                                                                            'profile.leadsLast30Days': selectedUser?.profile?.leadsLast30Days + 1,
                                                                            'profile.leadsLast14Days': selectedUser?.profile?.leadsLast14Days + 1,
                                                                            'profile.leadsLast7Days': selectedUser?.profile?.leadsLast7Days + 1,
                                                                        }, {merge: true});
                                                                        notify(`Lead dropped for ${selectedUser?.email}.`);
                                                                        setLeadDropData(initialLeadDropData);
                                                                        //update local state
                                                                        const updatedUser = {...selectedUser};
                                                                        updatedUser.profile.leadsAllTime += 1;
                                                                        updatedUser.profile.leadsLast30Days += 1;
                                                                        updatedUser.profile.leadsLast14Days += 1;
                                                                        updatedUser.profile.leadsLast7Days += 1;

                                                                        //add lead to user if leads array exists, if not create it
                                                                        if (updatedUser?.leads) {
                                                                            updatedUser.leads.push(newLead);
                                                                        } else {
                                                                            updatedUser.leads = [newLead];
                                                                        }

                                                                        setSelectedUser(updatedUser);
                                                                        
                                                                    }}
                                                                    disabled={wasDropped}
                                                                >{wasDropped ? 'Dropped' : 'Drop Lead'}</button>
                                                                }
                                                                {!isScheduled && (<>
                                                                    <button type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" disabled={wasDropped}>
                                                                        <span className="visually-hidden">Toggle Dropdown</span>
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        {[0.1, 0.3, 0.5, 0.7, 1, 2, 3, 7, 10, 14, 20].map((day) => {
                                                                            return (
                                                                                <li key={`day_${day}`}><button className="dropdown-item" 
                                                                                    onClick={async ()=>{
                                                                                        //add new lead document to firestore collection users/uid/leads
                                                                                        //delivery date is current date + day + random hours
                                                                                        let newLead: any = {
                                                                                            id: lead.grossLeadId,
                                                                                            grossLeadId: lead.grossLeadId,
                                                                                            name: lead.fullName,
                                                                                            phone: lead.phone,
                                                                                            email: lead.email,
                                                                                            address: lead?.listingZipCode,
                                                                                            type: 'buyer_lead',
                                                                                            created_at: serverTimestamp(),
                                                                                            push_status: 'pending',
                                                                                            sourced: true,
                                                                                            deliveryUid: selectedUser?.uid,
                                                                                            deliveryDate: new Date(new Date().getTime() + (day * 24 * 60 * 60 * 1000) + (Math.random() * 24 * 60 * 60 * 1000)),
                                                                                        }
                                                                                        await setDoc(doc(db, 'salesTaskForce', 'skateParkLeads', 'scheduledLeads', lead?.grossLeadId), newLead, {merge: true})
                                                                                            .then((docRef) => {
                                                                                                console.log("Document written");
                                                                                            })
                                                                                            .catch((error) => {
                                                                                                console.error("Error adding document: ", error);
                                                                                            });
                                                                                        
                                                                                        //update local state
                                                                                        const updatedUser = {...selectedUser};
                                                                                        
                                                                                        //add lead to user if leads array exists, if not create it
                                                                                        if (updatedUser?.scheduledLeads) {
                                                                                            updatedUser.scheduledLeads.push(newLead);
                                                                                        } else {
                                                                                            updatedUser.scheduledLeads = [newLead];
                                                                                        }
                                                                                        
                                                                                        setSelectedUser(updatedUser);
                                                                                        notify(`Lead scheduled for ${selectedUser?.email}.`);
                                                                                    }}
                                                                                >
                                                                                    {`Send in ${day} ${day > 1 ? 'days' : 'day'}`}
                                                                                </button></li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </>)}
                                                                {isScheduled && (
                                                                    // Delete Scheduled
                                                                    <div>
                                                                    <button type="button" className="btn btn-danger"
                                                                        onClick={async () => {
                                                                            console.log(lead)
                                                                            //delete lead from firestore collection
                                                                            await deleteDoc(doc(db, 'salesTaskForce', 'skateParkLeads', 'scheduledLeads', lead?.grossLeadId))
                                                                                .then(() => {
                                                                                    console.log("Document successfully deleted!");
                                                                                    notify(`Scheduled lead deleted for ${selectedUser?.email}.`);
                                                                                })
                                                                                .catch((error) => {
                                                                                    console.error("Error removing document: ", error);
                                                                                    notify(`Error removing scheduled lead for ${selectedUser?.email}.`);
                                                                                });
                                                                            

                                                                            //update local state
                                                                            const updatedUser = {...selectedUser};
                                                                            updatedUser.scheduledLeads = updatedUser.scheduledLeads.filter((scheduledLead) => scheduledLead.id !== lead?.grossLeadId);
                                                                            setSelectedUser(updatedUser);
                                                                        }}
                                                                    >Unschedule</button>
                                                                    <pre>{deliveryDate}</pre>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            {/* Refresh button */}
                            <button type="button" className="btn btn-primary"
                                onClick={() => {
                                    handleUserChange();
                                }}
                            >Refresh</button>
                        </div>
                        </div>
                    </div>
                </div>

                <div ref={ref}>
                    Loading...
                </div>
            </div>
        )
    }



        
    return (
        <div>
            <LeadsDroppingTable />
            
        </div>
    )
}

export default LeadDropping