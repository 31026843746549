import React from 'react'

function BillingTerms() {
  return (
    <div className='container mb-5 mt-5'>
        <h1>Billing Terms</h1>
        <p>This Billing Policy is incorporated into the BrokerSpot's Terms of Service (<a href="https://brokerspot.com/terms/">https://brokerspot.com/terms/</a>) and sets forth the billing terms and conditions that apply to your access and use of BrokerSpot's services (the “Services”). In the event that the Terms of Service and this Billing Policy conflict, the Terms of Service control.</p>
        <ol>
        <li><b>General.</b>&nbsp;Upon initial payment for your Subscription Plan, the Services will commence and all corresponding payments will be due and owing. All prices are payable in USD, and all payments are non-refundable, unless specifically approved by our billing department.</li>
        <li><b>Customer-centric Support.</b>&nbsp; BrokerSpot is committed to providing you with the highest level of customer service and your satisfaction is very important to us. On a case-by-case basis, BrokerSpot may, in its sole discretion, issue a partial or full refund, void upcoming monthly payments or issue a credit to your Account. If you have any questions or concerns about your Account, please initiate a chat inquiry from your Account or contact the BrokerSpot Support Team at support@brokerspot.com.</li>
        <li><b>Payment Instrument.</b>&nbsp;All payments must be made using a valid credit or debit card issued by American Express, MasterCard, Discover or Visa, which must be kept on file with your Account (your “Payment Method”). Checks, cash, and electronic funds transfer are not accepted methods of payment. You are required to keep your Payment Method details and contact information current, which you can do by logging into your Account, clicking on “Update Payment Methods”, and then clicking on “Set up”. By providing your Payment Instrument to BrokerSpot, you certify that you are an authorized user of the card.</li>
        <li><b>Billing Cycle.</b>&nbsp;You authorize BrokerSpot to bill your Payment Instrument in advance on a periodic basis for the amounts set forth in your Subscription Plan, and you agree to pay any charges so incurred, until the end of the Subscription Plan. Billing periods typically begin on the day of the month in which your Account was activated (“Billing Date”). Payment Instruments are automatically charged at the beginning of each billing period on the Billing Date. Invoices and payment receipts are available upon request by contacting the BrokerSpot Billing Department at support@brokerspot.com or by clicking on "Receipts" in your account settings. Failure to make full and complete payment by the Billing Date may result in fees, charges, and assessments, as set forth in Section 6 (Fees) below. If you fail to make payment on your Billing Date, or if your Payment Instrument is declined, your access to your Account will be temporarily suspended until your balance is paid in full. BrokerSpot will attempt to charge the Payment Instrument on file for 30 calendar days. If your Account remains delinquent for 30 calendar days, the Account will be cancelled and turned over to a third-party collection service. You will not be able to recover any files until the Account is reinstated by paying off any remaining balances.</li>
        <li><b>Advertising Budget.</b>&nbsp;By subscribing to BrokerSpot ad placing services, you understand and agree that you are required to pay a monthly amount (the “Ad Budget”) to be used by BrokerSpot to contract with certain third party providers to place internet advertising on your behalf to generate leads. You understand and agree that your Ad Budget will be automatically charged to your Payment Instrument at the beginning of each billing period on the Billing Date and does not replace, reduce or otherwise impact any other charges listed in your Subscription Plan. BrokerSpot makes no promise or guarantee of lead volume, quality, or conversion rates. You may choose the amount of your Ad Budget, subject to minimum required amounts. You may not cancel or pause your Ad Budget while your Account remains active, but you may adjust the amount of your Ad Budget at any time by notifying BrokerSpot in writing at support@brokerspot.com. The adjustment will be effective in the billing cycle immediately after you provide the foregoing written notice.</li>
        <li><b>Cancellation.&nbsp;</b>You may cancel at any time by clicking on the Cancel button provided in your Account. Your ads will run until the end of the Billing Period.</li>
        <li><b>Fees.</b>&nbsp;BrokerSpot does not anticipate that you will fail to pay for the Services on a timely basis, and we do not extend credit to our customers. In the event you fail to make full and complete payment by the Billing Date, you may be subject to the following fees, charges and assessments:<br/>
        <ul>
        <li>In the event BrokerSpot receives a chargeback from a credit card company or bank on your behalf, BrokerSpot may assess you a $50.00 processing fee for each individual chargeback.</li>
        <li>In the event BrokerSpot uses a third-party collection service or attorney to collect money owed to us by you, BrokerSpot may assess you a $15.00 processing fee, and you will be charged all reasonable costs and fees associated with such a collection, including, but not limited to, any third-party collection service’s fees, reasonable attorneys’ fees, and arbitration or court costs.</li>
        </ul>
        </li>
        </ol>
        <ol>
        <li>Any fees, charges, or assessments resulting from late payment or nonpayment will be considered liquidated damages intended to be a reasonable advance estimate of our costs resulting from such late payment or nonpayment. These costs will be difficult to calculate or to predict when we set such fees, charges and assessments, because we cannot know in advance whether you will pay for the Services on a timely basis, if ever, and what costs we will incur as a result of your late payment or nonpayment.</li>
        <li><b>Billing Disputes.</b>&nbsp;To dispute any charge related to the Services, you must contact BrokerSpot in writing at support@brokerspot.com, or submit a chat inquiry from your Account, within 30 days after the disputed charge is first incurred. You agree to refrain from disputing the charge with your credit card company or bank for 30 days after reporting the dispute to BrokerSpot, to allow BrokerSpot the opportunity to resolve the dispute. Should BrokerSpot receive a chargeback from a credit card company or bank on your behalf before BrokerSpot has been given 30 days to resolve the issue, BrokerSpot has the right to collect on the rendered services and any payments associated with the dispute. In the event BrokerSpot receives a chargeback from a credit card company or bank on your behalf, BrokerSpot will immediately suspend the Account pending the outcome of the dispute. Regardless of the outcome of the chargeback, BrokerSpot retains the right to collect on any rendered services or payments outstanding, and the Account may be turned over to a third-party collection service. If technical problems caused by BrokerSpot, as determined in BrokerSpot's sole reasonable discretion, prevent or unreasonably delay delivery of the Services, your exclusive and sole remedy is either replacement of the Services or refund of the last monthly payment.</li>
        
        </ol>
    </div>
  )
}

export default BillingTerms