import React, { useEffect, useState } from 'react'
import { NativeBaseProvider } from 'native-base';
import { addUserSupportChat, auth, db, getSupportSettings, updateSupportStatus, updateUserTextSupport, getUserNavigationHistory, getUserCollections, getFullChatData, sendPushNotification, getAllTokens, getAllUsers, sendBulkPushNotification, getAllSubscriptions, getAllActivity, getAllActiveSubscriptions, getAllUids, getAllPayAtClosings } from '../config/firebase';
import { LoginScreen } from './LoginScreen';
import * as Fa from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import '../assets/scss/styles.scss'
import { displayTimestamp } from '../functions/formatDate';
import { useInView } from 'react-intersection-observer';
import { Firestore, addDoc, arrayUnion, collection, collectionGroup, deleteDoc, doc, documentId, getDoc, getDocs, getFirestore, limit, orderBy, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';
import TextAreaWithCharacterCount from '../components/TextAreaWithCharacterCount';

const notify = ( text: string ) => toast(text);

const BroadcastPushNew = () => {

    //check if user is logged in with firebase
    const currentUser = auth.currentUser;
    // Set an initializing state whilst Firebase connects
    const [initializing, setInitializing] = useState(true);
    const [user, setUser] = useState(currentUser);

    // Handle user state changes
    function onAuthStateChanged(user) {
        try {
            setUser(user);
            if (initializing) setInitializing(false);
        } catch (error) {
            console.error("Error in onAuthStateChanged:", error);
        }
    }

    useEffect(() => {
        const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
        return subscriber; // unsubscribe on unmount
    }, []);
    

    if (!user) {
        return (
            <NativeBaseProvider>
                <LoginScreen />
            </NativeBaseProvider>
        )
    }


    const BroadcastList = () => {

        const [Loading, setLoading] = useState(false)

        const [tokens, setTokens] = useState<any[]>([])
        const [users, setUsers] = useState<any[]>([])
        const [targetUsers, setTargetUsers] = useState<any[]>([])


        const initFirestore = async () => {
            setLoading(true)
            let allTokens : any[] = []
            let allUsers: any[] = []
            let allSubscriptions: any[] = []
            let allActivity: any[] = []
            let payAtClosingData: any[] = []

            //get all tokens
            await getAllTokens()
            .then((tokens : any) => {
                tokens.forEach((token : any) => {
                    //push the key id at token.pushTokens
                    let key = Object.keys(token.pushTokens)[0]
                    allTokens.push({
                        token: token.pushTokens[key].id,
                        uid: token.uid,
                    })
                })
                
            })
            .catch((error : any) => {
                console.log(error)
            })

            //get all subscriptions
            await getAllSubscriptions()
            .then((subscriptions : any) => {
                subscriptions.forEach((subscription : any) => {
                    allSubscriptions.push(subscription)
                })
            })

            //get all activity
            await getAllActivity()
            .then((activity : any) => {
                activity.forEach((activity : any) => {
                    allActivity.push(activity)
                })
            })


            //get all users
            await getAllUsers()
            .then((users : any) => {
                users.forEach((user : any) => {
                    allUsers.push(user)
                })
            })
            .catch((error : any) => {
                console.log(error)
            })

            //get profiles paying at closing
            await getDocs(query(collectionGroup(db, 'settings'), where("payAtClosingActivated", "==", true)))
            .then((querySnapshot : any) => {
                querySnapshot.forEach((doc : any) => {
                    //add uid to payAtClosingData
                    payAtClosingData.push({
                        uid: doc.ref.parent.parent.id,
                        ...doc.data()
                    })
                })
                console.log(payAtClosingData)
            })
            .catch((error : any) => {
                console.log(error)
            })
            
            //add payAtClosing data to users
            allUsers.forEach((user : any) => {
                payAtClosingData.forEach((payAtClosing : any) => {
                    if (user.uid === payAtClosing.uid) {
                        user.payAtClosingSettings = {...payAtClosing}
                    }
                })
            })

            //if the user id is the same as the token uid, then push the token to the users array
            allUsers.forEach((user : any) => {
                allTokens.forEach((token : any) => {
                    if (user.uid === token.uid) {
                        user.pushToken = token.token
                    }
                })
            })

            //if the user uid is the same as the subscription uid, then push the subscription to the users array
            allUsers.forEach((user : any) => {
                let userSubscriptions: any[] = []
                allSubscriptions.forEach((subscription : any) => {
                    
                    if (user.uid === subscription.uid) {
                        userSubscriptions.push(subscription)
                    }
                })
                user.subscriptions = userSubscriptions
            })

            //if the user uid is the same as the activity uid, then push the activity to the users array
            allUsers.forEach((user : any) => {
                let userActivity: any[] = []
                allActivity.forEach((activity : any) => {

                    if (user.uid === activity.uid) {
                        userActivity.push(activity)
                    }
                })
                user.activity = userActivity
            })

            setTokens(allTokens)
            setUsers(allUsers)
            setLoading(false)
            console.log(allUsers);

        }

        const addUserToTarget = (user : any) => {
            let newTargetUsers = [...targetUsers]
            //check if user is already in targetUsers
            if (newTargetUsers.find((targetUser : any) => targetUser.uid === user.uid)) return
            newTargetUsers.push(user)
            setTargetUsers(newTargetUsers)
        }

        const removeUserFromTarget = (user : any) => {
            let newTargetUsers = [...targetUsers]
            newTargetUsers = newTargetUsers.filter((targetUser : any) => targetUser.uid !== user.uid)
            setTargetUsers(newTargetUsers)
        }
            
        useEffect(() => {
            initFirestore()
        }, [])

        const sendBulkPush = async (pushText) => {
            await sendBulkPushNotification({
                message: pushText, 
                users: targetUsers})
            .then((response) => {
                notify('Push notification sent.')
            })
            .catch((error) => {
                notify('Error sending push notification.')
            })
        }


        return (
            <div>
                <h1 className="text-center mb-5">Broadcast Push Notification</h1>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">Write a push notification message (emojis are allowed 😺).</label>
                    <TextAreaWithCharacterCount id='pushMessageInput' />
                    <button type="button" className="btn btn-danger btn-sm m-1" onClick={() => {
                        //get the text from the input
                        let pushText = (document.getElementById('pushMessageInput') as HTMLInputElement)?.value
                        
                        //check if there is text
                        if (!pushText) {
                            notify('Please write a message.')
                            return
                        }

                        //check if there are target users
                        if (targetUsers.length === 0) {
                            notify('Please select target users.')
                            return
                        }

                        //send push notification
                        sendBulkPush(pushText)
                    }}>
                        Send Push Notification
                    </button>
                </div>
                <div className='row'>
                    <div className='col-6'>
                    <div className="col-auto">
                            <button type="button" className="btn btn-primary btn-sm m-1" onClick={() => {
                                let newTargetUsers = []
                                users.forEach((user : any) => {
                                    if (user?.pushToken) {
                                        newTargetUsers.push(user)
                                    }
                                })
                                setTargetUsers(newTargetUsers)
                            }} data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="This will select all users with push enabled.">
                                Select All
                            </button>
                            <button type="button" className="btn btn-primary btn-sm m-1" 
                                onClick={() => {
                                    let newTargetUsers = []
                                    users.forEach((user : any) => {
                                        if (user?.pushToken) {
                                            if (!user?.public?.name || !user?.public?.email) {
                                                newTargetUsers.push(user)
                                            }
                                        }
                                    })
                                    console.log(newTargetUsers)
                                    setTargetUsers(newTargetUsers)
                                }}
                                data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="This will select all users with incomplete profiles, checking Name and Email."
                            >
                                Incomplete Profile
                            </button>
                            <br />
                            {/* Only with subscriptions */}
                            <button type="button" className="btn btn-primary btn-sm m-1" 
                                onClick={() => {
                                    let newTargetUsers = []
                                    users.forEach((user : any) => {
                                        if (user?.pushToken) {
                                            if (user?.subscriptions.length > 0) {
                                                newTargetUsers.push(user)
                                            }
                                        }
                                    })
                                    console.log(newTargetUsers)
                                    setTargetUsers(newTargetUsers)
                                }}
                                data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="This will select all users with subscriptions, active or cancelled"
                            >
                                With Subscriptions
                            </button>
                            {/* Only without subscriptions */}
                            <button type="button"  className="btn btn-primary btn-sm m-1" 
                                onClick={() => {
                                    let newTargetUsers = []
                                    users.forEach((user : any) => {
                                        if (user?.pushToken) {
                                            if (user?.subscriptions.length === 0) {
                                                newTargetUsers.push(user)
                                            }
                                        }
                                    })
                                    console.log(newTargetUsers)
                                    setTargetUsers(newTargetUsers)
                                }}
                                data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="This will select all users without any subscriptions"
                            >
                                Without Subscriptions
                            </button>
                            <br />
                            {/* Only with activity */}
                            <button type="button" className="btn btn-primary btn-sm m-1" 
                                onClick={() => {
                                    let newTargetUsers = []
                                    users.forEach((user : any) => {
                                        if (user?.pushToken) {
                                            if (user?.activity.length > 0) {
                                                newTargetUsers.push(user)
                                            }
                                        }
                                    })
                                    console.log(newTargetUsers)
                                    setTargetUsers(newTargetUsers)
                                }}
                                data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="This will select all users that have used the slider at least once."
                            >
                                Used Slider
                            </button>
                            {/* Only without activity */}
                            <button type="button" className="btn btn-primary btn-sm m-1" 
                                onClick={() => {
                                    let newTargetUsers = []
                                    users.forEach((user : any) => {
                                        if (user?.pushToken) {
                                            if (user?.activity.length === 0) {
                                                newTargetUsers.push(user)
                                            }
                                        }
                                    })
                                    console.log(newTargetUsers)
                                    setTargetUsers(newTargetUsers)
                                }}
                                data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="This will select all users that have not used the slider, don't know how to set a budget."
                            >
                                Not used Slider
                            </button>
                            {/* With Activity and No Subscriptions */}
                            <button type="button" className="btn btn-primary btn-sm m-1" 
                                onClick={() => {
                                    let newTargetUsers = []
                                    users.forEach((user : any) => {
                                        if (user?.pushToken) {
                                            if (user?.activity.length > 0 && user?.subscriptions.length === 0) {
                                                newTargetUsers.push(user)
                                            }
                                        }
                                    })
                                    console.log(newTargetUsers)
                                    setTargetUsers(newTargetUsers)
                                }}
                                data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="This will select all users that have used the slider at least once but have not subscribed yet."
                            >
                               Used Slider and No Subscriptions
                            </button>
                            <br />
                            {/* Paid $288 Fee */}
                            <button type="button" className="btn btn-primary btn-sm m-1" 
                                onClick={() => {
                                    let newTargetUsers = []
                                    users.forEach((user : any) => {                  
                                        if (user?.payAtClosingSettings?.payAtClosingActivated) {
                                            newTargetUsers.push(user)
                                        }
                                    })
                                    console.log(newTargetUsers)
                                    setTargetUsers(newTargetUsers)
                                }}
                                data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="This will select all users that paid the $288 fee."
                            >
                               Paid $288 Fee
                            </button>
                            {/* Can Pay at Closing but have not */}
                            <button type="button" className="btn btn-primary btn-sm m-1" 
                                onClick={() => {
                                    let newTargetUsers = []
                                    users.forEach((user : any) => {                  
                                        if (!user?.payAtClosingSettings?.payAtClosingActivated && user?.isPayAtClosingAllowed) {
                                            newTargetUsers.push(user)
                                        }
                                    })
                                    console.log(newTargetUsers)
                                    setTargetUsers(newTargetUsers)
                                }}
                                data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="This will select all users that have the Pay at Closing option enabled but have not paid yet."
                            >
                               Can Pay at Closing but Haven't
                            </button>
                        </div>
                    </div>
                    <div className='col-6'>
                        <button type="button" className="btn btn-primary btn-sm m-1" onClick={() => setTargetUsers([])}>
                            Clear All
                        </button>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <h3>Users {"->"} {users.length}</h3>
                        <div className="list-group overflow-auto" style={{maxHeight: 2000}}>
                            {
                                users.map((user : any, index: number) => {
                                    return (
                                        <button key={`all_${index}`} type="button" className="list-group-item list-group-item-action" onClick={() => 
                                        {
                                            addUserToTarget(user)
                                            notify(`Added ${user?.public?.email} to target users.`)
                                        }}>
                                            <div className='row'>
                                                <span>{user?.public?.name}</span>
                                                <span><strong>{user?.public?.email}</strong></span>
                                                <span>{user.uid}</span>
                                                <span>{user.pushToken}</span>
                                                <span>Subs: {user.subscriptions.length}</span>
                                            </div>
                                        </button>
                                    )
                                
                                })
                            }
                        </div>
                    </div>
                    <div className='col-6'>
                        <h3>Target Users {"->"} {targetUsers.length}</h3>
                        <div className="list-group overflow-auto" style={{maxHeight: 2000}}>
                        {
                            targetUsers.map((user : any, index: number) => {
                                return (
                                    <button key={`target_${index}`} type="button" className="list-group-item list-group-item-action" onClick={() => removeUserFromTarget(user)}>
                                        <div className='row'>
                                            <span>{user?.public?.name}</span>
                                            <span><strong>{user?.public?.email}</strong></span>
                                            <span>{user.uid}</span>
                                            <span>{user.pushToken}</span>
                                            <span>Subs: {user.subscriptions.length}</span>
                                        </div>
                                    </button>
                                )
                            
                            })
                        }
                        </div>
                    </div>
                </div>
                {Loading && <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>}
            </div>
        )
    }

    return (
        <div className='container'>
            <ToastContainer />
            <BroadcastList />
        </div>
    )
}

export default BroadcastPushNew