import React from "react";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import AllReview from "../components/testimonial/AllReview";
import Footer from "../components/layout/footer/Footer";

export default function ZapierTutorial() {
  return (
    <Layout>
      <Navbar darkBg={undefined} classOption={undefined} isHome={false} />
      <PageHeader HeaderTitle="How to Connect with Zapier" PageTitle="Zapier" Parent={undefined} />
        <div className="container">
            <h3 className="mb-5 mt-5">Here's a guide to help you connect BrokerSpot to Zapier:</h3>
            <h4>Step 1: Obtain your API Key from BrokerSpot</h4>
            <ul className="list-group mb-5">
                <li className="list-group-item">Open the BrokerSpot mobile app on your device.</li>
                <li className="list-group-item">Navigate to the Settings section.</li>
                <li className="list-group-item">Look for the Integrations option and tap on it.</li>
                <li className="list-group-item">Find the API key provided by BrokerSpot.</li>
            </ul>
            <h4>Step 2: Set up a Zapier Account (if you haven't already)</h4>
             <ul className="list-group mb-5">
                 <li className="list-group-item">Visit the Zapier website (<a href="https://zapier.com/" target="_new" rel="noopener">https://zapier.com</a>) and sign up for a new account.</li>
                 <li className="list-group-item">Once you have successfully created your account, log in to Zapier.</li>
            </ul>
            <h4>Step 3: Start creating a new Zap</h4>
             <ul className="list-group mb-5">
                 <li className="list-group-item">After logging in to Zapier, click on the "Make a Zap!" button located in the top right corner of the page. This will initiate the Zap creation process.</li>
            </ul>
            <h4>Step 4: Choose BrokerSpot as the Trigger App</h4>
             <ul className="list-group mb-5">
                 <li className="list-group-item">In the "Choose a Trigger App" search box, type "BrokerSpot" and select it from the available options.</li>
                 <li className="list-group-item">If this is your first time connecting BrokerSpot to Zapier, you might need to authorize Zapier to access your BrokerSpot account. Follow the on-screen instructions to do so. Which will ask you for your API key obtained in Step 1.</li>
            </ul>
            <h4>Step 5: Select a Trigger Event</h4>
             <ul className="list-group mb-5">
                 <li className="list-group-item">Once you have connected BrokerSpot, you'll need to choose a trigger event. This event will initiate the Zap.</li>
                 <li className="list-group-item">For example, you might want to trigger an action when a new lead is added in BrokerSpot. Select the appropriate trigger event based on your requirements and click "Continue."</li>
            </ul>
            <h4>Step 6: Connect your BrokerSpot Account</h4>
             <ul className="list-group mb-5">
                 <li className="list-group-item">In this step, you will be asked to connect your BrokerSpot account to Zapier.</li>
                 <li className="list-group-item">Click on the "Connect an Account" button and enter your API key in the provided field. Follow the on-screen instructions to complete the authentication process.</li>
            </ul>
            <h4>Step 7: Test the Trigger</h4>
             <ul className="list-group mb-5">
                 <li className="list-group-item">After successfully connecting your BrokerSpot account, Zapier will test the trigger by pulling in sample data.</li>
                 <li className="list-group-item">Verify that the sample data appears correctly and click "Continue" to proceed.</li>
            </ul>
            <h4>Step 8: Choose an Action App (optional)</h4>
             <ul className="list-group mb-5">
                 <li className="list-group-item">If you want to perform an action in another application based on the trigger event from BrokerSpot, you can choose an action app in this step.</li>
                 <li className="list-group-item">For example, you might want to create a new task in a project management tool when a lead is added in BrokerSpot.</li>
                 <li className="list-group-item">Select the desired application from the available options, or skip this step if you don't need an action app.</li>
            </ul>
            <h4>Step 9: Configure the Action (if applicable)</h4>
             <ul className="list-group mb-5">
                 <li className="list-group-item">If you selected an action app in the previous step, you will need to configure the specific action you want to perform.</li>
                 <li className="list-group-item">Follow the instructions provided by Zapier to set up the action according to your requirements.</li>
            </ul>
            <h4>Step 10: Test the Zap</h4>
             <ul className="list-group mb-5">
                 <li className="list-group-item">Once you have configured the action, Zapier will test the Zap by performing a test run.</li>
                 <li className="list-group-item">Verify that the test run was successful and that the desired action was executed correctly.</li>
            </ul>
            <h4>Step 11: Enable the Zap</h4>
             <ul className="list-group mb-5">
                 <li className="list-group-item">If the test run was successful, you can enable the Zap to start automating tasks between BrokerSpot and the chosen action app (if applicable).</li>
                 <li className="list-group-item">Toggle the Zap's status to "On" to activate it.</li>
            </ul>
            <h5 className="mb-5">Congratulations! You have successfully connected BrokerSpot to Zapier and set up a Zap to automate tasks based on trigger events in BrokerSpot. The Zap will now run automatically according to your defined triggers and actions, saving you time and effort in your daily workflows.</h5>

        </div>
        <Footer space />
    </Layout>
  );
}