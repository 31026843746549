import React from 'react'

function Success() {
    //close this tab
    // @ts-ignore
    window.ReactNativeWebView.postMessage('paymentSuccess');
    return (
        <div className='container'>
        </div>
    )
}

export default Success;