import { initializeApp, getApps, FirebaseApp, getApp } from "firebase/app";
import { Auth, browserSessionPersistence, getAuth, initializeAuth, setPersistence, signInWithEmailAndPassword} from "firebase/auth";
import { Firestore, addDoc, arrayUnion, collection, collectionGroup, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCtB-2kWtHrOqmdAHGIA7MbT3jIcpex-JE",
  authDomain: "brokerspotapp.firebaseapp.com",
  projectId: "brokerspotapp",
  storageBucket: "brokerspotapp.appspot.com",
  messagingSenderId: "941697368327",
  appId: "1:941697368327:web:345a22c1d54118325385a2",
  measurementId: "G-EQVKXGEC6M"
};

// Initialize Firebase
let app: FirebaseApp = initializeApp(firebaseConfig);
let auth: Auth = getAuth(app);

setPersistence(auth, browserSessionPersistence);


export {auth}

//Firestore
export const db = getFirestore(app);

export const getUserData = async (uid?: string, username?: string) => {
  if (uid) {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null
    }
  } else if (username) {
    const queryRef = query(collection(db, "users"), where("customize.homeValueSlug", "==", username))
    const docsSnap = await getDocs(queryRef);
    if (docsSnap.size > 0) {
      return docsSnap.docs[0].data();
    }
  } else {
    return null
  }

}

export const getUserNavigationHistory = async (uid: string) => {
  const queryRef = query(collection(db, "users", uid, 'history'), orderBy('createdAt', 'desc'), limit(10));
  const docsSnap = await getDocs(queryRef)
  if (docsSnap.size > 0) {
    return docsSnap.docs.map(doc => doc.data());
  } else {
    return null
  }
}

export const functions = getFunctions(app);
export const getAllUids = httpsCallable(functions, 'getAllUids');

export const getUserCollections = httpsCallable(functions, 'getUserCollections');

export const sendSmsUsingTwilio = httpsCallable(functions, 'sendSmsUsingTwilio');

export const sendPushNotification = httpsCallable(functions, 'sendPushNotification');

export const sendBulkPushNotification = httpsCallable(functions, 'sendBulkPushNotification');


//When Uid is present

export const signInEmailPassword = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password)
}

export const createPartialLead = async (uid: string, data: any) => {
  const docRef = collection(db, "users", uid, 'partialLeads');
  const result = await addDoc(docRef, data)
    .then((response) => {
      return response.id
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      return null;
    })
  return result;
}

export const deletePartialLead = async (uid: string, leadId: any) => {
  const docRef = doc(db, "users", uid, 'partialLeads', leadId);
  return await deleteDoc(docRef)
}

export const updatePartialLead = async (uid: string, leadId: any, data: any) => {
  const docRef = doc(db, "users", uid, 'partialLeads', leadId);
  return await setDoc(docRef, data, {merge: true})
}

export const completeLead = async (uid: string, leadId: any) => {
  const partialDocRef = doc(db, "users", uid, 'partialLeads', leadId);
  const completeDocRef = doc(db, "users", uid, 'leads', leadId);
  const leadData = await getDoc(partialDocRef);
  if (leadData.exists()) {
    const data = leadData.data();
    await setDoc(completeDocRef, data);
    await deleteDoc(partialDocRef);
    return true;
  } else {
    return false;
  }
}

export const updateLead = async (uid: string, leadId: any, data: any) => {
  const docRef = doc(db, "users", uid, 'leads', leadId);
  return await setDoc(docRef, data, {merge: true})
}

export const getAllTokens = async () => {
  const queryRef = query(collectionGroup(db, "settings"), orderBy('pushTokens', 'asc'));
  const docsSnap = await getDocs(queryRef)
  if (docsSnap.size > 0) {
    //return data, id and uid
    return docsSnap.docs.map(doc => ({...doc.data(), uid: doc.ref.parent.parent.id}));
  } else {
    return null
  }
}

export const getAllUsers = async () => {
  const queryRef = query(collection(db, "users"));
  const docsSnap = await getDocs(queryRef)
  if (docsSnap.size > 0) {
    //return data, id and uid
    return docsSnap.docs.map(doc => ({...doc.data(), uid: doc.id}));
  } else {
    return null
  }
}

export const getAllSubscriptions = async () => {
  const queryRef = query(collectionGroup(db, "subscriptions"));
  const docsSnap = await getDocs(queryRef)
  if (docsSnap.size > 0) {
    //return data, id and uid
    return docsSnap.docs.map(doc => ({...doc.data(), id: doc.id, uid: doc.ref.parent.parent.id}));
  } else {
    return null
  }
}

export const getAllActiveSubscriptions = async () => {
  const queryRef = query(collectionGroup(db, "subscriptions"), where("status", "==", "active"));
  const docsSnap = await getDocs(queryRef)
  if (docsSnap.size > 0) {
    //return data, id and uid
    return docsSnap.docs.map(doc => ({...doc.data(), id: doc.id, uid: doc.ref.parent.parent.id}));
  } else {
    return null
  }
}

export const getAllPayAtClosings = async () => {
  const queryRef = query(collectionGroup(db, "settings"), where("payAtClosingActivated", "==", true));
  const docsSnap = await getDocs(queryRef)
  if (docsSnap.size > 0) {
    //return data, id and uid
    return docsSnap.docs.map(doc => ({...doc.data(), id: doc.id, uid: doc.ref.parent.parent.id}));
  } else {
    return null
  }
}

export const getAllActivity = async () => {
  const queryRef = query(collectionGroup(db, "activity"));
  const docsSnap = await getDocs(queryRef)
  if (docsSnap.size > 0) {
    //return data, id and uid
    return docsSnap.docs.map(doc => ({...doc.data(), id: doc.id, uid: doc.ref.parent.parent.id}));
  } else {
    return null
  }
}


//When Uid is not present

export const createNoUserLead = async (data: any) => {
  const docRef = collection(db,'noUserLeads');
  const result = await addDoc(docRef, data)
    .then((response) => {
      return response.id
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      return null;
    })
  return result;
}

export const deleteNoUserLead = async (leadId: any) => {
  const docRef = doc(db, 'noUserLeads', leadId);
  return await deleteDoc(docRef)
}

export const updateNoUserLead = async (leadId: any, data: any) => {
  const docRef = doc(db, 'noUserLeads', leadId);
  return await setDoc(docRef, data, {merge: true})
}

export const addUserSupportImage = async (uid: any, payload: any) => {
  const ref = doc(db, "users", uid, 'supportChat', payload.id)
  const result = await setDoc(ref, payload, {merge:true})
  return result
}

export const addUserSupportChat = async (uid: any, payload: any) => {
  const ref = collection(db, "users", uid, 'supportChat')
  const result = await addDoc(ref, payload)
  return result
}

export const updateUserTextSupport = async (uid: any, chatId: any,  payload: any) => {
  const ref = doc(db, "users", uid, 'supportChat', chatId)
  const result = await setDoc(ref, payload, {merge:true})
  return result
}

export const getAppSettings = async () => {
  const ref = doc(db, "businessSettings", 'appSettings')
  return (await getDoc(ref)).data()
}

export const getSupportSettings = async () => {
  const ref = doc(db, "businessSettings", 'supportSettings')
  return (await getDoc(ref)).data()
}

export const getRedirectSettings = async () => {
  const ref = doc(db, "businessSettings", 'redirectSettings') 
  return (await getDoc(ref)).data()
}

export const setRedirectSettings = async (url) => {
  const ref = doc(db, "businessSettings", 'redirectSettings')
  const result = await setDoc(ref, {
    history: arrayUnion(url)
  }, {merge: true})
  return result
}

export const getFullChatData  = async (uid: any) => {
  const ref = query(collection(db, "users", uid, 'supportChat'), orderBy('created_at', 'desc'))
  const result = await getDocs(ref)
  //return docs, uid and add id to each doc
  return result.docs.map(doc => {
    return {
      data: doc.data(),
      id: doc.id,
      uid: doc.ref.parent.parent.id
    }
  })
}

//set boolean to /businessSettings/supportSettings/status
export const updateSupportStatus = async (status: string) => {
  const ref = doc(db, "businessSettings", 'supportSettings')
  const result = await setDoc(ref, {status}, {merge: true})
  return result
}

//storage bucket
export const storage = getStorage(app);

export const uploadImages = async (uid: string, fileUri: any) => {
  const randomString = Math.random().toString(36).substring(2);
  const storageRef = ref(storage, `users/${uid}/supportChat/${randomString}`);

  const blob: any = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      console.log(e);
      reject(new TypeError("Network request failed"));
    };
    xhr.responseType = "blob";
    xhr.open("GET", fileUri, true);
    xhr.send(null);
  });
  console.log(`blob converted`)
  await uploadBytesResumable(storageRef, blob)
  console.log(`blob uploaded`)
  return {
    uri: await getDownloadURL(storageRef),
    id: randomString
  };
};





