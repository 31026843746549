import React, {useState, useRef} from 'react';
import { Pressable, Center, Box, Heading, VStack, FormControl, Input, Link, Button, HStack, Text, Image, KeyboardAvoidingView, Toast, View, NativeBaseProvider } from 'native-base';
import { signInEmailPassword } from '../config/firebase';
import * as Fa from 'react-icons/fa';


export const signinUser = (email: string, password: string) => {

  signInEmailPassword(email, password)
    .then(()=>{
      Toast.show({
        title: 'Welcome Back',
        description: `You've been signed in.`
      });
    })
    .catch(error => {
      switch (error.code){
        case 'auth/invalid-email':
            Toast.show({
              title: 'Invalid email',
              description: `Please check your email format.`
            });
            break;
        case 'auth/wrong-password':
            Toast.show({
              title: 'Wrong password',
              description: `The password you entered did not match your previous password.`
            });
            break;
        case 'auth/too-many-requests':
          Toast.show({
            title: 'Too many requests',
            description: `Please do not try to guess your password, use the 'Forgot password' section instead.`
          });
          break;
        case 'auth/user-not-found':
            break;
      }
      console.log(error.code)
    });
}


export const LoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  
    const togglePasswordVisibility = () => {
      setIsPasswordVisible(!isPasswordVisible);
    };

    return (
      <NativeBaseProvider>
      <View flex={1}>
        <Center w="100%" h='100%'>
          <Box safeArea p="2" py="8" w="90%" maxW="290">
            <Heading size="lg" fontWeight="600" color="coolGray.800" _dark={{color: "warmGray.50"}}>
              Welcome to BrokerSpot
            </Heading>
            <Heading mt="1" _dark={{
            color: "warmGray.200"
          }} color="coolGray.600" fontWeight="medium" size="xs">
              Please enter your login details below. If you're new here, an account will be created with your credentials.
            </Heading>
    
            <VStack space={3} mt="5">
              <FormControl>
                <FormControl.Label>Email</FormControl.Label>
                <Input 
                  type='text'
                  onChangeText={(text)=>setEmail(text)}
                  autoCapitalize="none"
                  keyboardType='email-address'
                  
                />
              </FormControl>
              <FormControl>
                <FormControl.Label>Password</FormControl.Label>
                <Input type="password" 
                  onChangeText={(text)=>setPassword(text)}
                  autoCapitalize="none"
                  secureTextEntry={!isPasswordVisible}
                  InputRightElement={
                    <Pressable onPress={togglePasswordVisibility}>
                      <Fa.FaEyeSlash size={20} color='gray' />
                    </Pressable>
                  }
                />
              </FormControl>
              <Button mt="2" colorScheme="indigo"
                  onPress={()=>signinUser(email, password)}
              >
                Sign in
              </Button>
            </VStack>
            
          </Box>
        </Center>
      </View>
      </NativeBaseProvider>
      )
  };