import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserData } from "../config/firebase";


export default function Intercom() {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email') || undefined;

    useEffect(() => {
        try {
            if (email){                
                //@ts-ignore
                window.Intercom("boot", {
                    api_base: "https://api-iam.intercom.io",
                    app_id: "urgaoeak",
                    email: email, // the email for your user
                });
            } else {
                //@ts-ignore
                window.Intercom("boot", {
                    api_base: "https://api-iam.intercom.io",
                    app_id: "urgaoeak",
                });
            }
            //@ts-ignore
            window.Intercom("update");
            //@ts-ignore
            window.Intercom("show")

        } catch (error) {
            console.log(error);
        }
    }, []);
    return (
        <div className="container">
            <div className="container my-5">
                <div className="position-relative p-5 text-center text-muted bg-body">
                    <svg className="bi mt-5 mb-3" width="48" height="48"><use xlinkHref="#check2-circle"></use></svg>
                    <h1 className="text-body-emphasis">Support Center</h1>
                    <p className="col-lg-6 mx-auto mb-4">
                     Tap on the chat icon to start a conversation with us!
                    </p>
                    <p className="col-lg-6 mx-auto mb-4">
                        Or dismiss this window to continue using the app.
                    </p>
                </div>
            </div>
        </div>
    );
}
