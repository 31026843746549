import React from "react";
import OwlCarousel from "react-owl-carousel";

export default function TestimonialTwo({ hasBg }) {
  const options = {
    loop: false,
    margin: 30,
    items: 1,
    nav: true,
    dots: false,
    responsiveClass: true,
    autoplay: false,
    autoplayHoverPause: true,
    lazyLoad: true,
  };
  return (
    <>
      <section
        className={`testimonial-section ptb-100 ${
          hasBg ? "gray-light-bg " : ""
        }`}
      >
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <div className="section-heading mb-5">
                {hasBg ? (
                  <span className="text-uppercase color-secondary sub-title">
                    Our Trusted Clients
                  </span>
                ) : (
                  ""
                )}
                <h2>What Clients Say About Us</h2>
                <p className="lead">
                  Our clients feedback are critical to our success. We are
                  always looking for ways to improve our service and offerings.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <OwlCarousel
                className="owl-carousel owl-theme client-testimonial arrow-indicator"
                {...options}
              >
                <div className="item">
                  <div className="testimonial-quote-wrap">
                    <div className="media author-info mb-3">
                      <div className="author-img mr-3">
                        <img
                          src="assets/img/client-1.jpg"
                          alt="client"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">John Charles</h5>
                        <span>Chicago IL</span>
                      </div>
                    </div>
                    <div className="client-say">
                      <p>
                        <img
                          src="assets/img/quote.png"
                          alt="quote"
                          className="img-fluid"
                        />
                        I have been using this real estate lead generation service for a few months now, 
                        and I must say, it has been a game-changer for my business. The incorporation of 
                        AI technology is evident in the exceptional results it delivers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial-quote-wrap">
                    <div className="media author-info mb-3">
                      <div className="author-img mr-3">
                        <img
                          src="assets/img/client-2.jpg"
                          alt="client"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">Arabella Ora</h5>
                        <span>San Diego CA</span>
                      </div>
                    </div>
                    <div className="client-say">
                      <p>
                        <img
                          src="assets/img/quote.png"
                          alt="quote"
                          className="img-fluid"
                        />
                        As a real estate agent, staying ahead of the competition is crucial.
                        The platform's user-friendly interface and intuitive features make it 
                        easy to navigate and maximize its potential. If you're looking for 
                        innovation, this is the one to try!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial-quote-wrap">
                    <div className="media author-info mb-3">
                      <div className="author-img mr-3">
                        <img
                          src="assets/img/client-4.jpg"
                          alt="client"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">Jeremy Jere</h5>
                        <span>Miami FL</span>
                      </div>
                    </div>
                    <div className="client-say">
                      <p>
                        <img
                          src="assets/img/quote.png"
                          alt="quote"
                          className="img-fluid"
                        />
                        The lead quality has significantly improved my lead conversion rates.
                        If you're looking for a lead generation service that utilizes the 
                        latest AI advancements and offers a personalized touch, look no further!
                      </p>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
