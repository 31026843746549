import React, { useEffect, useState } from 'react';
import {usePlacesWidget} from "react-google-autocomplete";
import { Alert, AlertDialog, Avatar, Box, Button, Center, Checkbox, Container, Flex, HStack, Heading, Image, Input, InputGroup, InputLeftAddon, Link, NativeBaseProvider, Pressable, ScrollView, Spinner, Stack, Text, Toast, VStack, View } from 'native-base';
import * as Fa from "react-icons/fa";
import { auth, completeLead, createNoUserLead, createPartialLead, deleteNoUserLead, deletePartialLead, getUserData, updateNoUserLead, updatePartialLead } from '../config/firebase';
import { formatPhoneNumber } from '../functions/formatPhoneNumber';
import { getPropertyData } from '../api/certiapi';
import { Estated } from '../interfaces/propertyData';
import { serverTimestamp } from 'firebase/firestore';
import ReactPlayer from 'react-player/lazy';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';



//Create a multistep form with the following steps:
//1. User enters an address and unit no, use the autocomplete from google maps
//2. Fetch property data from backend and display a satelite image of the property, the address and the number of bedrooms and bathrooms for the user to confirm
//3. User selects the condition of the home from multiple buttons
//4. User selects how soon they want to sell the home from multiple buttons
//5. User enters their phone number
//6. User enters their name and email, and clicks Generate Report

//When the user clicks Generate Report, the following should happen:
//1. The user's information should be sent to the backend
//2. The backend should generate a report with the user's information and the property data
//3. The report should be sent to the user's email address
//4. The user should be redirected to a page that says "Thank you for your submission, you will receive an email shortly"

const AgentProfile = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  // Add event listener to update window dimensions on resize
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [address, setAddress] = useState("");
  const [fullAddress, setFullAddress] = useState<string | null>(null);
  const [unitNo, setUnitNo] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [disableSubmit, setDisableSubmit] = useState(true);

  const [leadId, setLeadId] = useState<any>(null);
  const [propertyData, setPropertyData] = useState<Estated>();
  const [step, setStep] = useState(1);

  const apiKey = "AIzaSyDAdP2bX_HvU4yI4iFBy9oEQNQiyxURakU";

  //get uid from url params
  const urlParams = new URLSearchParams(window.location.search);
  const uid = urlParams.get('uid') || undefined;
  const subId = urlParams.get('subId');
  const username = useParams<{username: string}>().username;


  //get user data from firebase using uid
  const [userData, setUserData] = useState<any>(null);

  const retrieveUserData = async () => {
    if (uid || username){
      const userData = await getUserData(uid, username);
      console.log(userData)
      setUserData(userData);
    }
  }

  useEffect(() => {
    retrieveUserData()
  }, [])

  const renderScreen = () => {
    const defaultbackground = 'https://firebasestorage.googleapis.com/v0/b/brokerspotapp.appspot.com/o/uploads%2Fcreative_3.jpg?alt=media&token=2c867047-5a60-4eb7-9711-5e96362f95bf';
    return (
      <View>
        <Helmet>
          <title>{`${userData?.public?.name} - ${userData?.public?.broker}`}</title>
          <meta name="description" content={`${userData?.public?.bio}`} />
          <meta property="og:image" content={`${userData?.public?.avatar}`} />
          <meta property="og:image:width" content="1080" /> 
          <meta property="og:image:height" content="1080" />
          <meta property="og:image:alt" content={`${userData?.public?.name} - ${userData?.public?.broker}`} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`${userData?.public?.name} - ${userData?.public?.broker}`} />
          <meta property="og:description" content={`${userData?.public?.bio}`} />
        </Helmet>
        {/* Rest of your component */}

        <Box p="5" rounded="xl" mb={2} mt={2} maxW={700} backgroundColor={userData?.customize?.homeValueCardBackgroundColor || 'white'} shadow={'5'}>
          <VStack alignItems={'center'}>
          <Box h={200} mb={12} w={'100%'} backgroundColor={'black'} borderRadius={5} alignItems={'center'}>
              <Image opacity={30} source={{uri: (userData?.customize?.homeValueProfileBackground)?userData?.customize?.homeValueProfileBackground:defaultbackground}} h={'100%'} w={'100%'} alt="Profile background" borderRadius={5} resizeMode='cover'/>
              <Avatar size={'2xl'} m={-20} source={{uri: userData?.public?.avatar}} borderColor={'white'} borderWidth={5}/>
          </Box>
          <Box alignItems={'center'}>
              {userData?.public?.name && <Heading color={userData?.customize?.homeValueHeadingColor || 'coolGray.700'}>{userData?.public?.name}</Heading>}
              {userData?.public?.broker  && (
              <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{userData?.public?.broker}</Text>
              )}
              {userData?.public?.license  && (
              <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{userData?.public?.license}</Text>
              )}
              <HStack m={5} space={2} alignItems={'center'}>
              {userData?.public?.phone && (
              <Link isExternal  href={`tel:${userData?.public?.phone}`}>
                  <Button size={'lg'} colorScheme={'success'}  leftIcon={<Fa.FaPhone size={15} color={'white'}/>}>
                  Call
                  </Button>
              </Link>
              )}
              {userData?.public?.email && (
              <Link isExternal  href={`mailto:${userData?.public?.email}`}>
                  <Button size={'lg'} colorScheme={'info'}  leftIcon={<Fa.FaEnvelope size={15} color={'white'}/>}>
                  Email
                  </Button>
              </Link> 
              )}
              </HStack>
              {userData?.public?.bio && <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{userData?.public?.bio}</Text>}
              <HStack m={5} space={2} alignItems={'center'}>
              {userData?.public?.website && (
                  <Link isExternal  href={userData?.public?.website} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                  <Fa.FaGlobe size={30}/>
                  </Link>
              )}
              {userData?.public?.facebook && (
                  <Link isExternal  href={userData?.public?.facebook} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                  <Fa.FaFacebook size={30}/>
                  </Link>
              )}
              {userData?.public?.instagram && (
                  <Link isExternal  href={userData?.public?.instagram} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                  <Fa.FaInstagram size={30}/>
                  </Link>
              )}
              {userData?.public?.twitter && (
                  <Link isExternal  href={userData?.public?.twitter} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                  <Fa.FaTwitter size={30}/>
                  </Link>
              )}
              {userData?.public?.linkedin && (
                  <Link isExternal  href={userData?.public?.linkedin} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                  <Fa.FaLinkedin size={30}/>
                  </Link> 
              )}
              {userData?.public?.youtube && (
                  <Link isExternal  href={userData?.public?.youtube} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                  <Fa.FaYoutube size={30}/>
                  </Link>
              )}
              </HStack>
              <ReactPlayer url={userData?.public?.featured_video} width={'100%'} height={200} controls={true}  />
          </Box>
          </VStack>
      </Box>
    </View>
    )
  }

  return (
      <NativeBaseProvider>
        <Center 
          backgroundColor={userData?.customize?.homeValueBackgroundColor || 'blueGray.300'} 
          height={windowHeight}
          alignContent={'center'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          justifyItems={'center'}
        >
          {renderScreen()}
        </Center>
      </NativeBaseProvider>
  );

}

export default AgentProfile;
