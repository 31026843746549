import React from "react";
import LatestNewsOne from "../../components/blogs/LatestNewsOne";
import Contact from "../../components/contact/Contact";
import CounterTwo from "../../components/counter/CounterTwo";
import FeatureImg from "../../components/features/FeatureImg";
import FeatureImgTwo from "../../components/features/FeatureImgTwo";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import Subsribe from "../../components/newsletter/Subsribe";
import Price from "../../components/prices/Price";
import VideoPromo from "../../components/promo/VideoPromo";
import Screenshot from "../../components/screenshot/Screenshot";
import Team from "../../components/team/Team";
import BrandCarousel from "../../components/testimonial/BrandCarousel";
import HeroEight from "./HeroEight";
import Cta from "../../components/cta/Cta";
import VideoPromoTwo from "../../components/promo/VideoPromoTwo";
import Testimonial from "../../components/testimonial/Testimonial";
import TestimonialTwo from "../../components/testimonial/TestimonialTwo";
import { NativeBaseProvider } from "native-base";
import { Helmet } from 'react-helmet-async';

export default function HomeEight() {
  return (
    <div>
      <Helmet>
        <title>BrokerSpot - Lead Generation powered by AI</title>
        <meta property="og:image" content="https://brokerspot.com/assets/img/bs-logo-2.jpg" />
        <meta property="og:image:width" content="1080" /> 
        <meta property="og:image:height" content="1080" />
        <meta property="og:image:alt" content="BrokerSpot Featured Image" /> 
        <meta property="og:type" content="website" />
        <meta property="og:title" content="BrokerSpot - Lead Generation powered by AI" />
        <meta property="og:description" content="We Generate Quality Leads for Real Estate Agents" />
      </Helmet>
      <NativeBaseProvider>
        <Layout>
          {/* <Navbar classOption="custom-header" darkBg={undefined} isHome={true} /> */}
          <HeroEight />
          <CounterTwo />
          {/* <TestimonialTwo hasBg={undefined} /> */}
          <FeatureImg ImgSource="assets/img/image-14-2.png" />
          {/* <VideoPromoTwo /> */}
          {/* <Price hasBg={undefined} /> */}
          {/* <Screenshot hasBg /> */}
          {/* <LatestNewsOne bgColor="primary-bg" /> */}
          {/* <Team /> */}
          {/* <Contact bgColor="gray-light-bg" /> */}
          <BrandCarousel hasBg={undefined} />
          <Subsribe />
          <Footer space />
        </Layout>
      </NativeBaseProvider>
    </div>
  );
}
