import React from "react";
import { Link } from "react-router-dom";

export default function Contact({ bgColor }) {
  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row" style={{alignItems: 'center'}}>
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-7" style={{textAlign: 'center'}}>
              <div className="section-heading">
                <h2>Connect With Our Support Team</h2>
                <p>
                  It's very easy to get in touch with us. Just use the in-app chat for real time support or send us an email and we will get back to you in a few minutes!
                </p>
              </div>
              <div className="footer-address">
                <ul>
                  <li>
                    <span>
                      Email: 
                      <a href="mailto:support@brokerspot.com" className="ml-2">
                        support@brokerspot.com
                      </a>
                    </span>
                  </li>
                </ul>
                <div className="download-btn mt-5">
                  <Link to="https://apps.apple.com/us/app/brokerspot/id6448661305" target="_blank" className="btn google-play-btn mr-3" style={{backgroundColor: 'gray', color: 'white'}}>
                    <span className="ti-apple" ></span> App Store
                  </Link>
                  <Link to="https://play.google.com/store/apps/details?id=com.branoli.brokerspot" target="_blank" className="btn google-play-btn" style={{backgroundColor: 'gray', color: 'white'}}>
                    <span className="ti-android" ></span> Google Play
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-5" style={{textAlign: 'center'}}>
              {/* Add an image here */}
              <img src="assets/img/support-chat.png" alt="contact" style={{maxWidth: 300, borderRadius: 35}}/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
