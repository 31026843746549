import React, { useState } from "react";
import Papa from "papaparse";

function CSVFileReader() {
  const [loading, setLoading] = useState(false);
  const [jsonData, setJSONData] = useState([]);

  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];

    await Papa.parse(file, {
      header: true, // Treat the first row as headers
      dynamicTyping: true,
      complete: (result) => {
        setJSONData(result.data);
      },
    });
    setLoading(false);
  };

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleFileUpload} />
      <div>
        <h2>JSON Data</h2>
        {!loading && <pre>{JSON.stringify(jsonData, null, 2)}</pre>}
        {loading && <h3>Reading file...</h3>}
      </div>
    </div>
  );
}

export default CSVFileReader;
