import React, { useEffect, useRef, useState, useMemo, useCallback, memo } from 'react'
import { collection, collectionGroup, deleteDoc, doc, getCountFromServer, getDoc, getDocs, limit, onSnapshot, orderBy, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { db } from '../config/firebase';

function EmptyPage() {
    
    const [memes, setMemes] = useState([])
    const [loading, setLoading] = useState(false)
    const [remaining, setRemaining] = useState(0)

    const getMemes = async () => {
        //get memes from firestore /salesTaskForce/facebook/memes/
        const ref = collection(db, 'salesTaskForce', 'facebook', 'memes')
        const q = query(ref, where('folder', '==', 'memes'), limit(1))
        const querySnapshot = await getDocs(q)
        const memes = querySnapshot.docs.map(doc => {
            return { id: doc.id, ref: doc.ref, ...doc.data() }
        })
        setMemes(memes)
    }

    const getRemaining = async () => {
        //get memes from firestore /salesTaskForce/facebook/memes/
        const ref = collection(db, 'salesTaskForce', 'facebook', 'memes')
        const q = query(ref, where('folder', '==', 'memes'))
        const count = await getCountFromServer(q)
        
        setRemaining(count.data().count)
    }

    const handleMeme = async (meme, status) => {
        if (status === 'good') {
            await updateDoc(meme.ref, {
                ...meme,
                folder: 'goodMemes'
            })
        } else {
            await deleteDoc(meme.ref)
        }
        await getMemes()
        setRemaining(remaining - 1)
    }

    useEffect(() => {
        getMemes()
        getRemaining()
    }, [])

    return (
        <div>
            {memes.map((meme, index) => {
                return (
                    <div key={meme.id} style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        <img src={meme.url} alt="meme" style={{width: 500}}/>
                        <div style={{fontSize: 20, fontWeight: 'bold', color: 'white', backgroundColor: 'black', padding: 10, borderRadius: 10, marginTop: 10}}>
                            {remaining} memes remaining
                        </div>
                        <div style={{bottom: 10, position: 'absolute'}}>
                            <button onClick={async () => {
                                setLoading(true)
                                await handleMeme(meme, 'bad')
                                setLoading(false)
                            }
                            } style={{
                                backgroundColor: '#f44336',
                                border: 'none',
                                color: 'white',
                                padding: 35,
                                textAlign: 'center',
                                textDecoration: 'none',
                                display: 'inline-block',
                                fontSize: 50,
                                margin: 4,
                                cursor: 'pointer',
                            
                            }} disabled={(loading)?true:false}>Bad</button>
                            <button onClick={async() => {
                                setLoading(true)
                                await handleMeme(meme, 'good')
                                setLoading(false)
                            }} style={{
                                backgroundColor: '#4CAF50',
                                border: 'none',
                                color: 'white',
                                padding: 35,
                                textAlign: 'center',
                                textDecoration: 'none',
                                display: 'inline-block',
                                fontSize: 50,
                                margin: 4,
                                cursor: 'pointer',
                            }} disabled={(loading)?true:false}>Good</button>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default EmptyPage;