import React, { useEffect, useState } from 'react';
import {usePlacesWidget} from "react-google-autocomplete";
import { Alert, AlertDialog, Avatar, Box, Button, Center, Checkbox, Container, Flex, HStack, Heading, Image, Input, InputGroup, InputLeftAddon, Link, NativeBaseProvider, Pressable, ScrollView, Select, Spinner, Stack, Text, TextArea, Toast, VStack } from 'native-base';
import * as Fa from "react-icons/fa";
import { auth, completeLead, createNoUserLead, createPartialLead, deleteNoUserLead, deletePartialLead, getUserData, updateLead, updateNoUserLead, updatePartialLead } from '../config/firebase';
import { formatPhoneNumber } from '../functions/formatPhoneNumber';
import { getPropertyData } from '../api/certiapi';
import { Estated } from '../interfaces/propertyData';
import { serverTimestamp } from 'firebase/firestore';
import ReactPlayer from 'react-player/lazy';
import { useParams } from 'react-router-dom';


const BuyerForm = () => {

  const urlParams = new URLSearchParams(window.location.search);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  // Add event listener to update window dimensions on resize
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [disableSubmit, setDisableSubmit] = useState(true);

  const startStep = urlParams.get('leadId') ? 3 : 1;

  const [step, setStep] = useState(startStep);
  const [minBudget, setMinBudget] = useState('100000');
  const [maxBudget, setMaxBudget] = useState("");



  //get uid from url params
  
  const uid = urlParams.get('uid') || undefined;
  const subId = urlParams.get('subId');
  const username = useParams<{username: string}>().username;

  const [homeTypes, setHomeTypes] = useState<any>([]);

  const [locationText, setLocationText] = useState('');


  //get user data from firebase using uid
  const [userData, setUserData] = useState<any>(null);
  const [leadId, setLeadId] = useState<any>(urlParams.get('leadId') || null);

  const retrieveUserData = async () => {
    if (uid || username){
      const userData = await getUserData(uid, username);
      console.log(userData)
      setUserData(userData);
    }
  }

  useEffect(() => {
    retrieveUserData()
  }, [])

  const handleNext = () => {
    setStep(step + 1);
  }

  const handleBack = () => {
    setStep(step - 1);
  }

  const handleRestart = () => {
    if (uid && leadId){
      deletePartialLead(uid, leadId);
    } else {
      deleteNoUserLead(leadId);
    }
    window.location.reload();
  }


  const renderStep = () => {
    switch(step) {
      case 1:
        return ( 
          <>
            <Box p="5" rounded="xl" mb={3} mt={10} backgroundColor={userData?.customize?.buyerFormCardBackgroundColor ||'white'} shadow={'5'} alignItems={'center'} >
                <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
                    <Stack w={"90%"} m={5}>
                        <Heading color={userData?.customize?.buyerFormHeadingColor || 'coolGray.700'}>What is your phone number?</Heading>
                        <Text fontSize={'lg'} mb={5} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>{`Please kindly provide a phone number to send the list of homes to.`}</Text>
                        <VStack space={2}>
                        <Input type='text' key={'phone'} inputMode='tel' placeholder="Phone Number" mb={5} size={'2xl'} backgroundColor={'white'} defaultValue=''
                          onChangeText={async(text) => {
                            //Validate phone number format
                            if (typeof parseInt(text) === 'number' && text.length >= 10 || userData?.customize?.buyerFormVerifyPhone == false) {
                              if (uid){
                                const docId = await createPartialLead(uid, {
                                  phone: text,
                                  created_at: serverTimestamp(),
                                  original_uid: uid,
                                  push_status: 'pending',
                                  status: "not_contacted",
                                  subscription: subId,
                                  type: "buyer_lead",
                                  visibility: "new"
                                })
                                setLeadId(docId);
                                console.log(docId)
                              } else {
                                const docId = await createNoUserLead({
                                  created_at: serverTimestamp(),
                                  push_status: 'pending',
                                  status: "not_contacted",
                                  type: "buyer_lead",
                                  visibility: "new",
                                  assignedToUsers: []
                                })
                                setLeadId(docId);
                                console.log(docId)
                              }
                              setDisableSubmit(false)
                            } else {
                              setDisableSubmit(true)
                            }
                          }}
                        />
                        <Button bgColor={userData?.customize?.buyerFormMainButtonColor || 'success.600'} w={'full'} 
                          onPress={()=>{
                            handleNext()
                            setDisableSubmit(true)
                          }} 
                          isDisabled={(userData?.customize?.buyerFormVerifyPhone == false) ? false : disableSubmit}>
                            <Text color={userData?.customize?.buyerFormMainButtonTextColor || 'white'} bold fontSize={'2xl'}>Continue</Text>
                        </Button>
                        </VStack>
                    </Stack>
                </Flex>
            </Box>
          </>
        )
      case 2:
        return (
          <>
            <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.buyerFormCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
                <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
                    <Stack w={"90%"} m={5}>
                        <Heading color={userData?.customize?.buyerFormHeadingColor || 'coolGray.700'}>What is your name and email?</Heading>
                        <Text fontSize={'lg'} mb={5} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>{`Please kindly provide a name and email.`}</Text>
                        <VStack space={2}>
                          <Input type='text' key={'name'} autoComplete='name' placeholder="Name" mb={5}size={'2xl'} backgroundColor={'white'} defaultValue=''
                            onChangeText={async(text) => {
                              if (uid){
                                await updatePartialLead(uid, leadId, {name: text})
                              } else {
                                await updateNoUserLead(leadId, {name: text})
                              }
                            }}
                          />
                          <Input type='text' key={'email'} autoComplete='email' autoCapitalize='none' placeholder="Email" mb={5} size={'2xl'} backgroundColor={'white'} defaultValue=''
                            onChangeText={async(text) => {
                              //validate email format
                              const regex = (userData?.customize?.buyerFormVerifyEmail == false) ? /^.+$/ : /\S+@\S+\.\S+/ ;
                              if (regex.test(text)) {
                                if (uid){
                                  await updatePartialLead(uid, leadId, {email: text})
                                } else {
                                  await updateNoUserLead(leadId, {email: text})
                                }
                                setDisableSubmit(false)
                              } else {
                                setDisableSubmit(true)
                              }
                            }}
                          />
                          <Button bgColor={userData?.customize?.buyerFormMainButtonColor || 'success.600'}  w={'full'} 
                            isDisabled={(userData?.customize?.buyerFormVerifyEmail == false) ? false : disableSubmit}
                            onPress={()=>{
                              handleNext()
                              if (uid){
                                completeLead(uid, leadId)
                              }
                            }}
                          >
                            <Text color={userData?.customize?.buyerFormMainButtonTextColor || 'white'} bold fontSize={'2xl'}>Continue</Text>
                          </Button>
                        </VStack>
                    </Stack>
                </Flex>
            </Box>
          </>
        )
      case 3: // Ask for number of bedrooms
        userData?.customize?.buyerFormDisabledSteps?.includes(3) && handleNext()
        return (
          // Ask for number of bedrooms
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.buyerFormCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.buyerFormHeadingColor || 'coolGray.700'} mb={5}><Fa.FaBed size={20} color={userData?.customize?.buyerFormHeadingColor || 'gray'} /> How many bedrooms?</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>Please select the desired number of bedrooms for your new home.</Text>
                <VStack space={2}>
                  {/* Create 5 buttons for each bedroom option */}
                  {['1+', '2+', '3+', '4+', '5+'].map((bedroomOption, index) => (
                    <Button key={index} bgColor={userData?.customize?.buyerFormMainButtonColor || 'info.600'} w={'full'}
                      onPress={async ()=>{
                        handleNext()
                        if (uid){
                          console.log('updating partial lead', uid, leadId, {buyBedrooms: bedroomOption})
                          await updateLead(uid, leadId, {buyBedrooms: bedroomOption})
                        } else {
                          await updateNoUserLead(leadId, {buyBedrooms: bedroomOption})
                          
                        }
                      }}
                    >
                      <Text color={userData?.customize?.buyerFormMainButtonTextColor || 'white'} bold fontSize={'2xl'}>{bedroomOption}</Text>
                    </Button>
                  ))}                  
                </VStack>
              </Stack>
            </Flex>
          </Box>
        );
      case 4: // Ask for number of bathrooms
        userData?.customize?.buyerFormDisabledSteps?.includes(4) && handleNext()
        return (
          // Ask for number of bathrooms
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.buyerFormCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.buyerFormHeadingColor || 'coolGray.700'} mb={5}><Fa.FaBath size={20} color={userData?.customize?.buyerFormHeadingColor || 'gray'} /> How many bathrooms?</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>Please select the desired number of bathrooms for your new home.</Text>
                <VStack space={2}>
                  {/* Create 5 buttons for each bathroom option */}
                  {['1+', '2+', '3+', '4+', '5+'].map((bathroomOption, index) => (
                    <Button key={index} bgColor={userData?.customize?.buyerFormMainButtonColor || 'info.600'} w={'full'}
                      onPress={async ()=>{
                        handleNext()
                        if (uid){
                          await updateLead(uid, leadId, {buyBathrooms: bathroomOption})
                        } else {
                          await updateNoUserLead(leadId, {buyBathrooms: bathroomOption})
                        }
                      }}
                    >
                      <Text color={userData?.customize?.buyerFormMainButtonTextColor || 'white'} bold fontSize={'2xl'}>{bathroomOption}</Text>
                    </Button>     
                  ))}
                </VStack>
              </Stack>
            </Flex>
          </Box>
        );
      case 5: // Ask for price range using a dropdown menu
        userData?.customize?.buyerFormDisabledSteps?.includes(5) && handleNext()
        return (
          // Ask for price range
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.buyerFormCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.buyerFormHeadingColor || 'coolGray.700'} mb={5}>What is your price range?</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>Please select the desired price range for your new home.</Text>
                <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
                  {/* Create 2 dropdowns */}
                  <Select
                    selectedValue={minBudget}
                    minWidth={200}
                    accessibilityLabel="Minimum Budget"
                    placeholder="Minimum Budget"
                    onValueChange={async (itemValue) => {
                      setMinBudget(itemValue);
                      if (uid){
                        await updateLead(uid, leadId, {buyMinPrice: itemValue})
                      } else {
                        await updateNoUserLead(leadId, {buyMinPrice: itemValue})
                      }
                    }}
                    _selectedItem={{
                      bg: "cyan.600",
                      endIcon: <Fa.FaArrowDown size={10} color={'white'} />,
                    }}
                    mb={5}
                    size={'xl'}
                    bgColor={'white'}
                  >
                    {/* Create the items for the dropdown starting in the $100,000 range and going up to +$1,500,000, the value of each item should be the same as the label */}
                    { ['$100,000', '$200,000', '$300,000', '$400,000', '$500,000', '$600,000', '$700,000', '$800,000', '$900,000', '$1,000,000', '$1,100,000', '$1,200,000', '$1,300,000', '$1,400,000', '+$1,500,000'].map((priceOption, index) => (
                      <Select.Item key={index} label={priceOption} value={priceOption} />
                    ))
                    }
                  </Select>
                  <Select
                    selectedValue={maxBudget}
                    minWidth={200}
                    accessibilityLabel="Maximum Budget"
                    placeholder="Maximum Budget"
                    onValueChange={
                      (itemValue) => {
                        setMaxBudget(itemValue)
                        if (uid){
                          updateLead(uid, leadId, {buyMaxPrice: itemValue})
                        } else {
                          updateNoUserLead(leadId, {buyMaxPrice: itemValue})
                        }
                      }}
                    _selectedItem={{
                      bg: "cyan.600",
                      endIcon: <Fa.FaArrowDown size={10} color={'white'} />,
                    }}
                    mb={5}
                    size={'xl'}
                    bgColor={'white'}
                  >
                    {/* Create the items for the dropdown starting in the minBudget and adding $1,000,000, the value of each item should be the same as the label */}
                    {Array.from(Array(15).keys()).map((priceOption, index) => {
                      const price = (parseInt(minBudget.replace('$', '').replace(',', '')) + (index * 100000))
                                      .toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0})
                      return (
                      <Select.Item key={index} label={price} value={price} />
                      )}
                    )
                    }
                  </Select>
                </Flex>
                {/* Continue Button */}
                <Button bgColor={userData?.customize?.buyerFormMainButtonColor || 'info.600'} w={'full'} onPress={handleNext}>
                  <Text color={userData?.customize?.buyerFormMainButtonTextColor || 'white'} bold fontSize={'2xl'}><Fa.FaArrowRight size={20} color={userData?.customize?.buyerFormMainButtonTextColor || 'white'} />  Continue</Text>
                </Button>
              </Stack>
            </Flex>
          </Box>
        );
      case 6: // Ask for what type of home they want using a checkbox
        userData?.customize?.buyerFormDisabledSteps?.includes(6) && handleNext()
        return (
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.buyerFormCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.buyerFormHeadingColor || 'coolGray.700'} mb={5}>What type of homes are you looking for?</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>You can choose multiple options</Text>
                <Flex flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'} justifyContent="flex-start">
                  {/* Create checkboxes */}
                  { ['Houses', 'Townhomes', 'Condos', 'Lots/Land', 'Multifamily', 'Apartments'].map((homeOption, index) => (
                    <Checkbox colorScheme="info" size={'lg'} mb={5} mr={5} key={index} value={homeOption}
                      onChange={() => {
                        if (homeTypes.includes(homeOption)){
                          setHomeTypes(homeTypes.filter((item: any) => item !== homeOption))
                          if (uid){
                            updateLead(uid, leadId, {homeTypes: homeTypes.filter((item: any) => item !== homeOption)})
                          } else {
                            updateNoUserLead(leadId, {homeTypes: homeTypes.filter((item: any) => item !== homeOption)})
                          }
                        } else {
                          setHomeTypes([...homeTypes, homeOption])
                          if (uid){
                            updateLead(uid, leadId, {homeTypes: [...homeTypes, homeOption]})
                          } else {
                            updateNoUserLead(leadId, {homeTypes: [...homeTypes, homeOption]})
                          }
                        }
                      }}
                    >
                      <Text fontSize={'lg'} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>{homeOption}</Text>
                    </Checkbox>
                  ))
                  }
                </Flex>
                {/* Reset Button */}
                <HStack justifyContent={'flex-end'} mb={5}>
                  {/* Continue Button */}
                  <Button bgColor={userData?.customize?.buyerFormMainButtonColor || 'info.600'} onPress={handleNext}>
                    <Text color={userData?.customize?.buyerFormMainButtonTextColor || 'white'} bold fontSize={'2xl'}><Fa.FaArrowRight size={20} color={userData?.customize?.buyerFormMainButtonTextColor || 'white'} />  Continue</Text>
                  </Button>
                </HStack>
              </Stack>
            </Flex>
          </Box>
        );
      case 7: // Ask if they are pre-approved
        userData?.customize?.buyerFormDisabledSteps?.includes(7) && handleNext()
        return (
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.buyerFormCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.buyerFormHeadingColor || 'coolGray.700'} mb={5}>Are you pre-approved?</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>We can suggest a professional loan officer if needed.</Text>
                <Flex flexDirection={'row'} flexWrap={'wrap'} justifyContent="space-around">
                  {/* Create Yes / No buttons and handle next on press */}
                  <Button colorScheme={'info'} w={'1/3'} size={'lg'} mb={5} mr={5} onPress={() => {
                    handleNext()
                    if (uid){
                      updateLead(uid, leadId, {preApproved: false})
                    } else {
                      updateNoUserLead(leadId, {preApproved: false})
                    }
                  }}>
                    <Text color={'white'} bold fontSize={'2xl'}>No</Text>
                  </Button>
                  <Button colorScheme={'info'} w={'1/3'} size={'lg'} mb={5} mr={5} onPress={() => {
                    handleNext()
                    if (uid){
                      updateLead(uid, leadId, {preApproved: true})
                    } else {
                      updateNoUserLead(leadId, {preApproved: true})
                    }
                  }}>
                    <Text color={'white'} bold fontSize={'2xl'}>Yes</Text>
                  </Button>
                </Flex>
              </Stack>
            </Flex>
          </Box>
        );
      case 8: // Ask if they are working with an agent
        userData?.customize?.buyerFormDisabledSteps?.includes(8) && handleNext()
        return (
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.buyerFormCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.buyerFormHeadingColor || 'coolGray.700'} mb={5}>Are you working with an agent?</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>Ethical rules prevent us from working with you if you are working with another agent.</Text>
                <Flex flexDirection={'row'} flexWrap={'wrap'} justifyContent="space-around">
                  {/* Create Yes / No buttons and handle next on press */}
                  
                  <Button colorScheme={'info'} w={'1/3'} size={'lg'} mb={5} mr={5} onPress={() => {
                    handleNext()
                    if (uid){
                      updateLead(uid, leadId, {workingWithAgent: false})
                    } else {
                      updateNoUserLead(leadId, {workingWithAgent: false})
                    }
                  }}>
                    <Text color={'white'} bold fontSize={'2xl'}>No</Text>
                  </Button>
                  <Button colorScheme={'info'} w={'1/3'} size={'lg'} mb={5} mr={5} onPress={() => {
                    handleNext()
                    if (uid){
                      updateLead(uid, leadId, {workingWithAgent: true})
                    } else {
                      updateNoUserLead(leadId, {workingWithAgent: true})
                    }
                  }}>
                    <Text color={'white'} bold fontSize={'2xl'}>Yes</Text>
                  </Button>
                </Flex>
              </Stack>
            </Flex>
          </Box>
        );
      case 9: // Ask for their timeframe
      userData?.customize?.buyerFormDisabledSteps.includes(9) && handleNext()
        return (
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.buyerFormCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.buyerFormHeadingColor || 'coolGray.700'}>What is your timeframe?</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>{`Please kindly provide a preferred timeframe within which you would like to buy your new home.`}</Text>
                <VStack space={2}>
                  <Button colorScheme={'danger'} w={'full'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {buyTimeframe: '6+ Months'})
                      } else {
                        await updateNoUserLead(leadId, {buyTimeframe: '6+ Months'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>6+ Months</Text>
                  </Button>
                  <Button colorScheme={'warning'} w={'full'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {buyTimeframe: '3-6 Months'})
                      } else {
                        await updateNoUserLead(leadId, {buyTimeframe: '3-6 Months'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>3-6 Months</Text>
                  </Button>
                  <Button colorScheme={'info'} w={'full'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {buyTimeframe: '1-3 Months'})
                      } else {
                        await updateNoUserLead(leadId, {buyTimeframe: '1-3 Months'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>1-3 Months</Text>
                  </Button>
                  <Button colorScheme={'success'} w={'full'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {buyTimeframe: 'ASAP'})
                      } else {
                        await updateNoUserLead(leadId, {buyTimeframe: 'ASAP'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>ASAP</Text>
                  </Button>
                </VStack>
              </Stack>
            </Flex>
          </Box>
        );
      case 10: // Ask Must you sell your home or complete a lease period before buying?
        userData?.customize?.buyerFormDisabledSteps?.includes(10) && handleNext()
        return (
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.buyerFormCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.buyerFormHeadingColor || 'coolGray.700'} mb={5}>Must you sell your home before buying?</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>We may be able to help you sell your current home at the best price.</Text>
                <Flex flexDirection={'row'} flexWrap={'wrap'} justifyContent="space-around">
                  {/* Create Yes / No buttons and handle next on press */}
                  <Button colorScheme={'info'} w={'1/3'} size={'lg'} mb={5} mr={5} onPress={() => {
                    handleNext()
                    if (uid){
                      updateLead(uid, leadId, {mustSell: true})
                    } else {
                      updateNoUserLead(leadId, {mustSell: true})
                    }
                  }}>
                    <Text color={'white'} bold fontSize={'2xl'}>Yes</Text>
                  </Button>
                  <Button colorScheme={'info'} w={'1/3'} size={'lg'} mb={5} mr={5} onPress={() => {
                    handleNext()
                    if (uid){
                      updateLead(uid, leadId, {mustSell: false})
                    } else {
                      updateNoUserLead(leadId, {mustSell: false})
                    }
                  }}>
                    <Text color={'white'} bold fontSize={'2xl'}>No</Text>
                  </Button>
                </Flex>
              </Stack>
            </Flex>
          </Box>
        );    
      case 11: // Ask "Is there a particular location you prefer?"
        userData?.customize?.buyerFormDisabledSteps?.includes(11) && handleNext()
        return (
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.buyerFormCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.buyerFormHeadingColor || 'coolGray.700'} mb={5}>Is there a particular location you prefer?</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>We may be able to help you find a home in your preferred location.</Text>
                <Flex flexDirection={'row'} flexWrap={'wrap'} justifyContent="space-around">
                  {/* Create a textarea to allow the user to insert text */}
                  <TextArea
                    w={'full'}
                    placeholder="Let us know what location you prefer"
                    size="xl"
                    mb={5}
                    autoCompleteType={'string'}
                    
                    onChangeText={(text: string) => {
                      setLocationText(text)
                    }}
                    
                    backgroundColor={userData?.customize?.buyerFormInputBackgroundColor || 'white'}
                    color={userData?.customize?.buyerFormInputTextColor || 'coolGray.700'}
                    borderColor={userData?.customize?.buyerFormInputBorderColor || 'coolGray.200'}
                    borderWidth={1}
                    borderRadius={5}
                    _focus={{
                      borderColor: userData?.customize?.buyerFormInputBorderColor || 'coolGray.200',
                      shadow: 'outline',
                    }}
                    _hover={{
                      borderColor: userData?.customize?.buyerFormInputBorderColor || 'coolGray.200',
                      shadow: 'outline',
                    }}
                    
                  />
                </Flex>
                {/* Click Next to continue */}
                <Button bgColor={userData?.customize?.buyerFormMainButtonColor || 'info.600'} w={'full'} size={'lg'} mb={5} mr={5} onPress={() => {
                  handleNext()
                  if (uid){
                    updateLead(uid, leadId, {buyLocationText: locationText})
                   } else {
                    updateNoUserLead(leadId, {buyLocationText: locationText})
                   }

                }}>
                  <Text color={userData?.customize?.buyerFormMainButtonTextColor || 'white'} bold fontSize={'2xl'}><Fa.FaArrowRight size={20} color={userData?.customize?.buyerFormMainButtonTextColor || 'white'} />  Submit Answers</Text>
                </Button>
              </Stack>
            </Flex>
          </Box>
        );
      case 12: // Show agents profile
        return (
          <Stack>
            {userData && (
              <>
              <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.buyerFormCardBackgroundColor || 'white'} shadow={'5'}>
                {/* Notify user that the list nof homes for sale will be sent to them shortly */}
                <Heading size={'2xl'} color={userData?.customize?.buyerFormHeadingColor || 'coolGray.700'} mb={5}>Thank you for your answers!</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>We will send you a list of homes for sale shortly.</Text>
              </Box>
              <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.buyerFormCardBackgroundColor || 'white'} shadow={'5'}>
                <VStack alignItems={'center'}>
                  <Box h={200} mb={12} w={'100%'} backgroundColor={'black'} borderRadius={5} alignItems={'center'}>
                  <Image opacity={30} source={{uri: userData?.customize?.buyerFormProfileBackground || 'https://img.freepik.com/free-photo/beautiful-tree-middle-field-covered-with-grass-with-tree-line-background_181624-29267.jpg?w=2000&t=st=1683776322~exp=1683776922~hmac=7fa1a8a52268244ed0f5096897d5de98b4172243faf9d104c52b1567f6c9ce11'}} h={'100%'} w={'100%'} alt="Satelite Image" borderRadius={5} resizeMode='cover'/>
                    <Avatar size={'2xl'} m={-20} source={{uri: userData?.public.avatar}} borderColor={'white'} borderWidth={5}/>
                  </Box>
                  <Box alignItems={'center'}>
                    {userData?.public.name && <Heading color={userData?.customize?.buyerFormHeadingColor || 'coolGray.700'}>{userData?.public.name}</Heading>}
                    {userData?.public?.broker  && (
                      <Text fontSize={'md'} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>{userData?.public?.broker}</Text>
                    )}
                    {userData?.public?.license  && (
                      <Text fontSize={'md'} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>{userData?.public?.license}</Text>
                    )}
                    <HStack m={5} space={2} alignItems={'center'}>
                      {userData?.public?.phone && (
                      <Link isExternal href={`tel:${userData?.public?.phone}`}>
                        <Button size={'lg'} colorScheme={'success'}  leftIcon={<Fa.FaPhone size={15} color={'white'}/>}>
                          Call
                        </Button>
                      </Link>
                      )}
                      {userData?.public?.email && (
                      <Link isExternal href={`mailto:${userData?.public?.email}`}>
                        <Button size={'lg'} colorScheme={'info'}  leftIcon={<Fa.FaEnvelope size={15} color={'white'}/>}>
                          Email
                        </Button>
                      </Link> 
                      )}
                    </HStack>
                    {userData?.public?.bio && <Text fontSize={'md'} color={userData?.customize?.buyerFormTextColor || 'coolGray.700'}>{userData?.public?.bio}</Text>}
                    <HStack m={5} space={2} alignItems={'center'}>
                      {userData?.public?.website && (
                        <Link isExternal href={userData?.public?.website} color={userData?.customize?.buyerFormProfileSocialIconsColor || 'coolGray.700'}>
                          <Fa.FaGlobe size={30}/>
                        </Link>
                      )}
                      {userData?.public?.facebook && (
                        <Link isExternal href={userData?.public?.facebook} color={userData?.customize?.buyerFormProfileSocialIconsColor || 'coolGray.700'}>
                          <Fa.FaFacebook size={30}/>
                        </Link>
                      )}
                      {userData?.public?.instagram && (
                        <Link isExternal href={userData?.public?.instagram} color={userData?.customize?.buyerFormProfileSocialIconsColor || 'coolGray.700'}>
                          <Fa.FaInstagram size={30}/>
                        </Link>
                      )}
                      {userData?.public?.twitter && (
                        <Link isExternal href={userData?.public?.twitter} color={userData?.customize?.buyerFormProfileSocialIconsColor || 'coolGray.700'}>
                          <Fa.FaTwitter size={30}/>
                        </Link>
                      )}
                      {userData?.public?.linkedin && (
                        <Link isExternal href={userData?.public?.linkedin} color={userData?.customize?.buyerFormProfileSocialIconsColor || 'coolGray.700'}>
                          <Fa.FaLinkedin size={30}/>
                        </Link> 
                      )}
                      {userData?.public?.youtube && (
                        <Link isExternal href={userData?.public?.youtube} color={userData?.customize?.buyerFormProfileSocialIconsColor || 'coolGray.700'}>
                          <Fa.FaYoutube size={30}/>
                        </Link>
                      )}
                    </HStack>
                    <ReactPlayer url={userData?.public?.featured_video} width={'100%'} height={200} controls={true}  />
                  </Box>
                </VStack>
              </Box>
              </>
            )}
          </Stack>
        );
      default:
        return (
          <></>
        );
    }
  }

  return (
      <NativeBaseProvider>
        <Center 
          backgroundColor={userData?.customize?.buyerFormBackgroundColor || 'blueGray.300'} 
          height={(step === 12)?['100%', windowHeight]:windowHeight}
          alignContent={'center'}
          alignItems={'center'}
          justifyContent={(step === 12)?'flex-start':'center'}
          justifyItems={'center'}
        >
          {step > 1 && <Pressable onPress={handleRestart} position={'absolute'} top={5} left={5} backgroundColor={'white'} p={3} borderRadius={10} shadow={5}>
            <Heading size={'sm'} color={'coolGray.600'}>{`< start`}</Heading>
          </Pressable>
          }
          {renderStep()}
        </Center>
      </NativeBaseProvider>
  );

}

export default BuyerForm;
