import React, { useEffect, useRef, useState, useMemo, useCallback, memo } from 'react'
import { Avatar, Divider, FormControl, Heading, HStack, Input, VStack, TextArea, ScrollView, Spinner, Stack, Text, Skeleton, Center, Box, Icon, Image, StatusBar, Badge, View, NativeBaseProvider, Pressable, IconButton, Toast, Button, Select, Link, Flex, Switch, Checkbox } from 'native-base';
import { addUserSupportChat, auth, db, getSupportSettings, updateSupportStatus, updateUserTextSupport, getUserNavigationHistory, getUserCollections, getFullChatData, sendPushNotification, getAllTokens, getAllUsers, sendBulkPushNotification, getAllSubscriptions, getAllActivity } from '../config/firebase';
import { LoginScreen } from './LoginScreen';

const BroadcastPush = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
  
    // Add event listener to update window dimensions on resize
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    //check if user is logged in with firebase
    const currentUser = auth.currentUser;

    // Set an initializing state whilst Firebase connects
    const [initializing, setInitializing] = useState(true);
    const [user, setUser] = useState(currentUser);
    

    // Handle user state changes
    function onAuthStateChanged(user : any) {
        setUser(user);
        if (initializing) setInitializing(false);
    }

    useEffect(() => {
        const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
        return subscriber; // unsubscribe on unmount
    }, []);

    if (!user) {
        return (
            <NativeBaseProvider>
                <LoginScreen />
            </NativeBaseProvider>
        )
    }
        
    const BroadCastList = () => {

        const [tokens, setTokens] = useState<any[]>([])
        const [users, setUsers] = useState<any[]>([])
        const [targetUsers, setTargetUsers] = useState<any[]>([])

        const initFirestore = async () => {
            let allTokens : any[] = []
            let allUsers: any[] = []
            let allSubscriptions: any[] = []
            let allActivity: any[] = []

            //get all tokens
            await getAllTokens()
            .then((tokens : any) => {
                tokens.forEach((token : any) => {
                    //push the key id at token.pushTokens
                    let key = Object.keys(token.pushTokens)[0]
                    allTokens.push({
                        token: token.pushTokens[key].id,
                        uid: token.uid,
                    })
                })
                
            })
            .catch((error : any) => {
                console.log(error)
            })

            //get all subscriptions
            await getAllSubscriptions()
            .then((subscriptions : any) => {
                subscriptions.forEach((subscription : any) => {
                    allSubscriptions.push(subscription)
                })
            })

            //get all activity
            await getAllActivity()
            .then((activity : any) => {
                activity.forEach((activity : any) => {
                    allActivity.push(activity)
                })
            })


            //get all users
            await getAllUsers()
            .then((users : any) => {
                users.forEach((user : any) => {
                    allUsers.push(user)
                })
            })
            .catch((error : any) => {
                console.log(error)
            })

            console.log(allTokens)
            console.log(allUsers)

            //if the user id is the same as the token uid, then push the token to the users array
            allUsers.forEach((user : any) => {
                allTokens.forEach((token : any) => {
                    if (user.uid === token.uid) {
                        user.pushToken = token.token
                    }
                })
            })

            //if the user uid is the same as the subscription uid, then push the subscription to the users array
            allUsers.forEach((user : any) => {
                let userSubscriptions: any[] = []
                allSubscriptions.forEach((subscription : any) => {
                    
                    if (user.uid === subscription.uid) {
                        userSubscriptions.push(subscription)
                    }
                })
                user.subscriptions = userSubscriptions
            })

            //if the user uid is the same as the activity uid, then push the activity to the users array
            allUsers.forEach((user : any) => {
                let userActivity: any[] = []
                allActivity.forEach((activity : any) => {

                    if (user.uid === activity.uid) {
                        userActivity.push(activity)
                    }
                })
                user.activity = userActivity
            })

            setTokens(allTokens)
            setUsers(allUsers)
        }

        const addUserToTarget = (user : any) => {
            let newTargetUsers = [...targetUsers]
            //check if user is already in targetUsers
            if (newTargetUsers.find((targetUser : any) => targetUser.uid === user.uid)) return
            newTargetUsers.push(user)
            setTargetUsers(newTargetUsers)
        }

        const removeUserFromTarget = (user : any) => {
            let newTargetUsers = [...targetUsers]
            newTargetUsers = newTargetUsers.filter((targetUser : any) => targetUser.uid !== user.uid)
            setTargetUsers(newTargetUsers)
        }
            
        useEffect(() => {
            initFirestore()
        }, [])

        const PushNotificationInput = () => {
            const [message, setMessage] = useState('')

            const sendBulkPush = async () => {
                await sendBulkPushNotification({
                    message: message, 
                    users: targetUsers})
                .then((response) => {
                    Toast.show({
                        title: `${JSON.stringify(response.data)}`,
                        placement: 'top',
                        duration: 3000,
                    })
                })
                .catch((error) => {
                    Toast.show({
                        title: `${JSON.stringify(error)}`,
                        placement: 'top',
                        duration: 3000,
                    })
                })
            }


            return (
                <VStack space={5} alignSelf={'center'}>
                    <TextArea value={message} w={700} onChangeText={(text : any) => setMessage(text)} placeholder="Message" autoCompleteType={'text'}/>
                    <Button onPress={() => sendBulkPush()}>Send Push</Button>
                </VStack>
            )
        }

        return (
            <Stack w={windowWidth} h={windowHeight} p={5}>
                <Heading alignSelf={'center'}>Broadcast Push</Heading>
                <HStack alignSelf={'center'} space={5}>
                    <Heading size={'sm'} >Target: {targetUsers.length}</Heading>
                    <Heading size={'sm'} >All Users: {users.length}</Heading>
                    <Heading size={'sm'} >Tokens: {tokens.length}</Heading>
                </HStack>
                <PushNotificationInput/>
                <HStack space={5}>
                    <ScrollView w={windowWidth/2} h={windowHeight} >
                        <Heading alignSelf={'center'}>All Users</Heading>
                        <ScrollView alignSelf={'center'} mb={5} horizontal>
                            <Button onPress={() => {
                                let newTargetUsers = []
                                users.forEach((user : any) => {
                                    if (user?.pushToken) {
                                        newTargetUsers.push(user)
                                    }
                                })
                                setTargetUsers(newTargetUsers)
                            }}>
                                Select All
                            </Button>
                            <Button
                                onPress={() => {
                                    let newTargetUsers = []
                                    users.forEach((user : any) => {
                                        if (user?.pushToken) {
                                            if (!user?.public?.name || !user?.public?.email) {
                                                newTargetUsers.push(user)
                                            }
                                        }
                                    })
                                    console.log(newTargetUsers)
                                    setTargetUsers(newTargetUsers)
                                }}
                            >
                                Incomplete Profile
                            </Button>
                            {/* Only with subscriptions */}
                            <Button
                                onPress={() => {
                                    let newTargetUsers = []
                                    users.forEach((user : any) => {
                                        if (user?.pushToken) {
                                            if (user?.subscriptions.length > 0) {
                                                newTargetUsers.push(user)
                                            }
                                        }
                                    })
                                    console.log(newTargetUsers)
                                    setTargetUsers(newTargetUsers)
                                }}
                            >
                                With Subscriptions
                            </Button>
                            {/* Only without subscriptions */}
                            <Button
                                onPress={() => {
                                    let newTargetUsers = []
                                    users.forEach((user : any) => {
                                        if (user?.pushToken) {
                                            if (user?.subscriptions.length === 0) {
                                                newTargetUsers.push(user)
                                            }
                                        }
                                    })
                                    console.log(newTargetUsers)
                                    setTargetUsers(newTargetUsers)
                                }}
                            >
                                Without Subscriptions
                            </Button>
                            {/* Only with activity */}
                            <Button
                                onPress={() => {
                                    let newTargetUsers = []
                                    users.forEach((user : any) => {
                                        if (user?.pushToken) {
                                            if (user?.activity.length > 0) {
                                                newTargetUsers.push(user)
                                            }
                                        }
                                    })
                                    console.log(newTargetUsers)
                                    setTargetUsers(newTargetUsers)
                                }}
                            >
                                With Activity
                            </Button>
                            {/* Only without activity */}
                            <Button
                                onPress={() => {
                                    let newTargetUsers = []
                                    users.forEach((user : any) => {
                                        if (user?.pushToken) {
                                            if (user?.activity.length === 0) {
                                                newTargetUsers.push(user)
                                            }
                                        }
                                    })
                                    console.log(newTargetUsers)
                                    setTargetUsers(newTargetUsers)
                                }}
                            >
                                Without Activity
                            </Button>
                            {/* With Activity and No Subscriptions */}
                            <Button
                                onPress={() => {
                                    let newTargetUsers = []
                                    users.forEach((user : any) => {
                                        if (user?.pushToken) {
                                            if (user?.activity.length > 0 && user?.subscriptions.length === 0) {
                                                newTargetUsers.push(user)
                                            }
                                        }
                                    })
                                    console.log(newTargetUsers)
                                    setTargetUsers(newTargetUsers)
                                }}
                            >
                                With Activity and No Subscriptions
                            </Button>

                        </ScrollView>
                        {
                            users.map((user : any) => {
                                if (!user.pushToken) return

                                return (
                                    <Pressable key={`user_${user.uid}`} onPress={() => addUserToTarget(user)} _hover={{opacity: 0.8}}>
                                        <VStack bgColor={'info.200'} mb={5}>
                                            <Text>{user?.public?.name}</Text>
                                            <Text>{user?.public?.email}</Text>
                                            <Text>{user.uid}</Text>
                                            <Text>{user.pushToken}</Text>
                                            <Text>Subs: {user.subscriptions.length}</Text>
                                        </VStack>
                                    </Pressable>
                                )
                            })
                        }
                    </ScrollView>
                    <ScrollView w={windowWidth/2} h={windowHeight} >
                        <Heading alignSelf={'center'}>Target Users</Heading>
                        <HStack space={5} alignSelf={'center'} mb={5}>
                            <Button onPress={() => setTargetUsers([])}>Clear</Button>
                        </HStack>
                        {
                            targetUsers.map((user : any) => {
                                return (
                                    <Pressable key={`target_${user.uid}`} onPress={() => removeUserFromTarget(user)} _hover={{opacity: 0.8}}>
                                        <VStack bgColor={'success.200'} mb={5}>
                                            <Text>{user?.public?.name}</Text>
                                            <Text>{user?.public?.email}</Text>
                                            <Text>{user.uid}</Text>
                                            <Text>{user.pushToken}</Text>
                                            <Text>Subs: {user.subscriptions.length}</Text>
                                        </VStack>
                                    </Pressable>
                                )
                            })
                        }
                    </ScrollView>
                </HStack>
            </Stack>
        )
    }

        
    return (
        <NativeBaseProvider>
            <BroadCastList />
        </NativeBaseProvider>
    )
}

export default BroadcastPush