import React, { useEffect, useState } from 'react';
import {usePlacesWidget} from "react-google-autocomplete";
import { Alert, AlertDialog, Avatar, Box, Button, Center, Checkbox, Container, Flex, HStack, Heading, Image, Input, InputGroup, InputLeftAddon, Link, NativeBaseProvider, Pressable, ScrollView, Spinner, Stack, Text, Toast, VStack } from 'native-base';
import * as Fa from "react-icons/fa";
import { auth, completeLead, createNoUserLead, createPartialLead, deleteNoUserLead, deletePartialLead, getUserData, updateNoUserLead, updatePartialLead } from '../config/firebase';
import { formatPhoneNumber } from '../functions/formatPhoneNumber';
import { getPropertyData } from '../api/certiapi';
import { Estated } from '../interfaces/propertyData';
import { serverTimestamp } from 'firebase/firestore';
import ReactPlayer from 'react-player/lazy';
import { useParams } from 'react-router-dom';


//Create a multistep form with the following steps:
//1. User enters an address and unit no, use the autocomplete from google maps
//2. Fetch property data from backend and display a satelite image of the property, the address and the number of bedrooms and bathrooms for the user to confirm
//3. User selects the condition of the home from multiple buttons
//4. User selects how soon they want to sell the home from multiple buttons
//5. User enters their phone number
//6. User enters their name and email, and clicks Generate Report

//When the user clicks Generate Report, the following should happen:
//1. The user's information should be sent to the backend
//2. The backend should generate a report with the user's information and the property data
//3. The report should be sent to the user's email address
//4. The user should be redirected to a page that says "Thank you for your submission, you will receive an email shortly"

const HomeValuation = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  // Add event listener to update window dimensions on resize
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [address, setAddress] = useState("");
  const [fullAddress, setFullAddress] = useState<string | null>(null);
  const [unitNo, setUnitNo] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [disableSubmit, setDisableSubmit] = useState(true);

  const [leadId, setLeadId] = useState<any>(null);
  const [propertyData, setPropertyData] = useState<Estated>();
  const [step, setStep] = useState(1);

  const apiKey = "AIzaSyDAdP2bX_HvU4yI4iFBy9oEQNQiyxURakU";

  //get uid from url params
  const urlParams = new URLSearchParams(window.location.search);
  const uid = urlParams.get('uid') || undefined;
  const subId = urlParams.get('subId');
  const username = useParams<{username: string}>().username;


  //get user data from firebase using uid
  const [userData, setUserData] = useState<any>(null);

  const retrieveUserData = async () => {
    if (uid || username){
      const userData = await getUserData(uid, username);
      console.log(userData)
      setUserData(userData);
    }
  }

  useEffect(() => {
    retrieveUserData()
  }, [])

  const handleNext = () => {
    setStep(step + 1);
  }

  const handleBack = () => {
    setStep(step - 1);
  }

  const handleRestart = () => {
    if (uid && leadId){
      deletePartialLead(uid, leadId);
    } else {
      deleteNoUserLead(leadId);
    }
    window.location.reload();
  }

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey:apiKey,
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    },
    onPlaceSelected: (place) => {
      console.log(place)

      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {            
            case "postal_code": 
                setZipCode(component.long_name)
                break;

            case "locality":
                setCity(component.long_name)
                break;
            case "administrative_area_level_1": 
                setState(component.short_name)
                break;
        }
      }
      //Extract street address, city, state and zip code from place object
      let fulladdress = `${place.address_components[0].short_name} ${place.address_components[1].short_name}`;

      const street_types = {
        Street: 'St',
        Avenue: 'Ave',
        Boulevard: 'Blvd',
        Road: 'Rd',
        Drive: 'Dr',
        Court: 'Ct',
        Lane: 'Ln',
        Parkway: 'Pkwy',
        Place: 'Pl',
        Terrace: 'Ter',
      };
      
      Object.entries(street_types).forEach(([full_type, abbrev]) => {
        fulladdress = fulladdress.replace(full_type, abbrev);
      });

      setAddress(place.formatted_address)
      setFullAddress(fulladdress);
    }
  });

  const handleSearchAddress = async () => {
    console.log(fullAddress, zipCode, unitNo)
    if (fullAddress){
      const response = await getPropertyData(fullAddress, zipCode, unitNo)
      console.log(response)

      setPropertyData(response);
      handleNext();

      if (uid){
        const docId = await createPartialLead(uid, {
          propertyData: response,
          created_at: serverTimestamp(),
          original_uid: uid,
          push_status: 'pending',
          status: "not_contacted",
          subscription: subId,
          type: "seller_lead",
          visibility: "new"
        })
        setLeadId(docId);
        console.log(docId)
      } else {
        const docId = await createNoUserLead({
          propertyData: response,
          created_at: serverTimestamp(),
          push_status: 'pending',
          status: "not_contacted",
          type: "seller_lead",
          visibility: "new",
          assignedToUsers: []
        })
        setLeadId(docId);
        console.log(docId)
      }
    } else {
      Toast.show({
        title: "Please enter an address",
        placement: "top",
        duration: 3000,
      })
    }
  }
  
  

  const renderStep = () => {
    const sateliteImage = `https://maps.googleapis.com/maps/api/staticmap?center=${address}, ${city} ${state} ${zipCode}&zoom=21&maptype=hybrid&size=1200x1200&key=${apiKey}`;
    switch(step) {
      case 1:
        return (
          <>
          <Stack p={5} maxWidth={1400}>
            <Box p="5" rounded="xl" mb={[20, 50]} mt={[20, 50]} backgroundColor={userData?.customize?.homeValueCardBackgroundColor ||'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'} >
              <Heading mb={10} color={userData?.customize?.homeValueHeadingColor ||'coolGray.500'} textAlign={'center'}>What's your home worth?</Heading>
              <Text fontSize={'lg'} mb={2} color={userData?.customize?.homeValueTextColor || 'coolGray.500'} textAlign={'center'}>The real estate market in your city is rapidly changing.</Text>
              <Text fontSize={'md'} mb={4} color={userData?.customize?.homeValueTextColor || 'coolGray.500'} textAlign={'center'}>Enter your address to get started</Text>
              <Flex flexDirection={['column', 'row']} alignItems={['center', 'stretch']} justifyContent="space-between">
                <Input ref={ref} mb={5} w={["90%", "50%"]} autoComplete={'off'} placeholder="Search your address" size="2xl" backgroundColor={'white'}
                  InputLeftElement={<Fa.FaMapMarkerAlt size={20} color='grey' style={{marginLeft: 8}}/>}
                />
                <Input mb={5} w={["90%", "20%"]} placeholder="Unit (Optional)" size="2xl" 
                onChangeText={(text)=>{
                  setUnitNo(text)
                }} backgroundColor={'white'}
                  InputLeftElement={<Fa.FaBuilding size={20} color='grey' style={{marginLeft: 8}}/> }
                />
                <Button mb={5} bgColor={userData?.customize?.homeValueMainButtonColor || 'success.600'} size={'lg'} w={["90%", "20%"]} onPress={handleSearchAddress}>
                  <Text color={userData?.customize?.homeValueMainButtonTextColor || 'white'} bold fontSize={'2xl'}>Search</Text>
                </Button>
              </Flex>
            </Box>
            <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.homeValueCardBackgroundColor ||'white'} shadow={'5'} flexDirection={'column'} alignSelf={'center'}>
              <Heading mb={10} color={userData?.customize?.homeValueHeadingColor || 'coolGray.500'} textAlign={'center'}>How is your property value calculated?</Heading>
              <Flex flexDirection={['column', 'row']} alignItems={['center', 'stretch']} justifyContent="space-between">
                <VStack alignItems={'center'} w={["90%", "30%"]} p={5} borderRadius={10}>
                  <Image size={'xl'} mb={5} borderRadius={100}  source={require('../assets/taxes.jpg')} alt="Alternate Text" />
                  <Heading size={'md'} textAlign={'center'} color={userData?.customize?.homeValueHeadingColor || 'coolGray.500'} mb={5}>Searching for Tax Information</Heading>
                  <VStack alignItems={'center'} w={'full'} backgroundColor={userData?.customize?.homeValueInformationCardBackgroundColor||'amber.200'} p={5} borderRadius={10}>
                    <Text fontSize={'md'} textAlign={'center'} color={userData?.customize?.homeValueTextColor || 'coolGray.500'}>We will check the county public tax records of the subject property. We gather valuable data like:</Text>
                    <Text fontSize={'md'} textAlign={'center'} color={userData?.customize?.homeValueTextColor || 'coolGray.500'}>Millage rate. This is important because the higher the millage rate is, the higher the owner’s tax rate will be. This value tends to affect home price conversely; i.e., the higher a tax rate, the lower the value of a home</Text>
                    <Text fontSize={'md'} textAlign={'center'} color={userData?.customize?.homeValueTextColor || 'coolGray.500'}>Property’s assessed value: We evaluate your current assesed value with our estimates.</Text>
                  </VStack>
                </VStack>
                <VStack alignItems={'center'} w={["90%", "30%"]}  p={5} borderRadius={10}>
                  <Image size={'xl'} mb={5} borderRadius={100}  source={require('../assets/comparables.jpg')} alt="Alternate Text" />
                  <Heading size={'md'} textAlign={'center'} color={userData?.customize?.homeValueHeadingColor || 'coolGray.500'} mb={5}>Using Comparables</Heading>
                  <VStack alignItems={'center'} w={'full'} backgroundColor={userData?.customize?.homeValueInformationCardBackgroundColor||'amber.200'} p={5} borderRadius={10}>
                    <Text fontSize={'md'} textAlign={'center'} color={userData?.customize?.homeValueTextColor || 'coolGray.500'}>Using data from the local MLS, we find four or five sold properties that match or are close to your subject property, going back six months to one year depending on market trends.</Text>
                    <Text fontSize={'md'} textAlign={'center'} color={userData?.customize?.homeValueTextColor || 'coolGray.500'}>If you live in a major metropolitan area and your subject property is pretty typical of the offerings in your city, we’ll have a great chance of finding some very close comparables on exactly your subject property criteria.</Text>
                  </VStack>
                </VStack>
                <VStack alignItems={'center'} w={["90%", "30%"]} p={5} borderRadius={10}>
                  <Image size={'xl'} mb={5} borderRadius={100}  source={require('../assets/trends.jpg')} alt="Alternate Text" />
                  <Heading size={'md'} textAlign={'center'} color={userData?.customize?.homeValueHeadingColor || 'coolGray.500'} mb={5}>Evaluating Market Trends</Heading>
                  <VStack alignItems={'center'} w={'full'} backgroundColor={userData?.customize?.homeValueInformationCardBackgroundColor||'amber.200'} p={5} borderRadius={10}>
                    <Text fontSize={'md'} textAlign={'center'} color={userData?.customize?.homeValueTextColor || 'coolGray.500'}>We research micro trends around your area of residence for anything that could affect the value of your property. For example, we gather traffic data to determine if there’s major road construction down the block from your property. Despite the overall trend of the market improving in your neighborhood, this micro market trend is going to drive the final number on your comparative market analysis down.</Text>
                  </VStack>
                </VStack>
              </Flex>
            </Box>
            {userData?.public?.name && (
              <VStack p={5} space={1} mb={10} backgroundColor={userData?.customize?.homeValueCardBackgroundColor ||'white'} borderRadius={20} shadow={5} alignSelf={'center'}>
                <HStack space={3} alignItems={'center'} >
                  <VStack>
                    {userData?.public?.name && <Heading color={userData?.customize?.homeValueHeadingColor || 'coolGray.700'} fontSize={'lg'}>Home Valuation by {userData?.public?.name}</Heading>}
                    {userData?.public?.phone && (
                      <Link isExternal href={`tel:${userData?.public?.phone}`} >
                        <Text fontSize={'lg'} color={userData?.customize?.homeValueTextColor || 'blue.400'} >
                          <Fa.FaPhone/> {formatPhoneNumber(userData?.public?.phone)}
                        </Text>
                      </Link>
                      )}
                    {userData?.public?.email && (
                      <Link isExternal href={`mailto:${userData?.public?.email}`} >
                        <Text fontSize={'lg'} color={userData?.customize?.homeValueTextColor || 'blue.400'} >
                          <Fa.FaMailBulk/> {userData?.public?.email}
                        </Text>
                      </Link>
                      )}
                  </VStack>
                  {userData?.public?.avatar && <Avatar size={'lg'} source={{uri: userData?.public?.avatar}}/>}
                </HStack>
              </VStack>
            )}
          </Stack>
          </>
        );
      case 2:   
        return (
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.homeValueCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={["90%","45%"]} m={5}>
                <Image source={{uri: sateliteImage}} size={'2xl'} alt="Satelite Image" borderRadius={10} />
              </Stack>
              <Stack w={["90%","45%"]} m={5}>
                <Heading color={userData?.customize?.homeValueHeadingColor || 'coolGray.700'}>Is this the property?</Heading>
                <Text fontSize={'lg'} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>Confirm your address</Text>
                <Text fontSize={'xl'} bold color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{`${fullAddress}, ${city} ${state} ${zipCode}`}</Text>
                <HStack space={3}>
                  {propertyData?.data?.structure?.beds_count > 0 && (
                    <HStack alignItems={'center'} space={1}>
                      <Fa.FaBed size={20} color={userData?.customize?.homeValueTextColor || 'grey'}/>
                      <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'grey'}>{propertyData?.data?.structure?.beds_count}</Text>
                    </HStack>)}
                  {propertyData?.data?.structure?.baths > 0 && (
                    <HStack alignItems={'center'} space={1}>
                      <Fa.FaBath size={15} color={userData?.customize?.homeValueTextColor || 'grey'}/>
                      <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'grey'}>{propertyData?.data?.structure?.baths}</Text>
                    </HStack>)}
                  {propertyData?.data?.parcel?.area_sq_ft > 0 && (
                    <HStack alignItems={'center'} space={1}>
                      <Fa.FaRuler size={15} color={userData?.customize?.homeValueTextColor || 'grey'}/>
                      <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'grey'}>{propertyData?.data?.parcel?.area_sq_ft} sqft.</Text>
                    </HStack>)}
                </HStack>
                <VStack mb={5}>
                  {propertyData?.data?.address?.geocoding_accuracy && (
                    <HStack alignItems={'center'} space={1}>
                      <Fa.FaBuilding size={20} color={userData?.customize?.homeValueTextColor || 'grey'}/>
                      <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'grey'}>{propertyData?.data?.address?.geocoding_accuracy}</Text>
                    </HStack>)} 
                  {propertyData?.data?.structure?.year_built > 0 && (
                    <HStack alignItems={'center'} space={1}>
                      <Fa.FaCalendar size={20} color={userData?.customize?.homeValueTextColor || 'grey'}/>
                      <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'grey'}>Built on {propertyData?.data?.structure?.year_built}</Text>
                    </HStack>)}
                </VStack>
                <HStack space={2}>
                  <Button colorScheme={'danger'} width={'1/3'} onPress={handleRestart}>
                    <Text color={'white'} bold fontSize={'2xl'}>No</Text>
                  </Button>
                  <Button colorScheme={'success'} width={'1/3'} onPress={handleNext}>
                    <Text color={'white'} bold fontSize={'2xl'}>Yes</Text>
                  </Button>
                </HStack>
              </Stack>
            </Flex>
          </Box>
        );
      case 3: // If the user is not the owner
        userData?.customize?.homeValueDisabledSteps?.includes(3) && handleNext()
        return (
          // Ask if its the current owner
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.homeValueCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.homeValueHeadingColor || 'coolGray.700'}>Are you the current owner?</Heading>
                <Text fontSize={'lg'} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>Advanced property details are only available for property owners.</Text>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>Select "Yes" if you are inquiring on behalf of the owner.</Text>
                <HStack space={2}>
                  <Button colorScheme={'danger'} w={'1/2'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {owner: false})
                      } else {
                        await updateNoUserLead(leadId, {owner: false})
                      }
                    }} 
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>No</Text>
                  </Button>
                  <Button colorScheme={'success'} w={'1/2'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {owner: true})
                      } else{
                        await updateNoUserLead(leadId, {owner: true})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>Yes</Text>
                  </Button>
                </HStack>
              </Stack>
            </Flex>
          </Box>
        );
      case 4: //Property Condition
        userData?.customize?.homeValueDisabledSteps?.includes(4) && handleNext()
        return (
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.homeValueCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.homeValueHeadingColor || 'coolGray.700'}>What is your home's condition?</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>Choose the condition that best describes your home</Text>
                <VStack space={2}>
                  <Button colorScheme={'danger'} w={'full'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {condition: 'Poor'})
                      } else {
                        await updateNoUserLead(leadId, {condition: 'Poor'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>Poor</Text>
                  </Button>
                  <Button colorScheme={'warning'} w={'full'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {condition: 'Fair'})
                      } else {
                        await updateNoUserLead(leadId, {condition: 'Fair'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>Fair</Text>
                  </Button>
                  <Button colorScheme={'info'} w={'full'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {condition: 'Good'})
                      } else {
                        await updateNoUserLead(leadId, {condition: 'Good'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>Good</Text>
                  </Button>
                  <Button colorScheme={'success'} w={'full'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {condition: 'Excellent'})
                      } else {
                        await updateNoUserLead(leadId, {condition: 'Excellent'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>Excellent</Text>
                  </Button>
                </VStack>
              </Stack>
            </Flex>
          </Box>
        );
      case 5: //Timeframe
        userData?.customize?.homeValueDisabledSteps?.includes(5) && handleNext()
        return (
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.homeValueCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.homeValueHeadingColor || 'coolGray.700'}>What is your timeframe?</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{`Please kindly provide a preferred timeframe within which you would like to sell your property as part of the home valuation process.`}</Text>
                <VStack space={2}>
                  <Button colorScheme={'danger'} w={'full'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {timeframe: '6+ Months'})
                      } else {
                        await updateNoUserLead(leadId, {timeframe: '6+ Months'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>6+ Months</Text>
                  </Button>
                  <Button colorScheme={'warning'} w={'full'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {timeframe: '3-6 Months'})
                      } else {
                        await updateNoUserLead(leadId, {timeframe: '3-6 Months'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>3-6 Months</Text>
                  </Button>
                  <Button colorScheme={'info'} w={'full'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {timeframe: '1-3 Months'})
                      } else {
                        await updateNoUserLead(leadId, {timeframe: '1-3 Months'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>1-3 Months</Text>
                  </Button>
                  <Button colorScheme={'success'} w={'full'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {timeframe: 'ASAP'})
                      } else {
                        await updateNoUserLead(leadId, {timeframe: 'ASAP'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>ASAP</Text>
                  </Button>
                </VStack>
              </Stack>
            </Flex>
          </Box>
        );
      case 6: //Renovations
        userData?.customize?.homeValueDisabledSteps?.includes(6) && handleNext()
        return (
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.homeValueCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.homeValueHeadingColor || 'coolGray.700'}>Have you done any recent renovations?</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{`Renovations add value to a home by improving its functionality, aesthetics, and overall condition, thereby increasing its desirability and potential resale price.`}</Text>
                <VStack space={2}>
                  <Button colorScheme={'warning'} w={'full'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {renovations: 'No Renovations'})
                      } else {
                        await updateNoUserLead(leadId, {renovations: 'No Renovations'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>No Renovations</Text>
                  </Button>
                  <Button colorScheme={'info'} w={'full'}
                    onPress={async ()=>{  
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {renovations: 'Minor Renovations'})
                      } else {
                        await updateNoUserLead(leadId, {renovations: 'Minor Renovations'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>Minor Renovations</Text>
                  </Button>
                  <Button colorScheme={'success'} w={'full'}
                    onPress={async ()=>{
                      handleNext()
                      if (uid){
                        await updatePartialLead(uid, leadId, {renovations: 'Major Renovations'})
                      } else {
                        await updateNoUserLead(leadId, {renovations: 'Major Renovations'})
                      }
                    }}
                  >
                    <Text color={'white'} bold fontSize={'2xl'}>Major Renovations</Text>
                  </Button>
                </VStack>
              </Stack>
            </Flex>
          </Box>
        );
      case 7: //Phone Number
        return (
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.homeValueCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.homeValueHeadingColor || 'coolGray.700'}>What is your phone number?</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{`Please kindly provide a phone number to send the report to.`}</Text>
                <VStack space={2}>
                  <Input type='text' key={'phone'} inputMode='tel' placeholder="Phone Number" mb={5} size={'2xl'} backgroundColor={'white'} defaultValue=''
                    onChangeText={async(text) => {
                      //Validate phone number format
                      if (typeof parseInt(text) === 'number' && text.length >= 10 || userData?.customize?.homeValueVerifyPhone == false) {
                        if (uid){
                          await updatePartialLead(uid, leadId, {phone: text})
                        } else {
                          await updateNoUserLead(leadId, {phone: text})
                        }
                        setDisableSubmit(false)
                      } else {
                        setDisableSubmit(true)
                      }
                    }}
                  />
                  <Checkbox colorScheme={'success'} mb={5}  value={''} w={'full'} defaultIsChecked>
                    <Text color={userData?.customize?.homeValueTextColor || 'coolGray.700'} fontSize={'lg'}>I would like to receive a weekly update</Text>
                  </Checkbox>
                  <Button bgColor={userData?.customize?.homeValueMainButtonColor || 'success.600'} onPress={()=>{
                    handleNext()
                    setDisableSubmit(true)
                  }} w={'full'} isDisabled={(userData?.customize?.homeValueVerifyPhone == false) ? false : disableSubmit}>
                    <Text color={userData?.customize?.homeValueMainButtonTextColor || 'white'} bold fontSize={'2xl'}>Get Report</Text>
                  </Button>
                </VStack>
              </Stack>
            </Flex>
          </Box>
        );
      case 8: // Ask for name and email
        return (
          <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.homeValueCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
            <Flex flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
              <Stack w={"90%"} m={5}>
                <Heading color={userData?.customize?.homeValueHeadingColor || 'coolGray.700'}>What is your name and email?</Heading>
                <Text fontSize={'lg'} mb={5} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{`Please kindly provide a name and email to send the report to.`}</Text>
                <VStack space={2}>
                  <Input type='text' key={'name'} autoComplete='name' placeholder="Name" mb={5}size={'2xl'} backgroundColor={'white'} defaultValue=''
                    onChangeText={async(text) => {
                      if (uid){
                        await updatePartialLead(uid, leadId, {name: text})
                      } else {
                        await updateNoUserLead(leadId, {name: text})
                      }
                    }}
                  />
                  <Input type='text' key={'email'} autoComplete='email' autoCapitalize='none' placeholder="Email" mb={5} size={'2xl'} backgroundColor={'white'} defaultValue=''
                    onChangeText={async(text) => {
                      //validate email format
                      const regex = (userData?.customize?.homeValueVerifyEmail == false) ? /^.+$/ : /\S+@\S+\.\S+/ ;
                      if (regex.test(text)) {
                        if (uid){
                          await updatePartialLead(uid, leadId, {email: text})
                        } else {
                          await updateNoUserLead(leadId, {email: text})
                        }
                        setDisableSubmit(false)
                      } else {
                        setDisableSubmit(true)
                      }
                    }}
                  />
                  <Button bgColor={userData?.customize?.homeValueMainButtonColor || 'success.600'}  w={'full'} isDisabled={(userData?.customize?.homeValueVerifyEmail == false) ? false : disableSubmit}
                    onPress={()=>{
                      handleNext()
                      if (uid){
                        completeLead(uid, leadId)
                      }
                    }}
                  >
                    <Text color={userData?.customize?.homeValueMainButtonTextColor || 'white'} bold fontSize={'2xl'}>Get Report</Text>
                  </Button>
                </VStack>
              </Stack>
            </Flex>
          </Box>
        );
      case 9: // Show report
        (window as any).dataLayer.push({
          event: 'reportView'
        });
        console.log('reportView', (window as any).dataLayer)
        return (
          <Stack>
            
            <Box p="5"  rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.homeValueCardBackgroundColor || 'white'} shadow={'5'} flexDirection={'column'} alignItems={'center'}>
              <Flex width={['100%', 900]} maxW={'900'} flexDirection={['column', 'row']} alignItems={['center', 'center']} justifyContent="space-between">
                <Stack w={["90%","45%"]} m={5}> 
                  <Image source={{uri: sateliteImage}} h={500} w={'100%'} alt="Satelite Image" borderRadius={10} />
                </Stack>
                <Stack w={["90%","45%"]} m={5}>
                  <div className='leadGenerated'>
                  <Heading color={userData?.customize?.homeValueHeadingColor || 'coolGray.700'}>Quick Report</Heading>
                  </div>
                  <Text fontSize={'xl'} bold color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{`${fullAddress}, ${city} ${state} ${zipCode}`}</Text>
                  <HStack space={3}>
                    {propertyData?.data?.structure?.beds_count > 0 && (
                      <HStack alignItems={'center'} space={1}>
                        <Fa.FaBed size={20} color={userData?.customize?.homeValueTextColor || 'grey'}/>
                        <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'grey'}>{propertyData?.data?.structure?.beds_count}</Text>
                      </HStack>)}
                    {propertyData?.data?.structure?.baths > 0 && (
                      <HStack alignItems={'center'} space={1}>
                        <Fa.FaBath size={15} color={userData?.customize?.homeValueTextColor || 'grey'}/>
                        <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'grey'}>{propertyData?.data?.structure?.baths}</Text>
                      </HStack>)}
                    {propertyData?.data?.parcel?.area_sq_ft > 0 && (
                      <HStack alignItems={'center'} space={1}>
                        <Fa.FaRuler size={15} color={userData?.customize?.homeValueTextColor || 'grey'}/>
                        <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'grey'}>{propertyData?.data?.parcel?.area_sq_ft} sqft.</Text>
                      </HStack>)}
                  </HStack>
                  <VStack mb={5}>
                    {propertyData?.data?.address?.geocoding_accuracy && (
                      <HStack alignItems={'center'} space={1}>
                        <Fa.FaBuilding size={20} color={userData?.customize?.homeValueTextColor || 'grey'}/>
                        <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'grey'}>{propertyData?.data?.address?.geocoding_accuracy}</Text>
                      </HStack>)} 
                    {propertyData?.data?.structure?.year_built > 0 && (
                      <HStack alignItems={'center'} space={1}>
                        <Fa.FaCalendar size={20} color={userData?.customize?.homeValueTextColor || 'grey'}/>
                        <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'grey'}>Built on {propertyData?.data?.structure?.year_built}</Text>
                      </HStack>)}
                  </VStack>

                  <VStack mb={5} space={2} alignItems={'flex-start'}>
                    {/* Format value into currency like $300,500 */}
                    {propertyData?.data?.valuation?.low > 10000 && (
                    <Box backgroundColor={'amber.200'} p={2} borderRadius={10}>
                      <Text fontSize={'lg'} bold color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>
                        Low: {`${propertyData?.data?.valuation?.low.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}`}
                      </Text>
                    </Box>)}
                    {propertyData?.data?.valuation?.value > 10000 && (
                    <Box backgroundColor={'success.200'} p={2} borderRadius={10}>
                      <Heading color={userData?.customize?.homeValueHeadingColor || 'coolGray.700'} fontSize={'2xl'} bold>
                        Value: {`${propertyData?.data?.valuation?.value.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}`}
                      </Heading>
                    </Box>)}
                    {propertyData?.data?.valuation?.high > 10000 && (
                    <Box backgroundColor={'amber.200'} p={2} borderRadius={10}>
                      <Text  fontSize={'lg'} bold color={userData?.customize?.homeValueTextColor || 'coolGray.700'} >
                        High: {`${propertyData?.data?.valuation?.high.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}`}
                      </Text>
                    </Box>)}
                    {!propertyData?.data?.valuation?.value && (
                      <Box w={'100%'}>
                        <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>We apologize for the inconvenience, but unfortunately, we were unable to gather sufficient data to generate an automated valuation for the property in question. Automated valuations rely on a comprehensive dataset and various factors to provide an accurate assessment. In this case, due to limited or unavailable information, we are unable to generate a reliable estimate. We highly recommend contacting the real estate agent associated with the property for a more precise valuation. The agent will have access to additional details and local market expertise, ensuring you receive the most accurate and up-to-date information.</Text>
                      </Box>
                    )}
                  </VStack>
                </Stack>
              </Flex>
            </Box>
            {userData && (
              <Box p="5" rounded="xl" mb={2} mt={2} backgroundColor={userData?.customize?.homeValueCardBackgroundColor || 'white'} shadow={'5'}>
                <VStack alignItems={'center'}>
                  <Box h={200} mb={12} w={'100%'} backgroundColor={'black'} borderRadius={5} alignItems={'center'}>
                    <Image opacity={30} source={{uri: userData?.customize?.homeValueProfileBackground || sateliteImage}} h={'100%'} w={'100%'} alt="Satelite Image" borderRadius={5} resizeMode='cover'/>
                    <Avatar size={'2xl'} m={-20} source={{uri: userData?.public?.avatar}} borderColor={'white'} borderWidth={5}/>
                  </Box>
                  <Box alignItems={'center'}>
                    {userData?.public?.name && <Heading color={userData?.customize?.homeValueHeadingColor || 'coolGray.700'}>{userData?.public.name}</Heading>}
                    {userData?.public?.broker  && (
                      <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{userData?.public?.broker}</Text>
                    )}
                    {userData?.public?.license  && (
                      <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{userData?.public?.license}</Text>
                    )}
                    <HStack m={5} space={2} alignItems={'center'}>
                      {userData?.public?.phone && (
                      <Link isExternal href={`tel:${userData?.public?.phone}`}>
                        <Button size={'lg'} colorScheme={'success'}  leftIcon={<Fa.FaPhone size={15} color={'white'}/>}>
                          Call
                        </Button>
                      </Link>
                      )}
                      {userData?.public?.email && (
                      <Link isExternal href={`mailto:${userData?.public?.email}`}>
                        <Button size={'lg'} colorScheme={'info'}  leftIcon={<Fa.FaEnvelope size={15} color={'white'}/>}>
                          Email
                        </Button>
                      </Link> 
                      )}
                    </HStack>
                    {userData?.public?.bio && <Text fontSize={'md'} color={userData?.customize?.homeValueTextColor || 'coolGray.700'}>{userData?.public?.bio}</Text>}
                    <HStack m={5} space={2} alignItems={'center'}>
                      {userData?.public?.website && (
                        <Link isExternal href={userData?.public?.website} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                          <Fa.FaGlobe size={30}/>
                        </Link>
                      )}
                      {userData?.public?.facebook && (
                        <Link isExternal href={userData?.public?.facebook} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                          <Fa.FaFacebook size={30}/>
                        </Link>
                      )}
                      {userData?.public?.instagram && (
                        <Link isExternal href={userData?.public?.instagram} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                          <Fa.FaInstagram size={30}/>
                        </Link>
                      )}
                      {userData?.public?.twitter && (
                        <Link isExternal href={userData?.public?.twitter} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                          <Fa.FaTwitter size={30}/>
                        </Link>
                      )}
                      {userData?.public?.linkedin && (
                        <Link isExternal href={userData?.public?.linkedin} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                          <Fa.FaLinkedin size={30}/>
                        </Link> 
                      )}
                      {userData?.public?.youtube && (
                        <Link isExternal href={userData?.public?.youtube} color={userData?.customize?.homeValueProfileSocialIconsColor || 'coolGray.700'}>
                          <Fa.FaYoutube size={30}/>
                        </Link>
                      )}
                    </HStack>
                    <ReactPlayer url={userData?.public?.featured_video} width={'100%'} height={200} controls={true}  />
                  </Box>
                </VStack>
              </Box>
            )}
          </Stack>
        );
      default:
        return (
          <></>
        );
    }
  }

  return (
      <NativeBaseProvider>
        <Center 
          backgroundColor={userData?.customize?.homeValueBackgroundColor || 'blueGray.300'} 
          height={(step === 1 || step === 9)?['100%', windowHeight]:windowHeight}
          alignContent={'center'}
          alignItems={'center'}
          justifyContent={(step === 1 || step === 9)?'flex-start':'center'}
          justifyItems={'center'}
        >
          {step > 2 && <Pressable onPress={handleRestart} position={'absolute'} top={5} left={5} backgroundColor={'white'} p={3} borderRadius={10} shadow={5}>
            <Heading size={'sm'} color={'coolGray.600'}>{`< start`}</Heading>
          </Pressable>
          }
          {renderStep()}
        </Center>
      </NativeBaseProvider>
  );

}

export default HomeValuation;
