import React, { useEffect, useRef, useState, useMemo, useCallback, memo } from 'react'
import { Avatar, Divider, FormControl, Heading, HStack, Input, VStack, TextArea, ScrollView, Spinner, Stack, Text, Skeleton, Center, Box, Icon, Image, StatusBar, Badge, View, NativeBaseProvider, Pressable, IconButton, Toast, Button, Select, Link, Flex } from 'native-base';
import { getRedirectSettings, setRedirectSettings } from '../config/firebase';
import * as Fa from 'react-icons/fa';

function FbRedirect() {
    
    const [redirectSettings, setRedirectLocalSettings] = useState<any>([])

    const handleRedirectList = async () => {
        await getRedirectSettings().then((res) => {
            setRedirectLocalSettings(res)
        })
    }

    const handlePaste = async () => {
        await navigator.clipboard.readText().then(async(res) => {
            let url = res
            //if res is a valid url
            if (url.includes('https')) {
                //if res contains https://www.facebook.comhttps replace it with https
                if (url.includes('https://www.facebook.comhttps')) {
                    url = url.replace('https://www.facebook.comhttps', 'https')
                }

                if (url.includes('www.facebook.com')){
                    url = url.replace('www', 'm');
                }

                //set the redirect url
                console.log(url)
                await setRedirectSettings(url)
                setRedirectLocalSettings({ history: [...redirectSettings.history, url], groups: redirectSettings.groups })
            }
        })
    }

    useEffect(() => {
        handleRedirectList()
    }, [])

    
    return (
        <NativeBaseProvider>
            <Center m={5}>
                {
                    redirectSettings?.history && (
                        <VStack space={4} alignItems="center" maxW={500}>
                            <HStack space={2} alignItems="center">
                                <IconButton
                                    onPress={() => handlePaste()}
                                    icon={<Fa.FaPaste size={20} color='white'  />}
                                    variant="solid"
                                    colorScheme="coolGray"
                                />
                                {/* Button to Facebook */}
                                <IconButton
                                    onPress={() => window.location.replace('https://m.facebook.com')}
                                    icon={<Fa.FaFacebook size={20} color='white'  />}
                                    variant="solid"
                                    colorScheme="coolGray"
                                />
                            </HStack>
                            
                            <HStack space={2} alignItems="center" justifyContent={'center'} flexWrap={'wrap'}>
                                {redirectSettings?.groups && (
                                    <>
                                        {redirectSettings?.groups.map((item: any, index: number) => {
                                            let url = item.url

                                            if (url.includes('www.facebook.com')) {
                                                url = url.replace('www', 'm')
                                            }

                                            return (
                                                <HStack key={`item_${index}`} space={2} alignItems="center" mb={3}>
                                                    <Link href={url} key={`item_${index}`} alignItems={'center'}>
                                                        <Heading size={'md'} color={'blue.700'} ellipsizeMode={'clip'}>{item.name}</Heading>
                                                    </Link>
                                                    <Divider orientation='vertical' h={5}/>
                                                </HStack>
                                            )
                                        })}
                                    </>
                                )}
                            </HStack>
                            <Heading size="lg">Redirect List</Heading>
                            <Divider width="100%" />
                            <VStack space={2} alignItems="center" reversed maxW={500}>
                                {
                                    redirectSettings.history.map((item: any, index: number) => {
                                        let url = item
                                        let link = item
                                        //splice the url to get the first 50 characters
                                        if (url.length > 40) {
                                            url = url.slice(0, 40) + '...'
                                        }
                                        if (link.includes('www.facebook.com')) {
                                            link = link.replace('www', 'm')
                                        }
                                        return (
                                            <HStack key={`item_${index}`} space={2} alignItems="center" maxW={500}>
                                                <Link href={link}>
                                                    <Heading size={'md'} color={'blue.700'} ellipsizeMode={'clip'}>{url}</Heading>
                                                </Link>
                                            </HStack>
                                        )
                                    })
                                }
                            </VStack>
                        </VStack>
                    )
                }
            </Center>
        </NativeBaseProvider>
    )
}

export default FbRedirect;