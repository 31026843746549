import React, { useEffect, useRef, useState, useMemo, useCallback, memo } from 'react'
import { Avatar, Divider, FormControl, Heading, HStack, Input, VStack, TextArea, ScrollView, Spinner, Stack, Text, Skeleton, Center, Box, Icon, Image, StatusBar, Badge, View, NativeBaseProvider, Pressable, IconButton, Toast, Button, Select, Link, Flex, Switch, Checkbox } from 'native-base';
import { addUserSupportChat, auth, db, getSupportSettings, updateSupportStatus, updateUserTextSupport, getUserNavigationHistory, getUserCollections, getFullChatData, sendPushNotification, getAllTokens, getAllUsers, sendBulkPushNotification, getAllSubscriptions, getAllActivity } from '../config/firebase';
import { LoginScreen } from './LoginScreen';

interface comment {
    text: string,
    date: string,
}

interface post {
    text: string,
    date: string,
    comments: comment[],
}

interface profile {
    name: string,
    worker: string,
    facebook: string,
}

interface group {
    name: string,
    url: string,
}

interface task {
    post: post,
    date: string,
    profile: profile,
    group: group,
}

const Schedule = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
  
    // Add event listener to update window dimensions on resize
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    //check if user is logged in with firebase
    const currentUser = auth.currentUser;

    // Set an initializing state whilst Firebase connects
    const [initializing, setInitializing] = useState(true);
    const [user, setUser] = useState(currentUser);
    

    // Handle user state changes
    function onAuthStateChanged(user : any) {
        setUser(user);
        if (initializing) setInitializing(false);
    }

    useEffect(() => {
        const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
        return subscriber; // unsubscribe on unmount
    }, []);

    if (!user) {
        return (
            <NativeBaseProvider>
                <LoginScreen />
            </NativeBaseProvider>
        )
    }
        
    const TaskSchedule = () => {

        const [tasks, setTasks] = useState<task[]>([]);
        const [completedTasks, setCompletedTasks] = useState<task[]>([]);

        const profiles: profile[] = [
            {
                "name": "Adam Cox",
                "worker": "Worker1",
                "facebook": "https://www.facebook.com/adam.sarwas",
            },
            {
                "name": "Matthew Anderson",
                "worker": "Worker1",
                "facebook": "https://www.facebook.com/matthewanderson943",
            },
            {
                "name": "Alexa Harris",
                "worker": "Worker1",
                "facebook": "https://www.facebook.com/profile.php?id=100055517155164",
            },
            {
                "name": "Christian Albrecht",
                "worker": "Worker1",
                "facebook": "https://www.facebook.com/christian.hadley.374",
            },
            {
                "name": "Emma Lopez",
                "worker": "Worker1",
                "facebook": "https://www.facebook.com/profile.php?id=100055316295783",
            },
            {
                "name": "Heidi Anderson",
                "worker": "Worker1",
                "facebook": "https://www.facebook.com/profile.php?id=100055703236676",
            },
            {
                "name": "Jane Anderson",
                "worker": "Worker1",
                "facebook": "https://www.facebook.com/profile.php?id=100062842624023",
            },
            {
                "name": "Ian Brown",
                "worker": "Worker1",
                "facebook": "https://www.facebook.com/ian.branagh.50",
            },
            {
                "name": "Jared Gilliam",
                "worker": "Worker1",
                "facebook": "https://www.facebook.com/jared.gilliam.357",
            },
            {
                "name": "John Smith",
                "worker": "Worker1",
                "facebook": "https://www.facebook.com/profile.php?id=100055566829723",
            },
            {
                "name": "Lana Clark",
                "worker": "Worker2",
                "facebook": "https://www.facebook.com/lanaclarkrealtorms",
            },
            {
                "name": "Liam Miller",
                "worker": "Worker2",
                "facebook": "https://www.facebook.com/liam.miller.7792052",
            },
            {
                "name": "Lon Herman",
                "worker": "Worker2",
                "facebook": "https://www.facebook.com/helen.cobas.7",
            },
            {
                "name": "Lucy McDonald",
                "worker": "Worker2",
                "facebook": "https://www.facebook.com/profile.php?id=100055755821666",
            },
            {
                "name": "Donald Boyd",
                "worker": "Worker2",
                "facebook": "https://www.facebook.com/profile.php?id=100055772770751",
            },
            {
                "name": "Matt Alfano",
                "worker": "Worker2",
                "facebook": "https://www.facebook.com/matt.alfano.16",
            },
            {
                "name": "Michael Harris",
                "worker": "Worker2",
                "facebook": "https://www.facebook.com/profile.php?id=100055777213159",
            },
            {
                "name": "Paul Clark",
                "worker": "Worker2",
                "facebook": "https://www.facebook.com/profile.php?id=100055609699563",
            },
            {
                "name": "Richard Wilson",
                "worker": "Worker2",
                "facebook": "https://www.facebook.com/ty.andrews.1694",
            },
            {
                "name": "Rick Beckett",
                "worker": "Worker2",
                "facebook": "https://www.facebook.com/rick.beckett.988",
            },
        ]
        
        const groups: group[] = [
            {
                name: "Lab Coat Agents",
                url: "https://www.facebook.com/groups/labcoatagents",
            },
            {
                name: "Real Estate Agent Referral Network & Marketing Tips",
                url: "https://www.facebook.com/groups/therealestateagentreferralnetwork",
            },
            {
                name: "Rockstar Agents",
                url: "https://www.facebook.com/groups/rockstar.agents",
            },
            {
                name: "Club Wealth",
                url: "https://www.facebook.com/groups/ClubWealth",
            },
            {
                name: "New Agents",
                url: "https://www.facebook.com/groups/208720559750332/",
            },
            {
                name: "Lead Gen Scripts and Objections",
                url: "https://www.facebook.com/groups/gotobjections/",
            },
            {
                name: "Real Estate Agents that REALLY work",
                url: "https://www.facebook.com/groups/RealEstateAgentsthatREALLYwork/",
            },
            {
                name: "Next Level Agents",
                url: "https://www.facebook.com/groups/nextlevelagents/",
            },
            {
                name: "Real Closers",
                url: "https://www.facebook.com/groups/RealTownsRealClosers/",
            },
        ]

        const cities = [
            "New York, NY",
            "Los Angeles, CA",
            "Chicago, IL",
            "Houston, TX",
            "Phoenix, AZ",
            "Philadelphia, PA",
            "San Antonio, TX",
            "San Diego, CA",
            "Dallas, TX",
            "San Jose, CA",
            "Austin, TX",
            "Jacksonville, FL",
            "Fort Worth, TX",
            "Columbus, OH",
            "Charlotte, NC",
            "San Francisco, CA",
            "Indianapolis, IN",
            "Seattle, WA",
            "Denver, CO",
            "Washington, D.C.",
            "Boston, MA",
            "El Paso, TX",
            "Nashville, TN",
            "Detroit, MI",
            "Oklahoma City, OK",
            "Portland, OR",
            "Las Vegas, NV",
            "Memphis, TN",
            "Louisville, KY",
            "Baltimore, MD",
            "Milwaukee, WI",
            "Albuquerque, NM",
            "Tucson, AZ",
            "Fresno, CA",
            "Sacramento, CA",
            "Mesa, AZ",
            "Kansas City, MO",
            "Atlanta, GA",
            "Long Beach, CA",
            "Colorado Springs, CO",
            "Raleigh, NC",
            "XXX, FL",
            "Virginia Beach, VA",
            "Omaha, NE",
            "Oakland, CA",
            "Minneapolis, MN",
            "Tulsa, OK",
            "Arlington, TX",
            "New Orleans, LA",
            "Wichita, KS",
            "Cleveland, OH",
            "Tampa, FL",
            "Bakersfield, CA",
            "Aurora, CO",
            "Honolulu, HI",
            "Anaheim, CA",
            "Santa Ana, CA",
            "Riverside, CA",
            "Corpus Christi, TX",
            "Lexington, KY",
            "Stockton, CA",
            "Saint Paul, MN",
            "Cincinnati, OH",
            "Greensboro, NC",
            "Pittsburgh, PA",
            "Irvine, CA",
            "St. Louis, MO",
            "Orlando, FL",
            "Plano, TX",
            "Norfolk, VA",
            "Chandler, AZ",
            "Laredo, TX",
            "Madison, WI",
            "Winston-Salem, NC",
            "Lubbock, TX",
            "Baton Rouge, LA",
            "Durham, NC",
            "Garland, TX",
            "Glendale, AZ",
            "Reno, NV",
            "Hialeah, FL",
            "Chesapeake, VA",
            "Scottsdale, AZ",
            "North Las Vegas, NV",
            "Irving, TX",
            "Fremont, CA",
            "Irvine, CA",
            "San Bernardino, CA",
        ];

        const questions = {
            prefix: [
                "Who's your favorite agent in ",
                "Tell me, who's your favorite agent in ",
                "Do you have a favorite agent in ",
                "Any preferred agent in ",
                "Which agent is your favorite in ",
                "Care to share your favorite agent in ",
                "I wonder, who's your favorite agent in ",
                "Can you name your favorite agent in ",
                "Who do you favor among agents in ",
                "Have you picked a favorite agent in ",
                "Choose your favorite agent in ",
                "Tell me about your favorite agent in ",
                "Any top agent picks in ",
                "Which agent stands out to you in ",
                "Looking for your favorite agent in ",
                "Favorite agent in ",
                "Tell me your favorite agent from ",
                "Any preferred agents in ",
                "Who stands as your favorite agent in ",
                "Who's your favorite agent in ",
                "Do you have a go-to agent in ",
                "Looking to find your favorite agent in ",
                "Any favorite agents in ",
                "Which agent do you root for in ",
                "I'm curious, who's your favorite agent in ",
                "Tell me, who's the one agent you love in ",
                "Have you found your favorite agent in ",
                "Who gets your vote as the favorite agent in ",
                "Pick your favorite agent in ",
                "Any standout agents in ",
                "Which agent is your absolute favorite in ",
                "Looking to choose your favorite agent in ",
                "Tell me who your favorite agent is in ",
                "Any beloved agents in ",
                "Who deserves the title of your favorite agent in ",
                "Who's your preferred agent in ",
                "Do you lean towards a certain agent in ",
                "Have you selected your favorite agent in ",
                "Who's your go-to agent in ",
                "Looking for your top agent in ",
                "Any agent you favor in ",
                "Which agent do you adore in ",
                "Tell me, who's your favorite agent in ",
                "Favorite agent in ",
                "Any preferred agent in ",
                "Who takes the crown as your favorite agent in ",
                "Care to share your favorite agent in ",
                "I wonder, who's your favorite agent in ",
                "Can you name your favorite agent in ",
                "Who do you favor among agents in ",
                "Have you picked a favorite agent in ",
            ],
            suffix: [
                "Please send friend request",
                "Pls friend request",
                "Send friend request",
                "Send me friend request",
                "Add me as friend",
                "Add me as a friend",
                "Add me as your friend",
                "Send me a msg or friend request",
                "Send me a message or friend request",
                "Let's connect",
                "Lets connect",
                "Lets be friends",
                "Let's be friends",
            ],
            leadTypes: [
                "a buyer",
                "a seller",
                "an investor",
                "a listing",
                "a condo",
                "a duplex",
                "",
                "a personal",
                "a relocation",
                "new construction",
                "a high end",
                "a luxury",
            ],
        }

        const generateReferralTasks = () => {
            let tasks: task[] = [];
            //generate 3 tasks per profile
            for (let i = 0; i < profiles.length; i++) {
                let userGroups: group[] = [];
                let userCities: string[] = [];

                for (let j = 0; j < 3; j++) {
                    //select a random group
                    let group = groups[Math.floor(Math.random() * groups.length)];
                    //check if group is already in userGroups
                    if (userGroups.includes(group)) {
                        //if so, select another random group
                        group = groups[Math.floor(Math.random() * groups.length)];
                    }
                    //select a random city
                    let city = cities[Math.floor(Math.random() * cities.length)];
                    //check if city is already in userGroups
                    if (userCities.includes(city)) {
                        //if so, select another random city
                        city = cities[Math.floor(Math.random() * cities.length)];
                    }
                    //select a random question in the format of "prefix" + "city" + "leadType" + "suffix"
                    let question = questions.prefix[Math.floor(Math.random() * questions.prefix.length)] + city + " for " + questions.leadTypes[Math.floor(Math.random() * questions.leadTypes.length)] + " referral? " + questions.suffix[Math.floor(Math.random() * questions.suffix.length)];
                    
                    //add task to tasks array
                    tasks.push({
                        post: {
                            text: question,
                            date: new Date().toISOString(),
                            comments: [],
                        },
                        date: new Date().toISOString(),
                        profile: profiles[i],
                        group: group,
                    })
                }
            }
            setTasks(tasks);
        }

        useEffect(() => {
            generateReferralTasks();
        }, [])

        


        return (
            <View width={windowWidth} bgColor={'amber.100'} p={5}>
                <VStack space={1} flexWrap={'wrap'}>
                    
                    {tasks.map((task, index) => (
                        <Box key={index} borderColor={'coolGray.200'} borderWidth={1} p={3} borderRadius={10} shadow={2} bgColor={!(completedTasks.includes(task))?'white':'success.100'}>
                            
                            <Link href={task.profile.facebook} isExternal mb={2}>
                                <Heading size={'sm'} color={'blue.700'}>{index+1}. {task.profile.name}</Heading>
                            </Link>
                            <Pressable onPress={() => {
                                //copy to clipboard
                                navigator.clipboard.writeText(task.group.url);
                                Toast.show({
                                    title: `Copied to clipboard: ${task.group.url}`,
                                    placement: 'bottom',
                                })
                            }
                            } _pressed={{opacity: 0.6}} _hover={{backgroundColor: "amber.100"}} mb={2}>
                                <Text fontSize={'lg'}>Group: {task.group.name}</Text>
                            </Pressable>
                            <Pressable onPress={() => {
                                //copy to clipboard
                                navigator.clipboard.writeText(task.post.text);
                                Toast.show({
                                    title: `Copied to clipboard: ${task.post.text}`,
                                    placement: 'bottom',
                                })
                            }} _pressed={{opacity: 0.6}} _hover={{backgroundColor: "amber.100"}} mb={2}>
                                <Text fontSize={'lg'}>Post text: {'\n'}"{task.post.text}"</Text>
                            </Pressable>

                            <Pressable onPress={() => {
                                //mark task as completed
                                setCompletedTasks([...completedTasks, task]);
                            }} _pressed={{opacity: 0.6}} _hover={{backgroundColor: "amber.100"}} mb={2} bgColor={!(completedTasks.includes(task))?'danger.100':'success.100'}>
                                <Text fontSize={'lg'}>{(completedTasks.includes(task))?'Completed':'Mark as Completed'}</Text>
                            </Pressable>

                        </Box>
                    ))}
                </VStack>
            </View>
        )
    }

        
    return (
        <NativeBaseProvider>
            <TaskSchedule />
        </NativeBaseProvider>
    )
}

export default Schedule