import React from 'react'

function Exit() {
    //close this tab
    window.ReactNativeWebView.postMessage('closeWebView');
    return (
        <div className='container'>
        </div>
    )
}

export default Exit;