import React from 'react'

function TermsOfUse() {
  return (
    <div className='container mb-5 mt-5'>
        <h1>Terms of Use</h1>
        <p>These Terms of Service, together with BrokerSpot's Privacy Policy (<a href="https://brokerspot.com/privacy-policy/">https://brokerspot.com/privacy-policy/</a>) and, if you are a subscriber of BrokerSpot's services, BrokerSpot's Billing Policy (<a href="https://brokerspot.com/billing-terms/">https://brokerspot.com/billing-terms/</a>), set forth the terms and conditions (the “Terms”) that apply to your access and use of this website (the “Site”), our mobile apps (the "Apps") and BrokerSpot's services (the “Services”). “BrokerSpot” includes HouseVenture, Inc. and its officers, directors, employees, consultants, affiliates, subsidiaries, and agents.</p>

        <p>BrokerSpot is a doing business as (dba) name for BrokerSpot, Inc.</p>

        <p>The Services may include, but are not limited to: (1) online advertising services; (2) limited non-exclusive license of BrokerSpot's proprietary customer relationship management software (the “Proprietary Software”) and certain copyrighted materials; (3) training in the Proprietary Software; and (4) certain support services provided by BrokerSpot at its sole discretion regarding the Proprietary Software.</p>
        <p>By using the Services, you agree to these Terms, as updated from time to time in accordance with Section 11 below. Because BrokerSpot provides a wide range of services, we may at times ask you to review and accept supplemental terms that apply to your interaction with a specific product or service.&nbsp;<b>These Terms state that any disputes between you and BrokerSpot must be resolved in arbitration or small claims court.</b></p>
        <ol>
        <li><b></b><b>Account Security.</b>&nbsp;To use the Services you must (i) be at least eighteen (18) years of age; (ii) have not previously been suspended or removed from the Services; and (iii) register for and use the Service in compliance with any and all applicable laws and regulations.</li>
        <li><b></b><b>Account Registration.</b>&nbsp;Using the Services requires a BrokerSpot account (the “Account”). When you register for your Account, we may ask you to give us certain identifying information about yourself, including but not limited to your email address and other contact information, and to create a user name and password (“Registration Information”). When registering for and maintaining an account, you agree to provide true, accurate, current, and complete information about yourself. You also agree not to impersonate anyone, misrepresent any affiliation with anyone else, use false information, or otherwise conceal your identity from BrokerSpot for any purpose. You are solely responsible for maintaining the confidentiality and security of your Registration Information. For your protection and the protection of other users, we ask you not to share your Registration Information with anyone else. If you do share this information with anyone, we will consider their activities to have been authorized by you. If you have reason to believe that your account is no longer secure, you must immediately notify us.</li>
        <li><b></b><b>Payment.</b>&nbsp;You understand that prior to BrokerSpot providing the Services, you are required to choose a Subscription Plan, enter into a Subscription Agreement, and pay all fees listed in your Subscription Agreement.By starting your Subscription Plan, you authorize us to charge fees per the terms of your Subscription Agreement, and any other charges you may incur in connection with your use of the Services. You will be automatically charged each month, or, in the case of a prepaid plan, per the terms of such plan, for your ongoing use of the Services. All prices are payable in USD, and all payments are non-refundable. You are responsible for paying any applicable taxes. If you fail to pay the full amount due for any or all of the Services, BrokerSpot may, at its sole discretion in accordance with and subject to any applicable law, suspend any or all of the Services.Please carefully review the Billing Policy, which contains detailed information regarding how we charge you for the Services, how we handle payment disputes, your Ad Budget requirements, how to cancel or renew your subscription, and other topics related to payment.&nbsp;In the event that these Terms and the Billing Policy conflict, these Terms control.</li>
        <li><b></b><b>Prohibited Conduct.</b>&nbsp;You agree not to:<br/>
        <ul>
        <li><b></b>Use the Services for any illegal purpose, or in violation of any local, state, national, or international law;</li>
        <li><b></b>Violate or encourage others to violate the rights of third parties, including intellectual property rights;</li>
        <li><b></b>Post, upload, or distribute any content that is unlawful, defamatory, libelous, inaccurate, or that a reasonable person could deem to be objectionable, profane, indecent, pornographic, harassing, threatening, hateful, or otherwise inappropriate;</li>
        <li><b></b>Interfere in any way with security-related features of the Services;</li>
        <li><b></b>Interfere with the operation or any user’s enjoyment of the Services, including by uploading or otherwise disseminating viruses, adware, spyware, worms, or other malicious code, making unsolicited offers or advertisements to other users, or attempting to collect personal information about users or third parties without their consent;</li>
        <li><b></b>Access, monitor or copy any content or information of the Services using any robot, spider, scraper, or other automated means or any manual process for any purpose without BrokerSpot's express written permission;</li>
        <li><b></b>Perform any fraudulent activity, including impersonating any person or entity, claiming false affiliations, accessing the accounts of other users without permission, or falsifying your identity or any information about you, including age or date of birth; or</li>
        <li><b></b>Sell or otherwise transfer the access granted herein.</li>
        </ul>
        </li>
        </ol>
        <ol>
        <li><b></b>We have the right at our sole discretion to remove any content posted on our Site or through the Services for any reason. We are not responsible for any delay in or failure to remove such content.</li>
        <li><b></b><b>Third Party Content.</b>&nbsp;The Site may contain links to third party websites and services. BrokerSpot provides such links as a convenience, and does not control or endorse these websites and services. You acknowledge and agree that BrokerSpot has not reviewed the content, advertising, products, services, or other materials that appear on such third party websites or services, and is not responsible for the legality, accuracy, or appropriateness of any such content, and shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of any such third party websites or services. Certain products, tools, or other types of services made available via the Services are delivered by third party sites and organizations. You hereby acknowledge and consent that BrokerSpot may share information and data about you with third parties that provide those products, tools, or other types of services that are available via the Services.</li>
        <li><b></b><b>Intellectual Property.</b>&nbsp;You are granted a non-exclusive, non-transferable, revocable license to access and use the Services strictly in accordance with these Terms. Using the Services does not give you ownership of any data or intellectual property rights in the Services or the content you access.You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale of, create derivative works of, or in any way exploit the software and services that make up the Services other than as expressly authorized herein. You will not upload, post or otherwise make available on the Services any material protected by copyright, trademark or another proprietary right without the express permission of the owner of the copyright, trademark or other proprietary rights.You will not resell or offer for resale any of the Services, or make any unauthorized use of the Services and any protected content. In particular, you will not remove, obscure, or alter any proprietary rights or attribution notices in any content, including any legal notices displayed on, in, or in connection with the Services. We do not grant you any licenses, express or implied, to the intellectual property of BrokerSpot or our licensors except as expressly authorized by these Terms. None of the BrokerSpot Services, or any part of any BrokerSpot Services, may be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of BrokerSpot. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of BrokerSpot without express written consent. You may not use any meta tags or any other “hidden text” utilizing BrokerSpot's name or trademarks without the express written consent of BrokerSpot.</li>
        <li><b></b><b>Imported Leads.</b>&nbsp;You represent that any information related to potential buyers and sellers of real estate (“Leads”) that you upload, import, or post to the Services was collected and at all times used in compliance with all applicable data privacy and security laws, including with respect to notice and consent requirements. You understand and acknowledge that such Leads may be used by BrokerSpot for any purpose at any time, including after you cease using the Services and/or your subscription terminates.</li>
        <li><b></b><b>User Content.</b>&nbsp;You represent and warrant that you own or otherwise have the right to use any content you post to the Services. If you believe that your content has been used in a way that constitutes copyright infringement, you may contact our support team to appropiately route your inquiry:</li>
        </ol>
        <ol>
        <li style={{listStyleType: 'none'}}>
        <ul>
        <li><b></b>BrokerSpot Support<br/>support@brokerspot.com</li>
        </ul>
        </li>
        </ol>
        <ol>
        <li><b></b>You must provide the following information: an electronic or physical signature of the copyright owner or the person authorized to act on behalf of the copyright owner; a description of the copyrighted work that you claim has been infringed; a description of where the material that you claim is infringing is located; your address, telephone number, and email address; a written statement that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf. BrokerSpot has adopted and implements a policy that provides for termination in appropriate circumstances of accounts of users who repeatedly infringe copyright or other intellectual property rights of BrokerSpot or others.</li>
        <li><b></b><b>Indemnification.</b>&nbsp;You agree that you will be personally responsible for your use of the Services, and you agree to defend, indemnify, and hold harmless BrokerSpot from and against any and all claims, liabilities, damages, losses, and expenses (including attorneys’ and accounting fees and costs), arising out of or in any way connected with (i) your access to, use of, or alleged use of the Services; (ii) your violation of the Terms or any applicable law or regulation; (iii) your violation of any third party right, including without limitation any intellectual property right, publicity, confidentiality, property, or privacy right; or (iv) any disputes or issues between you and any third party. BrokerSpot reserves the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and in such case, you agree to cooperate with our defense of such claim.</li>
        <li><b></b><b>Term and Termination.</b>&nbsp;If you violate these Terms, your permission to use the Services will automatically and immediately terminate. In addition, BrokerSpot in its sole discretion may suspend or terminate your Account and/or suspend or terminate some or all of your access to the Services at any time, with or without notice to you.After your subscription terminates, information and content previously provided by you will no longer be accessible through your Account, but BrokerSpot may continue to store such information and content, and it may also be stored by third parties to whom it has been transferred through your use of the Services.</li>
        <li><b></b><b>Modification of the Terms.</b>&nbsp;BrokerSpot reserves the right at any time to modify these Terms and to impose new or additional terms or conditions on your use of the Services. Such modifications and additional terms and conditions will be effective immediately upon notice and incorporated into these Terms. We will make reasonable efforts to notify you of any material changes to the Terms, including, but not limited to, by posting a notice to our website or by sending an email to any address you may have provided to us. Your continued use of the Services following notice will be deemed acceptance of any modifications to the Terms.</li>
        <li><b></b><b>Disclaimers of Warranties.</b>&nbsp;The Services are provided “as is” and on an “as available” basis, without warranty or condition of any kind, either express or implied. Although BrokerSpot seeks to maintain safe, secure, accurate, and well-functioning services, we cannot guarantee the continuous operation of or access the Services, and there may at times be inadvertent technical or factual errors or inaccuracies. BrokerSpot specifically (but without limitation) disclaims (i) any implied warranties of merchantability, satisfactory quality, fitness for a particular purpose, quiet enjoyment, or non-infringement; and (ii) any warranties arising out of course-of-dealing, usage, or trade. You assume all risk for any/all damages that may result from your use of or access to the Services. BrokerSpot does not guarantee the accuracy of, and disclaims all liability for, any errors or other inaccuracies in the information, content, recommendations, and materials made available through the Services.</li>
        <li><b></b><b>Limitation of Liability.</b>&nbsp;In no event will BrokerSpot be liable to you for personal injury or any incidental, special, consequential, direct, indirect, or punitive damages, including, without limitation, damages for loss of profits, loss of data, business interruption, or any other commercial damages or losses, arising out of or related to your use of or inability to use the Services however caused, regardless of the theory of liability (contract, tort, or otherwise), whether or not BrokerSpot has been informed of the possibility of such damage. Some jurisdictions do not allow the disclaimer of warranties or limitation of liability in certain circumstances. Accordingly, some of the above limitations may not apply to you. In no event shall BrokerSpot's total liability to you for all damages (other than as may be required by applicable law in cases involving personal injury) exceed the amount of fifty dollars ($50.00). The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.</li>
        <li><b></b><b>Governing Law.</b>&nbsp;These Terms and the relationship between you and BrokerSpot are governed by the laws of the State of Delaware, without regard to conflict of law principles. Subject to Section 11, which provides that disputes are to be resolved through binding arbitration or small claims court, to the extent that any lawsuit or court proceeding is permitted hereunder, you and BrokerSpot agree to submit to the exclusive personal jurisdiction of the state courts and federal courts located within Delaware, for the purpose of litigating all such disputes.</li>
        <li><b></b><b>Dispute Resolution by Binding Arbitration.</b>&nbsp;In the interest of resolving disputes between you and BrokerSpot in the most expedient and cost effective manner, you and BrokerSpot agree to resolve disputes through binding arbitration or small claims court instead of in courts of general jurisdiction (“Agreement to Arbitrate”). Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, allows for more limited discovery than in court, and is subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. Any arbitration under these Terms will take place on an individual basis; class arbitrations and class actions are not permitted. You acknowledge and agree that the arbitrator may award relief (including monetary, injunctive, and declaratory relief) only in favor of the individual party seeking relief and only to the extent necessary to provide relief necessitated by that individual party’s claim(s). Any relief awarded cannot affect other users.</li>
        <li><b></b><b>Waiver of Class Action</b><br/>There shall be no right or authority for any claims to be arbitrated or litigated on a class action or consolidated basis or on bases involving claims brought in a purported representative capacity on behalf of the general public, other users of the Site or the Services, or other persons similarly situated. BrokerSpot would not be willing to provide the Services to you absent this waiver. You acknowledge and agree that you are under no obligation to use this Site or the Services if you are not willing to waive class action rights, and you expressly agree to this waiver.</li>
        <li><b></b><b>Modification of the Services.</b>&nbsp;BrokerSpot reserves the right to modify or discontinue, temporarily or permanently, some or all of the Services at any time without any notice or further obligation to you. You agree that BrokerSpot will not be liable to you or to any third party for any modification, suspension, or discontinuance of any of the Services.If we do give you notice, it may be provided to you on your monthly statement, by e-mail, or other communication permitted under applicable law. However, if you continue to receive the Services after the change, this will constitute your acceptance of the change.</li>
        <li><b></b><b>Electronic Communications.</b>&nbsp;Visiting this Site or sending emails to BrokerSpot constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.</li>
        <li><b></b><b>Specially Designated Nationals.</b>&nbsp;If you are on the U.S. Treasury Department’s list of Specially Designated Nationals you will not engage in commercial activities on BrokerSpot. You will not use the Services if you are prohibited from receiving products, services, or software originating from the United States.</li>
        <li><b></b><b>General.</b>I.
        <p>Entire Agreement. These Terms, together with the Privacy Policy and, if you are a subscriber of BrokerSpot's services, the Subscription Agreement and Billing Policy, constitute the entire and exclusive understanding and agreement between you and BrokerSpot regarding your use of and access to the Services, and except as expressly permitted above may only be amended by a written agreement signed by authorized representatives of the parties.</p>
        <p>J.</p>
        <p>No Waiver.&nbsp;The failure to require performance of any provision shall not affect our right to require performance at any time thereafter, nor shall a waiver of any breach or default of the Terms constitute a waiver of any subsequent breach or default or a waiver of the provision itself.</p>
        <p>K.</p>
        <p>Paragraph Headers.&nbsp;Use of paragraph headers in the Terms is for convenience only and shall not have any impact on the interpretation of particular provisions.</p>
        <p>L.</p>
        <p>Severability.&nbsp;In the event that any part of the Terms is held to be invalid or unenforceable, the unenforceable part shall be given effect to the greatest extent possible and the remaining parts will remain in full force and effect.</p></li>
        </ol>
    </div>
  )
}

export default TermsOfUse