import React, { useState } from "react";
import * as Fa from 'react-icons/fa';
import { BrowserView } from 'react-device-detect';

export default function HeroEight() {
  return ( 
    <>
      <section className="hero-section hero-section-3 ptb-100" id="home">
        <div className="circles">
          <div className="point animated-point-1"></div>
          <div className="point animated-point-2"></div>
          <div className="point animated-point-3"></div>
          <div className="point animated-point-4"></div>
          <div className="point animated-point-5"></div>
          <div className="point animated-point-6"></div> 
        </div>
        <div className="container" id="animation-area-1">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-9 col-lg-7 col-xl-6">
              <div className="hero-content section-title text-lg-left my-5 mt-lg-0">
                <h1 className="font-weight-bold" style={{color: '#111111f0', margin: 20}}>
                  Download the BrokerSpot app to get started
                </h1>
                <BrowserView>
                <div className="row">
                  <div className="col-4">
                    <img src="assets/img/qr-code.png" alt="Scan the QR code" style={{maxWidth: 200}}/>
                  </div>
                  <div className="col-8" style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <h3>
                      Scan this code with your smartphone to download the BrokerSpot app.
                    </h3>
                  </div>
                </div>
                </BrowserView>
                <div className="row" style={{gap: 10, margin: 20}}>
                  <div>
                    <h4 className="d-flex align-items-center"><Fa.FaCheck size={25} color="green" style={{marginRight: 10}}/>Begin with a self guided onboarding experience.</h4>
                  </div>
                  <div>
                    <h4 className="d-flex align-items-center"><Fa.FaCheck size={25} color="green" style={{marginRight: 10}}/>Establish yourself as the go-to agent in your zip codes.</h4>
                  </div>
                  <div>
                    <h4 className="d-flex align-items-center"><Fa.FaCheck size={25} color="green" style={{marginRight: 10}}/>Get notified each time you get a new lead.</h4>
                  </div>
                </div>
                <div className="download-btn" style={{gap: 10, margin: 20}}>
                  <a href="https://apps.apple.com/us/app/brokerspot/id6448661305" target="_blank">
                    <img src="assets/img/apple-store.png" alt="Download in the App Store" style={{maxWidth: 200}}/>
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.branoli.brokerspot" target="_blank">
                    <img src="assets/img/google-play-badge-new.png" alt="Get it on Google Play" style={{maxWidth: 200}}/>
                  </a>
                </div>
              </div>
              <p>Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries. Google Play and the Google Play logo are trademarks of Google LLC.</p>
            </div>
            <div className="col-9 col-md-7 col-lg-5 offset-xl-1 align-self-sm-end">
              <div className="hero-img position-relative">
                <div className="image-bg-positioning">
                  <img
                    src="assets/img/app-product-2.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="animation-item">
                  <div className="p-4 w-75 rounded-custom d-flex white-bg hero-animated-card-1">
                    <p className="gr-text-11 mb-0 text-mirage-2">
                      “I would like to sell my property. Can we meet next Monday?”
                    </p>
                    <div className="small-card-img ml-3">
                      <img
                        src="assets/img/team-2.jpg"
                        alt=""
                        width="80px"
                        className="rounded-circle img-fluid"
                      />
                    </div>
                  </div>
                  <div className="p-4 w-75 rounded-custom d-flex secondary-bg hero-animated-card-2">
                    <div className="small-card-img mr-3 text-white">
                      <img
                        src="assets/img/team-5.jpg"
                        alt=""
                        width="80px"
                        className="rounded-circle img-fluid"
                      />
                    </div>
                    <p className="gr-text-11 mb-0 text-white">
                      “Absolutely! I would love to help you list your home.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
