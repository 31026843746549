import React from "react";

export default function FeatureImg({ ImgSource }) {
  return (
    <>
      <div id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">
                <span className="text-uppercase color-secondary sub-title">
                  Best features
                </span>
                <h2>All-In-One Lead Generation</h2>
                <p>
                  We created a platform that allows you to generate leads, manage them, and close deals. All in one place.
                </p>
              </div>
            </div>
          </div>
          <div className="row row-grid align-items-center">
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-1 rounded-circle">
                    <span className="ti-face-smile"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Quality Leads</h5>
                  <p className="mb-0">
                    Our leads show intention to buy or sell a property. We ensure that you receive quality leads that can boost your sales and business growth.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-2 rounded-circle">
                    <span className="ti-vector"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Easy Deal Management</h5>
                  <p className="mb-0">
                    Manage your leads and deals with our easy-to-use dashboard. You can also connect them to your favorite CRM and follow up from there.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-3 rounded-circle">
                    <span className="ti-headphone-alt"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Friendly online support</h5>
                  <p className="mb-0">
                    Our support team is always ready to help you with any questions, problems or comments you may have. Feel free to contact us.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="position-relative" style={{ zIndex: 10 }}>
                <img
                  alt="placeholder"
                  src={ImgSource}
                  className="img-center img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-media-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Frequent Updates</h5>
                  <p className="mb-0">
                    We update the platform regularly to ensure that you get the best experience and the best results.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-5 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Flexible Payments</h5>
                  <p className="mb-0">
                    We offer flexible payment options to suit your needs. You can set your monthly budget and cancel anytime with a click of a button.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-6 rounded-circle">
                    <span className="ti-palette"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Customizable Landing Pages</h5>
                  <p className="mb-0">
                    Our landing pages are designed to convert more while keeping the same look and feel of your brand.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
