import axios from 'axios';

export const getPropertyData = async (fullAddress: string, zipCode: string, unitNo?: string) => {
    //https://certiapi.com/estated-output.php?street_address=$fulladdress&zip=$zip_prefix
    let url: string;
    if (unitNo) {
        url = `https://certiapi.com/estated-output.php?street_address=${fullAddress} ${encodeURIComponent('#')}${unitNo}&zip=${zipCode}`;
    } else {
        url = `https://certiapi.com/estated-output.php?street_address=${fullAddress}&zip=${zipCode}`;
    }
    const response = await axios.get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return null;
        })
    return response;
}